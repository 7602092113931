import React from 'react';
import ListItem from './ListItem';
import PlaygroundListLoading from './loadingList/index';
import images from "../../themes/images";
import Background from '../../assets/images/img_playground_bg_list.svg';

var sectionStyle = {
  width: "100%",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${Background})`
};

// display: '1', '2'
function PlaygroundList({
  data,
  loading,
  param,
  omitHashtags = false,
  omitTitle = false,
  itemDisplayFormat = '1',
  wrapperClassName,
  editAction,
  deleteAction,
  showComment = false,
  timeAgo = false,
  highlight = '',
  highlightHashtagID = '',
  highlightStoreID = '',
}) {
  return (
    <div
      className={`wrapper-page-playground${
        wrapperClassName ? ' ' + wrapperClassName : ''
      }`}
      id="box-list-item"
      style={itemDisplayFormat == '1' ? sectionStyle : {}}
    >
      <div className="playground-list">
        {/*<DropdownAction />*/}
        {data.map((item, key) => (
          <ListItem
            key={key}
            param={param}
            item={item}
            omitHashtags={omitHashtags}
            omitTitle={omitTitle}
            itemDisplayFormat={itemDisplayFormat}
            wrapperClassName={wrapperClassName}
            editAction={editAction}
            deleteAction={deleteAction}
            showComment={showComment}
            showInfoUser={false}
            timeAgo={timeAgo}
            highlight={highlight}
            highlightHashtagID={highlightHashtagID}
            highlightStoreID={highlightStoreID}
          />
        ))}

        <PlaygroundListLoading
          itemDisplayFormat={itemDisplayFormat}
          loading={loading}
          param={param}
        />
      </div>
    </div>
  );
}

export default PlaygroundList;
