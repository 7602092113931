import { combineReducers } from 'redux';
import { mainReducer } from '../screens/mainPage/redux';
import { settingAppReducer } from '../screens/settingPage/redux';
import { accountReducer } from '../account/redux';
import { pushReducer } from '../screens/historyPush/redux';
import { addPointReducer } from '../screens/addPoint/redux';
import { levelReducer } from '../screens/levelPage/redux';
import { productReducer } from '../screens/productPage/redux';
import { myPageReducer } from '../screens/myPage/redux';
import { paymentReducer } from '../screens/historyBuy/redux';
import { searchReducer } from '../screens/findStore/redux';
import { groupBuyingReducer } from '../screens/order/redux';
import { playgroundReducer } from './playground/index';

const appReducer = combineReducers({
  mainReducer,
  settingAppReducer,
  accountReducer,
  pushReducer,
  addPointReducer,
  levelReducer,
  productReducer,
  myPageReducer,
  paymentReducer,
  searchReducer,
  groupBuyingReducer,
  playgroundReducer,
});

export default appReducer;
