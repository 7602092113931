
import { /*useNavigate, */useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

const useChangeUrlParams = () => {
  const location = useLocation();
  // const navigate = useNavigate();
  const history = useHistory();

  const changeUrlParams = (searchQuery, option) => {
    const params = {...searchQuery}
    if (option) {
      if (Array.isArray(option)) {
        let hasPage = false
        let hasReload = false
        for (const { type, value } of option){
          params[type] = value
          if (type === 'page') {
            hasPage = true
          }
          if (type === 'reload') {
            hasReload = true
          }
        }
        if (!hasPage) {
          params.page = 1
        }
        if (!hasReload) {
          params.reload = 0
        }
      } else {
        const { type, value } = option
        params[type] = value
        if (type !== 'page') {
          params.page = 1
        }
        if (type !== 'reload') {
          params.reload = ''
        }
      }
    }
    params.time = Date.now()
    history.push(location.pathname + `?${queryString.stringify(params)}`);
    // navigate(
    //   {
    //     pathname: location.pathname,
    //     search: `?${queryString.stringify(params)}`,
    //   },
    //   { replace: true }
    // )
  }

  const resetParams = () => {
    history.push(location.pathname);
    // navigate(
    //   {
    //     pathname: location.pathname,
    //     search: ``,
    //   },
    //   { replace: true }
    // )
  }

  return {
    changeUrlParams,
    resetParams
  }

};

export default useChangeUrlParams;

// Usage
// const changeUrlParams = useChangeUrlParams();
