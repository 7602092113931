import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as CreatorSearch } from 'screens/findStore/redux';
import EditCommunityQAComponent from '../components/editCommunityQA';
import { Creators as CreatorPlayground } from '../../../store/playground';

const mapStateToProps = (state) => {
  return {
    storeListLikeOfUser: state.searchReducer.listStoreLikeOfUser,
    listAllStore: state.searchReducer.listAllStore,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...CreatorPlayground,
      getListAllStore: CreatorSearch.getListAllStore,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCommunityQAComponent);
