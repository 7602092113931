import { useEffect } from 'react';

const useCountSize = (type, loading) => {
  const countSize = () => {
    setTimeout(() => {
      const viewportHeight = window.innerHeight;
      const boxFilterElement = document.getElementById('box-filter-page');
      const boxFilterHeight = boxFilterElement
        ? boxFilterElement.clientHeight
        : 0;
      const contentHeight = viewportHeight - boxFilterHeight;
      const boxListItem = document.getElementById('box-list-item');
      if (boxListItem) {
        // boxListItem.style.height = contentHeight?.toString() + 'px';
        boxListItem.style.paddingTop = (boxFilterHeight + 15) + 'px';
        boxListItem.style.minHeight = '100vh';
      }
    }, 20)
  };

  useEffect(() => {
    countSize();
  }, [type, loading]);
};

export default useCountSize;
