// @flow

import React, { memo, useState, useEffect } from 'react';
import HeaderMain from 'components/MainHeader';
import ROUTERS from 'constants/router';
import images from 'themes/images';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import useTouchDownRefresh from 'customHooks/useTouchDownRefresh';

type Props = {
  children: any,
  isShowHeader?: boolean,
  isShowFooter?: boolean,
  titleHeader?: string,
  customClassHeader?: string,
  activePage?: number,
  icoBackWhite?: boolean,
  customClass?: string,
  isMarginBottom?: boolean,
  fontWeight?: string,
  isLink?: boolean,
  isShowIconBackFunction?: boolean,
  iconBackFunction?: Function,
  isCenter?: boolean,
  isShowIcon?: boolean,
  typeLogin?: string,
  history?: {
    push: Function,
  },
  isShowBtnAdd?: boolean,
  isLoading?: boolean,
  isShowHeaderMain?: boolean,
  numberBell?: number,
  showModalLogout?: Function,
  isShowModalLogout?: boolean,
  activeItemStore?: any,
  isBackNative?: boolean,
  isTouchEnd?: boolean,
  refreshPage?: Function
};

const MainLayout = ({
  children,
  isShowHeader = false,
  isShowFooter = false,
  titleHeader = '',
  customClassHeader = '',
  customClass = '',
  activePage = 1,
  icoBackWhite = false,
  isMarginBottom = false,
  fontWeight = '',
  isLink = false,
  isShowIconBackFunction = false,
  iconBackFunction = () => {},
  isShowIcon = false,
  isCenter = false,
  typeLogin = '',
  history = {},
  isShowBtnAdd = false,
  isLoading = false,
  isShowHeaderMain = false,
  numberBell = 0,
  showModalLogout = () => {},
  isShowModalLogout = false,
  isBackNative = false,
  isTouchEnd = false,
  refreshPage = () => {}
}: Props) => {
  const [activeTab, setActiveTab] = useState(activePage);
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    window.showLoadingNative(isLoading)
  }, [isLoading])



  const [dataRefreshPage, setRefreshPage] = useTouchDownRefresh(isTouchEnd);

  useEffect(() => {
    if (dataRefreshPage) {
      refreshPage(dataRefreshPage)
    }
  }, [dataRefreshPage])

  return (
    <div
      className={`layout-main ${isShowFooter ? 'mb-80' : ''}  ${
        isMarginBottom ? 'mb-80' : ''
      } ${isShowHeader ? 'pt-48' : ''} ${customClass}`}
      onTouchEnd={(e) => setRefreshPage(e, 'handelOnTouchEnd')}
      onTouchStart={(e) => setRefreshPage(e, 'handelOnTouchStart')}
      onTouchMove={(e) => setRefreshPage(e, 'handelOnTouchMove')}
    >
      {isShowHeader && ( // isShowHeader = false is hide header
        <Header
          title={titleHeader} // name of title
          customClass={customClassHeader}
          icoBackWhite={icoBackWhite} // icoBlack = true is show icon back
          isLink={isLink}
          isShowIcon={isShowIcon}
          fontWeight={fontWeight}
          isShowIconBackFunction={isShowIconBackFunction}
          iconBackFunction={iconBackFunction}
          isCenter={isCenter}
          showModalLogout={showModalLogout}
          isShowModalLogout={isShowModalLogout}
          isBackNative={isBackNative}
        />
      )}
      {isShowHeaderMain && (
        <HeaderMain history={history} numberBell={numberBell} />
      )}
      {/* {isLoading && <Loading />} */}
      <div className="h-100">{children}</div>
      {/* If account is Admin then show icon BTN */}
      <div>
        {isShowBtnAdd && (
          <>
            {typeLogin === 'admin' ? (
              <div
                className="btn-notice"
                onClick={() => history && history.push(ROUTERS.PRODUCT_ADD)}
                onKeyUp={() => {}}
                role="button"
                tabIndex={0}
              >
                <div className='contact'>
                  <img className='img-contact' src={images.icon_contact} alt="" />
                  <span className='text-contact'>문의 하기</span>
                </div>
              </div>
            ) : (
              <div
                className="btn-notice"
                onClick={() =>
                  history && history.push(ROUTERS.INQUIRY_PRODUCT_REGISTER)
                }
                onKeyUp={() => {}}
                role="button"
                tabIndex={0}
              >
                <div className='contact'>
                  <img className='img-contact' src={images.icon_contact} alt="" />
                  <span className='text-contact'>문의 하기</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {isShowFooter && (
        <Footer
          onChangeTab={(tab) => {
            setActiveTab(tab.key);
          }}
          activeTab={activeTab} // number tab active
        />
      )}
    </div>
  );
};

MainLayout.defaultProps = {
  isShowHeader: false,
  isShowFooter: false,
  titleHeader: '',
  customClassHeader: '',
  activePage: 1,
  icoBackWhite: false,
  customClass: '',
  isMarginBottom: false,
  fontWeight: '',
  isLink: false,
  isCenter: false,
  isShowIcon: false,
  isShowIconBackFunction: false,
  iconBackFunction: () => {},
  typeLogin: '',
  isShowBtnAdd: false,
  isLoading: false,
  isShowHeaderMain: false,
  numberBell: 0,
  showModalLogout: () => {},
  isShowModalLogout: false,
  history: {},
  isBackNative: false,
  isTouchEnd: false,
  refreshPage: () => {}
};

export default memo<Props>(MainLayout);
