import React from 'react'
import Background from 'assets/images/img_playground_bg_list.svg';
import NoData from 'components/NoData';

var sectionStyle = {
  width: "100%",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${Background})`
};

function PlaygroundListNoData({param, backgroundBlank = false, noDataMessage = '등록된 게시글이 없어요.'}) {

  let _noDataMessage = noDataMessage;
  let messageDetail = null;
  if (param.filterType === 3) {
    _noDataMessage = '아직 인기있는 게시글이 없어요!'
    messageDetail = <span>게시글을 지금 바로 등록해보세요!</span>
  }

  return (
    <div className="wrapper-page-playground" style={!backgroundBlank ? sectionStyle : {}} id="box-list-item">
      <div className="playground-list">
        <NoData text={_noDataMessage}>
          {messageDetail}
        </NoData>
      </div>
    </div>
  )
}

export default PlaygroundListNoData;
