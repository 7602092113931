// @flow

import React, { memo, useState, useRef, useEffect } from 'react';
import useOnClickOutside from 'customHooks/useClickOutSide';
import { useHistory, useLocation } from 'react-router-dom';
import ROUTERS from 'constants/router';
import { ReactComponent as IconBack } from 'assets/icons/icon_back.svg';
import { ReactComponent as IconSearchPlayground } from 'assets/icons/search_playground.svg';
import { ReactComponent as IconDropdownPlayground } from 'assets/icons/dropdown_playground.svg';
import { ReactComponent as IconClosePlayground } from 'assets/icons/close_playgroud.svg';
import { ReactComponent as IconUpPlayground } from 'assets/icons/icon_up_playground.svg';

type Props = {
  handleBack: () => {},
  handleSearch: () => {},
  handleSelect: () => {},
  isShowSearchIcon: true,
  activeItem: {
    key: 'playground',
    text: '플레이그라운드',
  },
};

const HeaderPlaygroundComponent = ({
  handleBack,
  handleSearch,
  handleSelect,
  isShowSearchIcon,
  activeItem,
}: Props) => {
  const wrapperRef = useRef(null);
  const [isShowDropdown, setIsShowDropDown] = useState(false);
  const history = useHistory();
  const [active, setActive] = useState(activeItem);
  const location = useLocation();
  useEffect(() => {
    setActive(activeItem);
  }, [activeItem]);

  const hideModal = () => {
    setIsShowDropDown(false);
  };
  const hideModalClickOutSide = () => {
    setTimeout(()=>{
      setIsShowDropDown(false);
    },150)
  };
  useOnClickOutside(wrapperRef, hideModalClickOutSide);

  const showModal = () => {
    setIsShowDropDown(true);
  };

  const selectItem = (key, text) => {
    setActive({
      key,
      text,
    });
    hideModal();
    if (key === 'playground' && history) {
      history.push(ROUTERS.PLAYGROUND);
    } else if (key === 'q&a' && history) {
      history.push(`${ROUTERS.INQUIRY_PRODUCT}?type=back_main`);
    }
    // if (location.path === ) {
      
    // }
    handleSelect(key)
  };

  return (
    <>
      <div className="wrapper-header wrapper-header--playground">
        <div className="app-sub-header">
          <div className="app-sub-header__content d-flex align-items-center app-sub-header__content--center">
            <div className="app-sub-header__content__ico">
              <div className="d-flex align-items-center" style={{ width: 50 }}>
                <p
                  role="presentation"
                  className="icon_back"
                  onClick={() => handleBack()}
                >
                  <IconBack />
                </p>
              </div>
            </div>
            <div className="app-sub-header__content__text">
              <span role="presentation" onClick={() => showModal()}>
                {active.text}
              </span>
              <IconDropdownPlayground />
              {isShowSearchIcon && (
                <div
                  role="presentation"
                  className="btn-logout"
                  onClick={() => handleSearch()}
                >
                  <IconSearchPlayground />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`modal-playground ${isShowDropdown ? 'overlay' : ''}`}>
        {isShowDropdown && (
          <div className="modal-playground__content" ref={wrapperRef}>
            <ul>
              <li className="item default">
                <div
                  className="item-selected"
                  role="presentation"
                  onClick={() => hideModal()}
                >
                  <span className="selected">{active.text}</span>
                  <IconUpPlayground />
                  <div
                    className="img-close"
                    role="presentation"
                    onClick={() => hideModal()}
                  >
                    <IconClosePlayground />
                  </div>
                </div>
              </li>
              <li
                className={`item playground ${
                  active.key === 'playground' ? 'active' : ''
                }`}
                role="presentation"
                onClick={() => selectItem('playground', '플레이그라운드')}
              >
                <span>플레이그라운드</span>
                <div className="line" />
              </li>
              <li
                className={`item ${active.key === 'q&a' ? 'active' : ''}`}
                role="presentation"
                onClick={() => selectItem('q&a', '상품문의')}
              >
                <span>상품문의</span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

HeaderPlaygroundComponent.defaultProps = {
  handleBack: () => {},
  handleSearch: () => {},
  handleSelect: () => {},
  isShowSearchIcon: true,
  activeItem: {
    key: 'playground',
    text: '플레이그라운드',
  },
};
export default memo<Props>(HeaderPlaygroundComponent);
