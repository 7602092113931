import { useEffect, useMemo } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useCheckUpdate,
  useFilterParam,
  // import FormDate from 'components/Form/Date';
} from 'customHooks/utils';
import { useSelector } from 'react-redux';
import { Creators } from 'store/playground/index';

const getSelector = (key) => (state) => state['playgroundReducer'][key];

const loadingSelector = getSelector('isLoadingListComment');
const listSelector = getSelector('list');
const paramsSelector = getSelector('listParams');
const getListFailedSelector = getSelector('getListFailed');

const loadingCreateSelector = getSelector('isLoadingCreatePlaygroundComment');
const createSuccessSelector = getSelector('createPlaygroundCommentSuccess');
const createFailedSelector = getSelector('createPlaygroundCommentFailed');

const loadingUpdateSelector = getSelector('isLoadingUpdatePlaygroundComment');
const updateSuccessSelector = getSelector('updatePlaygroundCommentSuccess');
const updateFailedSelector = getSelector('updatePlaygroundCommentFailed');

const loadingDeleteSelector = getSelector('isLoadingDeletePlaygroundComment');
const deleteSuccessSelector = getSelector('deletePlaygroundCommentSuccess');
const deleteFailedSelector = getSelector('deletePlaygroundCommentFailed');

export const useListQueryParams = (id) => {
  const [param, setParam] = useFilterParam(null);

  useEffect(() => {
    if (id) {
      setParam([
        { type: 'page', value: 0 },
        { type: 'page_size', value: 0 },
        { type: 'type', value: '' },
        { type: 'relation', value: ['user'] },
        { type: 'inquiry', value: id },
        { type: 'sort_by', value: 'created_at' },
        { type: 'sort_direction', value: 'desc' },
      ]);
    }
  }, [id]);

  return [param, setParam];
};

export const useList = (param) => {
  return useFetchByParam({
    action: Creators.getListComment,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param,
  });
};

export const useCreate = (form) => {
  const [isLoading, handleSubmit] = useSubmit({
    loadingSelector: loadingCreateSelector,
    action: Creators.createPlaygroundComment,
  });

  useSuccess(createSuccessSelector, 'Create success', form.onSuccess);
  useFailed(createFailedSelector, 'Create Failed');

  const _handleSubmit = (values) => {
    return handleSubmit({
      ...values,
      relation: ['user'],
      page_size: 0
    });
  };

  return [isLoading, _handleSubmit];
};

export const useUpdate = (form) => {
  const [isLoading, handleSubmit] = useSubmit({
    loadingSelector: loadingUpdateSelector,
    action: Creators.updatePlaygroundComment,
  });

  useSuccess(updateSuccessSelector, 'Update success', form.onSuccess);
  useFailed(updateFailedSelector, 'Update Failed');

  // eslint-disable-next-line no-underscore-dangle
  const _handleSubmit = (values) => {
    return handleSubmit({
      ...values,
      comment_id: values.comment_id,
      _method: 'PUT',
      relation: ['user'],
      page_size: 0
    });
  };

  return [isLoading, _handleSubmit];
};

export const useDelete = (form) => {
  const [isLoading, handleSubmit] = useSubmit({
    loadingSelector: loadingDeleteSelector,
    action: Creators.deletePlaygroundComment,
  });

  useSuccess(deleteSuccessSelector, 'Delete success', form.onSuccess);
  useFailed(deleteFailedSelector, 'Delete Failed');

  // eslint-disable-next-line no-underscore-dangle
  const _handleSubmit = (values) => {
    return handleSubmit({
      comment_id: values.id,
      inquiry: values.inquiry_id,
      relation: ['user'],
      page_size: 0
    });
  };

  return [isLoading, _handleSubmit];
};
