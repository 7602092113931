// @flow
// libs
import React, { memo } from 'react';
import Button from 'components/Button';
import { ModalPopup } from 'components/Modal';

type Props = {
  type?: string,
  isShow?: boolean,
  handleShowModal?: Function,
  textBtnLeft?: string,
  textBtnRight?: string,
  title?: string,
  handleBtnLeft?: Function,
  handleBtnRight?: Function,
  className?: string
};


const ModalPlayground = ({
  type,
  isShow,
  handleShowModal,
  textBtnLeft,
  textBtnRight,
  title,
  handleBtnLeft,
  handleBtnRight,
  className
}: Props) => {
  return (
    <ModalPopup
      isOpen={isShow}
      // handleCloseOvl={() => handleShowModal(false)}
      handleClose={() => handleShowModal(false)}
      textBtnLeft={textBtnLeft}
      textBtnRight={textBtnRight}
      customClass={`playground-modal ${className}`}
    >
      <div className="content">
        <h3>{title}</h3>
        <div className="group-button-popup">
          {
            textBtnLeft &&  <Button
            type="button"
            customClass="button--half btn-left"
            onClick={() => handleBtnLeft(false)}
          >
            {textBtnLeft}
            </Button>
          }
         
          {
            textBtnRight && <Button
              type="button"
              customClass="button--half btn-right"
              onClick={() => handleBtnRight()}
            >
              {textBtnRight}
            </Button>
          }
        </div>
      </div>
    </ModalPopup>
  );
};

ModalPlayground.defaultProps = {
  type: '',
  isShow: false,
  handleShowModal: () => {},
  textBtnLeft: '',
  textBtnRight: '',
  title: '',
  handleBtnLeft: () => {},
  handleBtnRight: () => {},
  className: ''
};

export default memo<Props>(ModalPlayground);
