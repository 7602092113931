import React from 'react';
import images from '../../themes/images';
import { useReaction } from 'customHooks/playground/reaction';
import { abbreviateNumber } from 'utils/Helpers'

function LikeComment({
  className,
  data,
  onCommentClick,
  itemDisplayFormat = '1',
}) {
  const [loading, onReaction] = useReaction();
  const _clickComment = () => {
    if (onCommentClick) {
      onCommentClick();
    }
  };

  const _onReaction = () => {
    onReaction(data);
  };

  const likeIcon1 =
    data.is_liked === 'true'
      ? images.icon_playground_heart_active
      : images.icon_playground_heart;
  const likeIcon2 =
    data.is_liked === 'true'
      ? images.icon_inquiry_heart_active
      : images.icon_inquiry_heart;

  const commentIcon =
    itemDisplayFormat == '1'
      ? images.icon_playground_comment
      : images.icon_inquiry_comment;

  return (
    <div
      className={`box-action-like-comment not-has-image${
        className ? ' ' + className : ''
      }`}
    >
      <div className="item-action item-like" onClick={_onReaction}>
        <img
          className=""
          src={itemDisplayFormat == '1' ? likeIcon1 : likeIcon2}
          alt=""
        />
        <label>{abbreviateNumber(data?.like_cnt || 0)}</label>
      </div>
      <div
        className={`item-action item-comment ${
          itemDisplayFormat == '1' ? ' d-none' : ''
        }`}
        onClick={_clickComment}
      >
        <img className="" src={commentIcon} alt="" />
        <label>{abbreviateNumber(data?.comment_cnt || 0)}</label>
      </div>
    </div>
  );
}

export default LikeComment;
