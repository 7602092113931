import React from 'react';
import TimeAgoComponent from './TimeAgo';
import AvatarDefault from '../../assets/icons/avatar_default.svg';

function UserInfo({ user, createdAt, inline, onClick, timeAgo = false, highlight = '' }) {
  const hightLightitem = (value, text) => {
    if (!value) {
      return text;
    }
    const regex = new RegExp(`(${value})`, "gi");
    const nameHightlight = text.replace(regex, "<mask class='highlight'>$1</mask>")

    return nameHightlight
  }

  return (
    <div
      className={`item-info-user${inline ? ' w-100' : ''}`}
      onClick={onClick}
    >
      <img
        className="item-info-user__avatar"
        src={user?.avatar?.thumbnail || AvatarDefault}
        alt={user?.nickname || user?.name}
      />
      <div
        className={`
          item-info-user__text
          ${
            inline
              ? ' w-100 d-flex justify-content-between align-items-center'
              : ''
          }
        `}
      >
        <div
          className="nick-name"
          dangerouslySetInnerHTML={{ __html: hightLightitem(highlight, user?.nickname || user?.name) }}
        />
        <label className="created-at">
          {/* {timeAgo ?
            <TimeAgoComponent datetime={createdAt} />
          : createdAt
          } */}
          <TimeAgoComponent datetime={createdAt} />
        </label>
      </div>
    </div>
  );
}

export default UserInfo;
