import React, { useEffect, useState } from 'react';
import DropdownAction from './../DropdownAction';
import UserInfo from './../UserInfo';
import Image from './../Image';
// import LikeComment from './../LikeComment';
import PostInfo from './../PostInfo';
import Hashtags from './../Hashtags';
import LikeComment from './../LikeComment';
import PlaygroundCommentForm from './Form';
import AvatarDefault from '../../../assets/icons/avatar_default.svg';
import { useDelete, useUpdate } from 'customHooks/playground/comment';
import { formatDate } from 'utils/Helpers';
import ModalPlayground from '../ModalPlayground';
import TimeAgoComponent from '../TimeAgo';
import LoadingCommunity from '../Loading';
import SkeletonLoadingComponent from 'components/SkeletonLoading';
import { useSelector } from 'react-redux';

function PlaygroundCommentItem({
  item,
  isEditComment,
  setEditComment,
  userInfo,
  type,
  dateTimeFormat = 'Y.MM.DD HH:mm:ss',
  handleFocusIpt = ()=>{}
}) {

  // eslint-disable-next-line no-underscore-dangle
  const [dataModal, setDataModal] = useState({
    isShow: false,
    type: '',
    textBtnLeft: '취소',
    textBtnRight: '삭제',
    title: '댓글을 삭제 하시겠습니까?',
  })

  const _onSuccess = () => {
    console.log('_onSuccess delete');
    setEditComment(false)
    window.showLoadingNative(false)
  }

  const [loadingDelete, onDelete] = useDelete({ onSuccess: _onSuccess });
  const [loadingUpdate, ] = useUpdate({ onSuccess: () => {} });

  // eslint-disable-next-line no-shadow
  const handleConfirmDelete = (item) => {
    setDataModal({
      isShow: true,
      type: 'delete',
      textBtnLeft: '취소',
      textBtnRight: '삭제',
      title: '댓글을 삭제 하시겠습니까?',
      data: item
    })
  };

  const handleCloseModal = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const handleCancelDelete = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const handleDelete = () => {
    setEditComment(false)
    onDelete(dataModal.data);
    setDataModal({
      ...dataModal,
      isShow: false,
    })
    window.showLoadingNative(true)
  }
  
  const handleEdit = (item) => {
    setEditComment(true);
  };

  const onSuccess = () => {
    setEditComment(false);
  };

  return (
    <div className="item-comment">
      <div className="wrap-item-comment">
        <div className="comment-left">
          <div className="img-user">
            {item?.user?.avatar ? (
              <img src={item?.user?.avatar?.thumbnail} alt="" />
            ) : (
              <img src={AvatarDefault} alt="" />
            )}
          </div>
        </div>
        <div className="comment-right">
          <div className="comment-right__info">
            <div className={`left ${type === 'QNA' ? 'left--qna' : ''}`}>
              <div className="user-name">{item?.user?.nickname || item?.user?.name}</div>
              {
                type === 'PLAYGROUND' && <span className="dot-space" />
              }
              <div className="time">
                {/* {
                  type === 'PLAYGROUND'
                    ? <TimeAgoComponent datetime={item?.created_at} />
                    : formatDate(item?.created_at, '', dateTimeFormat)
                } */}
                {
                  formatDate(item?.created_at, '', dateTimeFormat)
                }
              </div>
            </div>
            <div className="right" role="presentation">
              {/* <IconThreeDotCmt /> */}
              <DropdownAction
                isShow={item?.user_id === userInfo?.uid}
                data={item}
                deleteAction={handleConfirmDelete}
                editAction={handleEdit}
              />
            </div>
          </div>
          <div className="comment-content">
            {
              (loadingUpdate && isEditComment) ? <SkeletonLoadingComponent height={16} width={150}/> : <div className="content" 
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
              __html: item?.comment || '',
              }} />
            }
            
          </div>
          {(isEditComment && !loadingUpdate) && (
            <PlaygroundCommentForm defaultValues={item} handleFocusIpt={handleFocusIpt} onSuccess={onSuccess} comment={item?.comment || ''} type="editCmt"/>
          )}
        </div>
      </div>

      <ModalPlayground
        isShow={dataModal.isShow}
        handleShowModal={handleCloseModal}
        textBtnLeft={dataModal.textBtnLeft}
        textBtnRight={dataModal.textBtnRight}
        title={dataModal.title}
        handleBtnLeft={handleCancelDelete}
        handleBtnRight={handleDelete}
        type={''}
      />

    </div>
  );
}

export default PlaygroundCommentItem;
