// @flow

import React, { lazy, Suspense } from 'react';
import { bindActionCreators } from 'redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import ROUTERS from 'constants/router';
import { connect } from 'react-redux';
import PrivateRoute from 'utils/PrivateRoute';
import pageFAQDetailContainer from 'screens/pageNotice/containers/pageFAQDetailContainer';
import pageAnnoucementDetailContainer from 'screens/pageNotice/containers/pageAnnoucementDetailContainer';
import createStore from 'store/createStore';
import { Creators } from '../account/redux';
import FindStoreComponent from '../screens/findStore/containers/findStoreContainer';
import FindStoreLikeComponent from '../screens/findStore/containers/findStoreLikeContainer';
import ProductContainer from '../screens/productPage/containers/productContainer';
import PageOrderDetailContainer from '../screens/order/components/detail';
import PageEventContainer from '../screens/event/containers/eventContainer';
import PageEventDetailComponent from '../screens/event/components/detail';
import PageConsultionContainer from '../screens/consultion/containers/consultionContainer';
import PageConsultionDetailComponent from '../screens/consultion/components/detail';
import ConsutantCreateComponent from '../screens/consultion/components/create';
import PagePrivacyPolicyComponent from '../screens/policy/components/list';
import PagePolicyWebComponent from '../screens/policy/components/policy';
import PagePolicyHandlePersonalInfomationComponent from '../screens/policy/components/policyHandlePersonalInfomation';
import PlaygroundContainer from '../screens/playground/containers/playgroundContainer';
import PlaygroundSearchContainer from '../screens/playground/containers/playgroundSearchContainer';
import PlaygroundCreateContainer from '../screens/playground/containers/playgroundCreateContainer';
import PlaygroundDetailContainer from '../screens/playground/containers/playgroundDetailContainer';
import PlaygroundEditContainer from '../screens/playground/containers/playgroundEditContainer';
import createCommunityQAContainer from '../screens/productPage/containers/createCommunityQAContainer';
import editCommunityQAContainer from '../screens/productPage/containers/editCommunityQAContainer';
import pageCommunityQADetailContainer from '../screens/productPage/containers/pageCommunityQADetailContainer';


const registerProductContainer = lazy(() =>
  import('../screens/productPage/containers/registerProductContainer')
);

const PageNoticeContainer = lazy(() =>
  import('../screens/pageNotice/containers/pageNoticeContainer')
);

const PolicyContainer = lazy(() => import('screens/policy/components'));

const mainGroupBuyContainer = lazy(() =>
  import('../screens/order/containers/mainGroupBuyContainer')
);
const purchaseHistoryContainer = lazy(() =>
  import('../screens/purchaseHistory/containers/purchaseHistoryContainer')
);
const detailOrderContainer = lazy(() =>
  import('screens/order/components/orderBook')
);

const storeSelectionContainer = lazy(() =>
  import('screens/order/containers/selectStoreEventContainer')
);

const PageOrderCompleteContainer = lazy(() =>
  import('../screens/order/components/orderComplete')
);
const PageOrderHistoryDetailContainer = lazy(() =>
  import('../screens/purchaseHistory/containers/purchaseHistoryDetailContainer')
);
const PageQaCreate = lazy(() => import('../screens/order/components/createQA'));
const PageQaEdit = lazy(() => import('../screens/order/components/editQA'));
const PageQaManagement = lazy(() =>
  import('../screens/qna/containers/qnaAdminContainer')
);
const PageQaHistory = lazy(() =>
  import('../screens/qna/containers/qnaHistoryContainer')
);

type Props = {
  token: string,
  settingApp: Object,
  setInfoFromApp: Function,
};

const Router = ({ token, settingApp }: Props) => {
  // alert(token);
  const { store } = createStore();

  // if (window.tokenApp) {
  //   API.setHeader('Authorization', `Bearer ${window.tokenApp}`);
  // } else {
  //   document.getElementById('token').innerHTML = 'tokennnnnnnnnnnnnnnnnullllllllll'
  // }

  const isAuthenticated = window.tokenApp;

  // document.getElementById('www').innerHTML = window?.tokenApp !== ''
  return (
    <BrowserRouter>
      <Suspense fallback="" >
        <Switch>
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.FIND_STORE}
            component={FindStoreComponent}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.FIND_STORE_FAVORITE}
            component={FindStoreLikeComponent}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.INQUIRY_PRODUCT}
            component={ProductContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.INQUIRY_PRODUCT_REGISTER}
            component={createCommunityQAContainer}
          />
          <Route
            // isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.TERM_AND_POLICY}
            component={PolicyContainer}
          />

          <Route
            exact
            path={ROUTERS.PRIVACY_POLICY}
            component={PagePrivacyPolicyComponent}
          />

          <Route
            exact
            path={ROUTERS.POLICY_WEB}
            component={PagePolicyWebComponent}
          />

          <Route
            exact
            path={ROUTERS.POLICY_HANDLE_PERSONAL_INFORMATION}
            component={PagePolicyHandlePersonalInfomationComponent}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.PRODUCT_ADD}
            component={registerProductContainer}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.NOTICE_PAGE}
            component={PageNoticeContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.INQUIRY_PRODUCT_DETAIL}
            component={editCommunityQAContainer}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.INQUIRY_PRODUCT_PAGE_DETAIL}
            component={pageCommunityQADetailContainer}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.ORDER}
            component={mainGroupBuyContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.PUSCHASE_HISTORY}
            component={purchaseHistoryContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.ORDER_BOOK}
            component={detailOrderContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.ORDER_DETAIL}
            component={PageOrderDetailContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.ORDER_COMPLETE}
            component={PageOrderCompleteContainer}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.STORE_SELECTION}
            component={storeSelectionContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.PUSCHASE_HISTORY_DETAIL}
            component={PageOrderHistoryDetailContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.QA_CREATE}
            component={PageQaCreate}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.QA_DETAIL}
            component={PageQaEdit}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.QA_MANAGEMENT}
            component={PageQaManagement}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.QA_HISTORY}
            component={PageQaHistory}
          />

          <Route
            exact
            path={ROUTERS.FAQ_DETAIL}
            component={pageFAQDetailContainer}
          />

          <Route
            exact
            path={ROUTERS.ANNOUCEMENT_DETAIL}
            component={pageAnnoucementDetailContainer}
          />

          {/* router event */}
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.EVENT}
            component={PageEventContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.EVENT_DETAIL}
            component={PageEventDetailComponent}
          />

          {/* router consultion */}
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.CONSULTION}
            component={PageConsultionContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.CONSULTION_DETAIL}
            component={PageConsultionDetailComponent}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.CONSULTION_CREATE}
            component={ConsutantCreateComponent}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.PLAYGROUND}
            component={PlaygroundContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.PLAYGROUND_SEARCH}
            component={PlaygroundSearchContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.PLAYGROUND_CREATE}
            component={PlaygroundCreateContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.PLAYGROUND_EDIT}
            component={PlaygroundEditContainer}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.PLAYGROUND_DETAIL}
            component={PlaygroundDetailContainer}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state?.accountReducer?.token,
    settingApp: state?.settingAppReducer?.settingApp,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...Creators }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Router);
