import React, { useEffect, useState, useRef } from 'react';
import { checkKeywordFobiden } from 'utils/Helpers';
import { useCreate, useUpdate } from 'customHooks/playground/comment';
import { useSelector } from 'react-redux';
import ModalPlayground from '../ModalPlayground';


function PlaygroundCommentForm({
  comment,
  defaultValues,
  onSuccess,
  type,
  submitText = '',
  scrollTop,
  handleFocusIpt,
}) {
  const [values, setValues] = useState({
    content: defaultValues?.comment || '',
    inquiry: defaultValues?.inquiry_id || '',
    comment_id: defaultValues?.id || '',
    
  });

  const [dataModal, setDataModal] = useState({
    isShow: false,
    type: '',
    textBtnLeft: null,
    textBtnRight: '확인',
    title: '모음을 마지막에 입력한 상태로 저장 할 수 없습니다.',
  })
  const [ isFocus, setIsFocus ] = useState(false)
  const textAreaRef = useRef(null);
  const loading = useSelector((state) => state.playgroundReducer)
  useEffect(() => {
    setValues({
      content: defaultValues?.comment || '',
      inquiry: defaultValues?.inquiry_id || '',
      comment_id: defaultValues?.id || '',
    });
  }, [defaultValues?.content, defaultValues?.id, defaultValues?.inquiry_id]);

  useEffect(() => {
    if(typeof handleFocusIpt == 'function') {
      handleFocusIpt(isFocus);
    }
  }, [isFocus])

  const onChange = (e) => {
    if (e.target?.value === ' ') return;
    e.persist();
    if (e.target?.value.replace(/\s+/g, ' ').length <= 100) {
      setValues((p) => ({
        ...p,
        content: e.target?.value || '',
      }));
    }
  };

  // eslint-disable-next-line no-underscore-dangle
  const _onSuccess = () => {
    setValues((p) => ({
      ...p,
      content: '',
    }));
  };

  // eslint-disable-next-line no-underscore-dangle
  const _onUpdateSuccess = () => {
    if (onSuccess && !loading?.isLoadingListComment) {
      onSuccess();
    }
  }
  
  const [loadingCreate, onCreate] = useCreate({ onSuccess: _onSuccess });
  const [loadingUpdate, onUpdate] = useUpdate({ onSuccess: _onUpdateSuccess });

  const onSubmit = (e) => {
    e.preventDefault();

    // update
    if (comment) {
      // update
      if (checkKeywordFobiden(values.content)) {
        setDataModal({
          ...dataModal,
          isShow: true
        })
      }else{
        setValues((p) => ({
          ...p,
          content: '',
        }));
        onUpdate(values);
        
      }
    } else if (checkKeywordFobiden(values.content)) {
        setDataModal({
          ...dataModal,
          isShow: true
        })
      }else{
        setValues((p) => ({
          ...p,
          content: '',
        }));
        onCreate(values);
        if (scrollTop) {
          scrollTop()
        }
      }
  };

  const resizeTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  
  const handleCloseModal = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const handleCancelDelete = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const handleDelete = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const handleFocus = () => {
    console.log('handleFocus');
    setIsFocus(true)
    // .wrapper-page-footer
  }

  const handleBlur = () => {
    setIsFocus(false)
  }


  useEffect(resizeTextArea, [values.content]);

  return (
    <form onSubmit={onSubmit}>
    {/* <input className="form-control" value={values.comment} onChange={onChange} />
    <button type="submit">수정</button> */}
    {
      type === 'editCmt' && (
        <div className="comment-reply">
          <div className="input-comment">
            <textarea
              ref={textAreaRef}
              maxLength="100"
              onChange={onChange}
              className="input-comment__custom"
              name="content"
              rows="1"
              value={values.content}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </div>
          <div className="button-reply-comment ed">
            <button
              className="btn button-reply-comment__custom"
              disabled={loadingUpdate || values.content === ''}
              type="submit"
            >
              {submitText || '수정'}
            </button>
          </div>
          
        </div>
    )
  }
    {
      type === 'createCmt' &&  <div className="wrapper-page-footer__group">
        <div className="input-comment">
          <textarea
            ref={textAreaRef}
            maxLength="100"
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="input-comment__custom"
            name="content"
            rows="1"
            placeholder="댓글을 입력해주세요."
            value={values.content}
          />
        </div>
        <div className="button-comment">
          <button
            type="submit"
            className="btn button-comment__custom"
            disabled={loadingCreate || loading?.isLoadingDetail || loading?.isLoadingListComment || loading?.isLoadingDeletePlaygroundComment || values.content === ''}
          >
            {submitText || '등록'}
          </button>
        </div>
      </div>
    }
    <ModalPlayground
      isShow={dataModal.isShow}
      handleShowModal={handleCloseModal}
      textBtnLeft={dataModal.textBtnLeft}
      textBtnRight={dataModal.textBtnRight}
      title={dataModal.title}
      handleBtnLeft={() => {}}
      handleBtnRight={handleCloseModal}
      type={''}
    />
  </form>
  );

}

export default PlaygroundCommentForm;
