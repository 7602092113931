import React, { Fragment, useState } from 'react';
import images from '../../../../themes/images';
import FilterDefault from './Default';
import FilterFavorite from './Favorite';
// import FilterNearBy from './NearBy';
import FilterPopular from './../Filter/Popular';
import useChangeUrlParams from 'customHooks/useChangeUrlParams'

const types = {
  // '': FilterDefault,
  1: FilterDefault,
  2: FilterFavorite,
  3: FilterPopular,
};

function ListFilter({ param, setParam, loading, data, reloadPage, setLoadingByFilter }) {
  const { changeUrlParams } = useChangeUrlParams();
  const [filterType, setFilterType] = useState(param.filterType)
  const FilterByType = types[filterType];

  const _onChangeType = (newFilterType) => {
    if (newFilterType === filterType) {
      // filterType = '';
      return;
    }
    setFilterType(newFilterType)

    changeUrlParams(param, [
      { type: 'filterType', value: newFilterType },
      { type: 'reload', value: '0' },
      { type: 'current_time', value: '' },
      // { type: 'page', value: 1 },
      // { type: 'store_id', value: '' },
      // { type: 'hash_tag', value: '' },
      // { type: 'keyword', value: '' },
      // { type: 'hash_tag_id', value: '' },
      // { type: 'has_like_cnt', value: '' },
      // { type: 'has_hashtag', value: '' },
    ]);
  };

  return (
    <Fragment>
      <div className="main-box-category">
        <button
          type="button"
          className={`btn-category btn-favories btn-all${
            filterType === 1 ? ' active' : ''
          }`}
          onClick={() => _onChangeType(1)}
        >
          <img className="" src={images.icon_playground_filter_all} alt="" />
          <label>전체</label>
        </button>
        <button
          type="button"
          className={`btn-category btn-nearby${
            filterType === 2 ? ' active' : ''
          }`}
          onClick={() => _onChangeType(2)}
        >
          <img className="" src={images.icon_playground_hometown} alt="" />
          <label>우리동네</label>
        </button>
        <button
          type="button"
          className={`btn-category btn-trending${
            filterType === 3 ? ' active' : ''
          }`}
          onClick={() => _onChangeType(3)}
        >
          <img className="" src={images.icon_playground_trending} alt="" />
          <label>인기있는</label>
        </button>
      </div>
      {/*{!(loading && param.type) ? (*/}
      <FilterByType
        param={param}
        setParam={setParam}
        loading={loading}
        data={data}
        reloadPage={reloadPage}
        setLoadingByFilter={setLoadingByFilter}
        title={
          <Fragment>
            <img className="" src={images.icon_playground_marker} alt="" />
            <label>가장 가까운 매장에 올라온 게시글이 궁금하다면?</label>
          </Fragment>
        }
      />
      {/*) : null}*/}
    </Fragment>
  );
}

export default ListFilter;
