import React, { memo, useEffect, useState, useRef, Fragment } from 'react';
import MainLayout from 'layout/MainLayout';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'query-string'
import moment from 'moment'

import SearchFilter from '../components/SearchFilter/index';
import PlaygroundListNoData from '../components/ListNoData';
import ROUTERS from '../../../constants/router';
import PlaygroundList from 'components/Playground/List';
import PlaygroundLoading from 'components/Playground/Loading';
import useCountSize from 'customHooks/playground/useCountSize';

import useChangeUrlParams from 'customHooks/useChangeUrlParams'
import {
  useListQueryUrlParams,
  useListQueryParams,
  useList,
  useListResParams,
} from 'customHooks/playground/index';

const PlaygroundSearchContainer = () => {
  const history = useHistory();
  const [backUrl, setBackurl] = useState(ROUTERS.PLAYGROUND)

  const { changeUrlParams } = useChangeUrlParams();
  const param = useListQueryUrlParams('PLAYGROUND', moment().utc().format('YYYY-MM-DD HH:mm:ss'));
  // const [param, setParam] = useListQueryParams('PLAYGROUND');
  const [list, loading] = useList(param);
  const resParams = useListResParams();
  useCountSize(param.keyword, loading);

  useEffect(() => {
    if (history.location.state) {
      let params = history.location.state
      // params.reload = '1'
      setBackurl(ROUTERS.PLAYGROUND + `?${queryString.stringify(params)}`)
    }
  }, [history.location?.state])

  const handleBack = () => {
    history.push(backUrl);
  };

  window.backFromDeviceAndroid = function() {
    handleBack();
  }

  const _delete = (item) => {
    console.log('delete', item);
  };

  const goToQADetail = (item) => {
    const url = ROUTERS.PLAYGROUND_EDIT.replace(':id', item.id);
    history.push(url);
  };

  const goToCreate = () => {
    history.push(ROUTERS.PLAYGROUND_CREATE);
  };

  return (
    <MainLayout
      isShowHeader
      titleHeader="검색"
      isLink
      isShowIcon
      customClass="playground-main playground-search-main"
      typeLogin
      isBackNative={false}
      isShowIconBackFunction
      iconBackFunction={handleBack}
    >
      <div className='playground-search-main__filter'>
        <div className="auto-complete">
          <SearchFilter
            param={param}
            loading={loading}
            data={list}
          />

        </div>
      </div>
          
      <div className={`wrapper-page-playground box-data-search ${param.keyword ? 'd-block' : 'd-none'}`}>
        {!list.length && !loading ?
          <PlaygroundListNoData
            param={param}
            backgroundBlank
            noDataMessage="검색 결과가 없습니다."
          />
        : 
          <InfiniteScroll
            dataLength={(list && list.length) || 0}
            next={() => {
              changeUrlParams(param, [{ type: 'page', value: +param.page + 1 }]);
            }}
            hasMore={list && list.length < resParams.total && !loading}
          >
            <PlaygroundList
              data={list}
              loading={loading}
              param={param}
              deleteAction={_delete}
              editAction={goToQADetail}
              omitHashtags
              timeAgo
              highlight={param.keyword}
            />
          </InfiniteScroll>
        }
      </div>


    </MainLayout>
  );
};

export default memo(PlaygroundSearchContainer);
