// @flow

import React, { memo, useState, useEffect, useRef } from 'react';
import queryString from 'query-string'
// import Immutable from 'seamless-immutable';
import MainLayout from 'layout/MainLayout';
import ModalPopup from 'components/Modal';
import images from 'themes/images';
import Button from 'components/Button';
import InputAutoCompleteSearch from 'components/AutoComplete';
import { checkPermissionCamera } from 'utils/Helpers';
import { useCreate } from 'customHooks/playground';
import { useHistory } from 'react-router-dom';
import { useListStore, useListStoreFavoriteParam, useListStoreFavorite } from 'customHooks/store';
import ROUTERS from 'constants/router';
import ModalPlayground from 'components/Playground/ModalPlayground';

type Props = {
  history: {
    push: Function,
  },
  // storeListLikeOfUser: Array,
  // listAllStore: Array,
  // getListAllStore: Function
};

const FILE_TYPES =
  'image/png,image/jpeg,image/jpg,image/gif';

const CreateCommunityQAComponent = ({
  // storeListLikeOfUser,
  // listAllStore,
  // getListAllStore
}: Props) => {
  const history = useHistory();
  const [isShowData, setIsShowData] = useState(true);
  const param = useListStoreFavoriteParam();
  const [storeListLikeOfUser, ] = useListStoreFavorite(param);
  const [showModal, setShowModal] = useState({
    isShow: false,
    content: '',
  });
  const [dataInquiry, setDataInquiry] = useState({
    content: '',
    productImg: '',
    imageView: '',
    data_store: {},
  });
  // const [isCheckPermission, setIsCheckPermission] = useState(false);
  const clickFile = useRef(null);

  const [listAllStore, loadingStore] = useListStore();
  const [backUrl, setBackurl] = useState(ROUTERS.INQUIRY_PRODUCT)

  useEffect(() => {
    window.setPermissionCamera = function(data) {
      window.permissionCamera = data;
      if (data) {
        document.getElementById('btnUploadFile').classList.remove("d-none");
        // document.getElementById('btnUploadFile').click();
        clickFile.current.click()
      }
      // setIsCheckPermission(data);
    }

    return () => {
      window.setPermissionCamera = undefined
    }
  })

  useEffect(() => {
    if (history.location.state) {
      let paramList = history.location.state
      paramList.current_time = ''
      setBackurl(ROUTERS.INQUIRY_PRODUCT + `?${queryString.stringify(paramList)}`)
    }
  }, [history.location?.state])

  const checkAcceptFile = (file, accept) => {
    let acceptArr = (accept || '').split(',');
    if (accept && !acceptArr.includes(file.type)) {
      return false;
    }
  
    return true;
  };

  const handleChangeFile = (e) => {
    checkPermissionCamera(window.permissionCamera)
    if (!window?.permissionCamera) {
      return false;
    }
    const { files } = e.target;

    console.log(files[0], 'files[0]');
    if (files[0]) {
      if (!checkAcceptFile(files[0], FILE_TYPES)) {
        setShowModal({
          ...showModal,
          isShow: true,
          content:
            '이미지 형식의 파일만 업로드 가능합니다.',
        });
        return;
      }

      if (files[0]?.size > 20971520) {
        setShowModal({
          ...showModal,
          isShow: true,
          content:
            '파일 크기가 너무 큽니다. \n 20MB 미만의 크기를 선택하십시오',
        });
      } else {
        setDataInquiry({
          ...dataInquiry,
          productImg: files[0] || '',
          imageView: (window.URL || window.webkitURL).createObjectURL(
            e.target.files[0]
          ),
        });
      }
    }
  };

  const handlePermission = () => {
    if (!window?.permissionCamera || window?.permissionCamera === 'false') {
      // alert('callRequestPermissionNative');
      window.callRequestPermissionNative();
    }else{
      document.getElementById('btnUploadFile').classList.remove("d-none")
    }

  }

  const handleChange = (value) => {
    if (value === ' ') return;
    if (value.length >= 2000) {
      value = value.slice(0, 2000)
    }
    setDataInquiry({
      ...dataInquiry,
      content: value,
    });
  };

  const handleDelete = () => {
    setDataInquiry({
      ...dataInquiry,
      imageView: '',
      productImg: '',
    });
  };



  const { content, imageView, productImg, data_store } = dataInquiry;

  const handleSucess = () => {
    setDataInquiry({
      content: '',
      productImg: '',
      imageView: '',
    });
    if (backUrl) {
      history.push(backUrl)
    } else {
      history.go(-1);
    }
  }

  const [loadingCreate, onCreate] = useCreate({ onSuccess: handleSucess, onFailed: () => {
    setShowModal({
      ...showModal,
      isShow: true,
      content: '제품 등록 실패.',
    });
  } });

  const handleCreateQA = () => {
    const values = {
      type: 'QNA',
      title: 'title',
      content,
      image: [productImg || ''],
      image_remove: [],
      store_id: [data_store?.id],
    }
    onCreate(values)
  };

  const handleSearch = (item) => {
    console.log(item, 'handleSearch');
    setDataInquiry({
      ...dataInquiry,
      data_store: item,
    });
    setIsShowData(true)
  };

  const handleSearchText = (item) => {
    console.log(item, 'handleSearchText');
  };

  const onChangeInputSearch = (str) => {
  };

  const showDataProduct = (isShow) => {
    setIsShowData(!isShow);
  };

  const handleForcusIpt = (isShow) => {
  }

  const handleClickSelectDropdown = () => {
  };

  const handleSelectStore = (item) => {
    console.log(item, 'item');
    setDataInquiry({
      ...dataInquiry,
      data_store: {
        ...item,
        id: item.id
      },
    });
  }

  const renderListStore = lists => {
    if (lists && lists.length > 0) {
      return lists.map((item) => (
        <li
          key={item.store_id}
          className={`item ${(dataInquiry.data_store?.id && dataInquiry.data_store?.id === item.id) ? 'selected' : ''}`}
          aria-hidden
          onClick={() => handleSelectStore(item)}
        >
          {item.name}
        </li>
      ));
    }
  };

  return (
    <MainLayout
      isMarginBottom
      isShowHeader
      titleHeader="상품 문의 글쓰기"
      isCenter
      isShowIcon
      isLink
      isLoading={loadingCreate || loadingStore}
      customClass="mb-0 min-height"
    >
      <div className="inquiry-product-page mh-90vh mb-80">
        <div className="form-inquiry">
          <div className="form-group">

            <div className='list-stores-like'>
              <h2>즐겨 찾는 매장</h2>
              <ul className='lists'>
                {renderListStore(storeListLikeOfUser || [])}
              </ul>
            </div>

            <InputAutoCompleteSearch
              data={listAllStore || []}
              handleSearch={handleSearch}
              handleSearchText={handleSearchText}
              onChangeInputSearch={onChangeInputSearch}
              showDataProduct={showDataProduct}
              placeholder="가맹점명을 입력해 주세요"
              showBoxResult
              handleClickSelectDropdown={handleClickSelectDropdown}
              dataSelected={dataInquiry.data_store || {}}
              classDiv="flex-search"
              classResult="find-store-like-search"
              // showIconSearch
              showIconPositionSearch
              // showDataFocus
              handleForcusIpt={handleForcusIpt}
            />
          </div>
          {isShowData ? (
            <>
              <textarea
                name="contents"
                rows="3"
                // eslint-disable-next-line react/jsx-no-duplicate-props
                placeholder='문의 하실 내용을 입력해주세요'
                maxLength="2000"
                className="form-control content-textarea"
                value={content}
                onChange={(e) => handleChange(e.target.value)}
              />
              <div className="group-btn-file">
                {imageView ? (
                  <div className="img-view">
                    <div
                      className="icon-delete"
                      onClick={() => handleDelete()}
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex={0}
                    >
                      <img src={images.iconClose} alt="" />
                    </div>
                    <img src={imageView} alt="" className="h-100" />
                  </div>
                ) : (
                  <>
                    <input
                      type="file"
                      className="custom-file-input d-none" 
                      id='btnUploadFile'
                      value=""
                      ref={clickFile}
                      onChange={(e) => handleChangeFile(e)}
                      accept="image/jpg, image/jpeg, image/png, capture=camera"
                    />
                     {/* onClick={() => handlePermission()} */}
                    <div className="upload-img" onClick={() => handlePermission()}>
                      <div className="text-upload">
                        이미지 <br />
                        추가
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : <>
            
          </>}
        </div>
        {isShowData && (
          <Button
            customClass="absolute mt-3 btn-register-inquiry"
            isDisabled={!content || !data_store?.id}
            onClick={() => handleCreateQA()}
          >
            작성 완료
          </Button>
        )}
      </div>
{/* 
      <ModalPopup
        isOpen={showModal?.isShow}
        isShowFooter
        isShowHeader
        handleClose={() =>
          setShowModal({
            ...showModal,
            isShow: false,
            content: '',
          })
        }
        textBtnRight="확인"
      >
        <div className="content">{showModal?.content}</div>
      </ModalPopup> */}

      <ModalPlayground
        isShow={showModal?.isShow}
        // handleShowModal={handleCloseModal}
        // textBtnLeft={'확인'}
        textBtnRight={'확인'}
        title={showModal?.content}
        handleBtnRight={() => {
          setShowModal({
            ...showModal,
            isShow: false,
            content: '',
          })
        }}
        type={''}
      />

    </MainLayout>
  );
};

export default memo<Props>(CreateCommunityQAComponent);
