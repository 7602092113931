import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageCommunityQADetailComponent from '../components/detailCommunityQA';
import { Creators } from '../redux';

const dataUser = JSON.parse(localStorage.getItem("DATA_INFO")) 

const mapStateToProps = (state) => {
  return {
    isProcessing: state.productReducer.isProcessing,
    userInfo: dataUser || state.accountReducer.userInfo,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...Creators,
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(PageCommunityQADetailComponent);
