import React, { Fragment, useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import DropdownAction from './../DropdownAction';
import UserInfo from './../UserInfo';
import Image from './../Image';
// import LikeComment from './../LikeComment';
import PostInfo from './../PostInfo';
import Hashtags from './../Hashtags';
import LikeComment from './../LikeComment';
import PlaygroundCommentItem from './Item';
import PlaygroundCommentForm from './Form';
import {
  useListQueryParams,
  useList,
  useCreate,
} from 'customHooks/playground/comment';
import { ReactComponent as IconEmptyCmt } from '../../../assets/icons/empty_cmt.svg';
import LoadingCommunity from '../Loading';

const chunkArray = (array, chunkSize = 10) => {
  let newArray = []
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    newArray.push(chunk)
  }

  return newArray
}

// display: '1', '2'
function PlaygroundCommentList({
  data,
  type,
  userInfo,
  showFormCreate = false,
  dateTimeFormat = 'Y.MM.DD H:mm:ss',
  scrollTop,
  infinityScroll = false,
  scrollPerpage = 5,
}) {
  const ref = useRef(null)
  const [editComment, setEditComment] = useState(-1);
  const [param, setParam] = useListQueryParams(data?.id || null);
  const [ listComment, setListComment ] = useState([])
  const [ pageComment, setPageComment ] = useState(0)
  const [ loadingLoadmoreComment, setLoadingLoadmore ] = useState(false)
  const [ isFirstLoaded, setFirstLoader ] = useState(true)
  const [cmt, loadingCmt] = useList(param);
  const [loadingCreate] = useCreate({ onSuccess: () => {} });

  const handleFocusIpt = (data) => {
    // const boxCreateQna = document.getElementById('btn-create-qna')
    // if(boxCreateQna){
    //   if(data){
    //     boxCreateQna.classList.add('d-none')
    //   }else {
    //     boxCreateQna.classList.remove('d-none')
    //   }
    // }
  }

  useEffect(() => {
    if (loadingCreate) {
      setEditComment(-1)
    }
  }, [loadingCreate])

  useEffect(() => {
    if (data?.comment) {
      if (infinityScroll) {
        let newArray = chunkArray(data.comment, scrollPerpage * (pageComment + 1))
        setListComment(newArray[0])
        checkPageToLoadMore()
      } else {
        setListComment(data?.comment)
      }
    }
  }, [data?.comment])

  const checkPageToLoadMore = () => {
    setTimeout(() => {
      if (ref.current) {
        let domRect = ref.current.getBoundingClientRect()
        var spaceBelow = window.innerHeight - domRect.bottom
        if (spaceBelow > 100) { // - height cua form create + header
          appendNewComment(pageComment + 1)
        }
      }
    }, 200)
  }

  const appendNewComment = (newPage, timeout = 1000) => {
    if (listComment?.length < data?.comment?.length) {
      let newArray = chunkArray(data.comment, scrollPerpage)
      if (newArray[newPage]) {
        setLoadingLoadmore(true)
        setPageComment(pageComment + 1)
        setTimeout(() => {
          setListComment(p => [...p, ...newArray[newPage]])
          setLoadingLoadmore(false)
        }, timeout)
      }
    }
  }

  const renderComment = (comments) => {
    if (comments && comments.length > 0) {
      return comments.map((item, key) => (
        <PlaygroundCommentItem
          item={item}
          key={key}
          type={type}
          userInfo={userInfo}
          isEditComment={editComment === key}
          setEditComment={(e) => setEditComment(e ? key : -1)}
          dateTimeFormat={dateTimeFormat}
          handleFocusIpt={handleFocusIpt}
        />
      ));
    }

    if (!loadingCmt) {
      if (type === 'PLAYGROUND' || type === 'QNA') {
        return (
          <div className="comment-empty">
            <div className="img">
              <IconEmptyCmt />
            </div>
            <p>아직 작성된 댓글이 없어요!</p>
          </div>
        );
      } else {
        return <div className="comment-list-empty"></div>;
      }
    } else {
      return (
        <>
          <LoadingCommunity type={'comment'} />
          <LoadingCommunity type={'comment'} />
        </>
      );
    }
  };

  return (
    <div ref={ref}>
      {loadingCreate ? <LoadingCommunity type={'comment'} /> : null}
      {infinityScroll ? (
        <InfiniteScroll
          dataLength={(listComment && listComment?.length) || 0}
          next={() => appendNewComment(pageComment + 1)}
          hasMore={listComment && listComment?.length < data?.comment?.length && !loadingLoadmoreComment}
        >
          {renderComment(listComment || [])}
        </InfiniteScroll>
      ) : renderComment(listComment || [])}
      {loadingLoadmoreComment && <>
        <LoadingCommunity type={'comment'} />
        <LoadingCommunity type={'comment'} />
      </>}
      {showFormCreate ? (
        <div className="comment-form">
          <PlaygroundCommentForm
            defaultValues={{
              inquiry_id: data?.id,
            }}
            type={'editCmt'}
            submitText="등록"
            scrollTop={scrollTop}
            handleFocusIpt={handleFocusIpt}
          />
        </div>
      ) : null}
    </div>
  );
}

export default PlaygroundCommentList;
