import React, { Fragment, useState, useRef } from 'react';
import images from '../../themes/images';
import UserInfo from './UserInfo';
import useOnClickOutside from 'customHooks/useClickOutSide';

function DropdownAction({ isShow, data, editAction,deleteAction }) {
  const wrapperRef = useRef(null);
  const [isShowDropdown, setShowDropdown] = useState(false);

  useOnClickOutside(wrapperRef, () => setShowDropdown(false));

  const _setShowDropdown = () => {
    setShowDropdown((p) => !p);
  };

  const edit = (data) => {
    console.log(data, 'data');
    setShowDropdown(false)
    editAction(data);
  }

  const del = (data) => {
    setShowDropdown(false)
    deleteAction(data);
  }

  return (
    <>
      {
        isShow && <div ref={wrapperRef}>
        <div
          className="item-action-dropdown"
          onClick={_setShowDropdown}
        >
          <span />
          <span />
          <span />
        </div>
        <div className={`dropdown-action${!isShowDropdown ? ' d-none' : ''}`}>
          <ul className="dropdown-action__main">
            <li className="dropdown-action__main__item" onClick={() => edit(data)}>수정</li>
            <li className="dropdown-action__main__item" onClick={() => del(data)}>삭제</li>
          </ul>
        </div>
      </div>
      }
    </>
    
  );
}

export default DropdownAction;
