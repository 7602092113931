import React, { Fragment, useEffect } from 'react';
import images from '../../../../themes/images';
import { useListRecentSearch, useDelete } from './../../hooks/recentSearch';
import useChangeUrlParams from 'customHooks/useChangeUrlParams'

function RecentSearch({ data, loading, param, setParam, title = '', noData = null }) {
  const [loadingDelete, onDelete] = useDelete();
  const [histories, loadingHistory] = useListRecentSearch(param.keyword, loadingDelete);
  const { changeUrlParams } = useChangeUrlParams();

  useEffect(() => {
    window.showLoadingNative(loadingHistory);
  }, [loadingHistory]);

  const _onChange = (keyword) => {
    changeUrlParams(param, [{ type: 'keyword', value: `${keyword}` }]);
  };

  const _delete = (item = null) => {
    let ids = [];
    if (item) {
      ids = [item.id];
    } else {
      ids = histories.map(e => e.id);
    }

    onDelete(ids)
  }

  return (
      <Fragment>
        <div className="box-data-default__search-nearby">
          <div className='box-title'>
            <h3 className='text-title'>최근 검색어</h3>
            {histories.length ? <button type="button" className='btn-delete' onClick={() => _delete()}>
              모두 지우기
            </button> : null}
          </div>
          <div className="box-list-item">
            <div className="list-item">
              {!loadingHistory && histories.map((item) => (
                <div
                  key={item.id}
                  className={`list-item__item`}
                >
                  <span onClick={() => _onChange(item.keyword)}>
                    {item.keyword}
                  </span>
                  <img
                    onClick={() => _delete(item)}
                    className='item-delete'
                    src={images.icon_playground_close}
                    alt=''
                  />
                </div>
              ))}
            </div>
            {!loadingHistory && !histories.length && <div className='box-data-empty'>
              <span>최근 검색어가 없습니다.</span>
            </div>}
          </div>
        </div>
      </Fragment>
  );
}

export default RecentSearch;
