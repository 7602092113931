
import React, { memo, useEffect, Fragment, useState } from 'react';
import MainLayout from 'layout/MainLayout';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import ROUTERS from 'constants/router';
import PlaygroundList from 'components/Playground/List';
import HeaderPlayground from 'components/Playground/HeaderPlayground';
import NoData from 'components/NoData';
import moment from 'moment'

import {
  useListQueryUrlParams,
  // useListQueryParams,
  useList,
  useListResParams,
  useDelete,
} from 'customHooks/playground/index';
import useChangeUrlParams from 'customHooks/useChangeUrlParams'
import useCountSize from 'customHooks/playground/useCountSize';
import ModalPlayground from 'components/Playground/ModalPlayground';
import ProductInquiryFilter from './../components/ListFilter/index';
import images from '../../../themes/images';
import {hideBottomButtonInputFocus} from "../../../utils/Helpers";

const PlaygroundContainer = () => {
  const history = useHistory();

  const { changeUrlParams } = useChangeUrlParams();
  const param = useListQueryUrlParams('QNA', moment().utc().format('YYYY-MM-DD HH:mm:ss'));
  // const [param, setParam] = useListQueryParams('QNA');
  const [list, loading] = useList((+!+param?.reload) ? param : null);
  const resParams = useListResParams();
  const [ isShowList, setIsShowList ] = useState(true) 
  const [dataModal, setDataModal] = useState({
    isShow: false,
    type: '',
    textBtnLeft: '취소',
    textBtnRight: '삭제',
    title: '글을 삭제 하시겠습니까?',
  })
  useCountSize(list.length, loading);

  const handleBack = () => {
    window.backToScreenNative();
  };

  useEffect(() => {
    // window.callGetListPathNative(['/playground?', '/product/inquiry?type=back_main'])
    changeUrlParams(param, [
      { type: 'current_time', value: param.current_time },
      { type: 'reload', value: param.reload },
    ]);
  }, [])

  useEffect(() => {
    if (+!+param.reload) {
      window.scrollTo(0, 0)
      window.localStorage.scrollY = 0
    } else {
      window.scrollTo(0, window.localStorage.scrollY)
    }
  }, [])
  
  useEffect(() => {

    hideBottomButtonInputFocus()
    function scroll (event) {
      let scroll = this.scrollY;
      window.localStorage.scrollY = scroll
    }
    window.addEventListener("scroll", scroll);

    return () => window.removeEventListener("scroll", scroll);
  }, [])

  const _delete = (item) => {
    console.log('delete', item);
    setDataModal({
      isShow: true,
      type: 'delete',
      textBtnLeft: '취소',
      textBtnRight: '삭제',
      title: '글을 삭제 하시겠습니까?',
      data: {inquiry: item?.id || null}
    })
  };

  const goToEdit = (item) => {
    const url = ROUTERS.INQUIRY_PRODUCT_DETAIL.replace(':id', item.id);
    history.push(url);
    
  };

  const goToCreate = () => {
    history.push({
      pathname: ROUTERS.INQUIRY_PRODUCT_REGISTER,
      state: param
    });
    // history.push(ROUTERS.INQUIRY_PRODUCT_REGISTER);
  };

  const _onSuccess = () => {
    console.log('delete success');
  }

  const [loadingDelete, onDelete] = useDelete({ onSuccess: _onSuccess, params: param });

  const handleCloseModal = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const handleCancelDelete = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const handleDelete = () => {
    onDelete(dataModal.data);
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const onTouchrefreshPage = (data) => {
    if (data) {
      console.log(data, param, +!param.reload, 'datadatadatadata');
      changeUrlParams(param, [
        { type: 'page', value: 1 },
        // { type: 'reload', value: '' + (+!+param.reload) },
        { type: 'current_time', value: moment().utc().format('YYYY-MM-DD HH:mm:ss') },
      ])
    }
  }

  const handleSelect = (key) => {
    if (key === 'q&a') {
      changeUrlParams(param, [
        { type: 'page', value: 1 },
        // { type: 'reload', value: '' + (+!+param.reload) },
        { type: 'current_time', value: moment().utc().format('YYYY-MM-DD HH:mm:ss') },
      ])
    }
  }
  const handleFocusIptSearch = (data) =>{
    // const boxCreateQna = document.getElementById('btn-create-qna')
    // if(data){
    //   boxCreateQna.classList.add('d-none')
    // }else {
    //   boxCreateQna.classList.remove('d-none')
    // }
  }



  window.backFromDeviceAndroid = function() {
    handleBack();
  }

  return (
    <MainLayout
      isLoading={loadingDelete}
      isShowHeader={false}
      customClass="playground-main page-product-list"
      isTouchEnd
      refreshPage={onTouchrefreshPage}
    >
      <div className="playground-main__filter" id="box-filter-page">
        <HeaderPlayground
          handleBack={handleBack}
          isShowSearchIcon={false}
          handleSelect={handleSelect}
          activeItem={{
            key: 'q&a',
            text: '상품문의',
          }}
        />
        <ProductInquiryFilter
          param={param}
          // setParam={setParam}
          loading={loading}
          data={list}
          setIsShowList={setIsShowList}
          isShowList={isShowList}
          handleFocusIptSearch={handleFocusIptSearch}
        />
      </div>
      <div className={!isShowList ? 'd-none' : ''}>
        {!list.length && !loading ?
          <div className="wrapper-page-playground" id="box-list-item">
            <NoData text="최근 거래 내역이 없습니다." />
          </div>
        : 
          <InfiniteScroll
            dataLength={(list && list.length) || 0}
            next={() => {
              changeUrlParams(param, [{ type: 'page', value: +param.page + 1 }]);
            }}
            hasMore={list && list.length < resParams.total && !loading}
          >
            <PlaygroundList
              data={list}
              loading={loading}
              param={param}
              itemDisplayFormat="2"
              omitHashtags
              wrapperClassName="list-inquiry-qna"
              deleteAction={_delete}
              editAction={goToEdit}
              showComment
              omitTitle
            />
          </InfiniteScroll>
        }

        <div className="btn-notice bottom-btn" id='btn-create-qna' onClick={goToCreate}>
          <div className="contact">
            <img
              className="img-contact"
              src={images.icon_contact}
              alt=""
            />
            <span className='text-contact'>문의 하기</span>
          </div>
        </div>

      </div>
      <ModalPlayground
        isShow={dataModal.isShow}
        handleShowModal={handleCloseModal}
        textBtnLeft={dataModal.textBtnLeft}
        textBtnRight={dataModal.textBtnRight}
        title={dataModal.title}
        handleBtnLeft={handleCancelDelete}
        handleBtnRight={handleDelete}
        type={''}
      />
    </MainLayout>
  );
};

export default memo(PlaygroundContainer);
