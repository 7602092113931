/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';
import { checkStatusCodeApi } from 'utils/Helpers';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* detailProductEnquiry(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() =>
      API.get(ROUTES.API_GET_DETAIL_PLAYGROUND, action.data)
    );

    console.log(response, 'response');
    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({ type: Types.DETAIL_PRODUCT_ENQUIRY_SUCCESS, data });
    } else {
      const { data } = response;
      checkStatusCodeApi(data?.code || null)

      yield put({
        type: Types.DETAIL_PRODUCT_ENQUIRY_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.DETAIL_PRODUCT_ENQUIRY_FAILED, error });
  }
}

/*
  Starts detailProductEnquiry on each dispatched `DETAIL_PRODUCT_ENQUIRY` action.
*/
function* detailProductEnquirySaga() {
  yield takeLatest(Types.DETAIL_PRODUCT_ENQUIRY, detailProductEnquiry);
}

export default detailProductEnquirySaga;
