import React, { Fragment, useState, useRef, useEffect } from 'react';
import ImagePreview from './ImagePreview';
import ModalPlayground from 'components/Playground/ModalPlayground';
import { ReactComponent as IconPlaygroundImageSelect } from '../../../../assets/icons/playground-image-select.svg';

const FILE_TYPES =
  'image/png,image/jpeg,image/jpg,image/gif';

const checkAcceptFile = (file, accept) => {
  let acceptArr = (accept || '').split(',');
  if (accept && !acceptArr.includes(file.type)) {
    return false;
  }

  return true;
};

function FormFilePreview({ maxFile, images, onChange, remove }) {
  const clickFile = useRef(null);
  const [isMaxFileSize, setShowMaxFileSize] = useState(false);
  const [isFileType, setShowFileType] = useState(false);

  useEffect(() => {
    window.setPermissionCamera = function (data) {
      // alert(86, '');
      window.permissionCamera = data;
      if (data) {
        // document.getElementById('btnUploadFile').classList.remove("d-none");
        // document.getElementById('btnUploadFile').click();
        clickFile.current.click();
      }
    };

    return () => {
      window.setPermissionCamera = undefined;
    };
  });

  const handlePermission = () => {
    if (images.length >= maxFile) {
      return;
    }

    // push code thi disable cai ni
    window.setPermissionCamera(true);

    if (!window?.permissionCamera || window?.permissionCamera === 'false') {
      // alert('callRequestPermissionNative');
      window.callRequestPermissionNative();
    } else {
      // document.getElementById('btnUploadFile').classList.remove("d-none")
    }
  };

  const handleChangeFile = (e) => {
    // console.log('handleChangeFile');
    // checkPermissionCamera(window.permissionCamera)
    if (!window?.permissionCamera) {
      return false;
    }
    if (images.length >= maxFile) {
      return;
    }
    const { files } = e.target;
    if (files[0]) {
      if (!checkAcceptFile(files[0], FILE_TYPES)) {
        setShowFileType(true);
        return;
      }
      if (files[0]?.size > 10 * 1024 * 1024) {
        setShowMaxFileSize(true);
        setTimeout(() => {
          setShowMaxFileSize(false);
        }, 3000);
        return;
      }
      onChange(files[0]);

      // document.getElementById('btnUploadFile').classList.add("d-none");
    }
  };

  return (
    <Fragment>
      <input
        type="file"
        className="custom-file-input d-none"
        id="btnUploadFile"
        value=""
        ref={clickFile}
        onChange={(e) => handleChangeFile(e)}
        accept={`${FILE_TYPES},capture=camera`}
      />
      <div className="d-flex overflow-auto"></div>

      <div
        className={`
        images-count
        ${images.length >= maxFile ? ' disabled' : ''}
      `}
      >
        <div className="title">
          사진 첨부 {images.length}
          <span>/{maxFile}</span>
        </div>
        <div className="d-flex overflow-auto">
          <div className="select-img" onClick={() => handlePermission()}>
            <div className="position-relative d-flex justify-content-center align-items-center" style={{
              width: '72px',
              height: '72px'
            }}>
              <div className="icon">
                <IconPlaygroundImageSelect />
              </div>
            </div>
          </div>
          {images.map((image, index) => (
            <div key={index} className="img-preview">
              <ImagePreview
                file={image}
                remove={() => remove(image, index)}
                width={72}
                height={72}
              />
            </div>
          ))}
        </div>
      </div>
      {isMaxFileSize && (
        <div className="image-notice">
          <div className="content">
            이미지 등록에 실패했습니다.
            <br />
            최대 10MB이하 이미지만 등록 가능합니다.
          </div>
        </div>
      )}
      <ModalPlayground
        isShow={isFileType}
        handleShowModal={() => setShowFileType(false)}
        textBtnRight={'확인'}
        title={'이미지 형식의 파일만 업로드 가능합니다.'}
        handleBtnRight={() => setShowFileType(false)}
      />
    </Fragment>
  );
}

export default FormFilePreview;
