import React from 'react';
import images from '../../themes/images';
import DropdownAction from './DropdownAction';
import UserInfo from './UserInfo';
import Image from './Image';
// import LikeComment from './LikeComment';
import PostInfo from './PostInfo';
import Hashtags from './Hashtags';
import LikeComment from './LikeComment';

import AvatarDefault from '../../assets/icons/avatar_default.svg';
import { ReactComponent as IconDelHastag } from '../../assets/icons/icon_del_hastag.svg';
import { ReactComponent as IconDislike } from '../../assets/icons/icon_dislike_detail.svg';
import { ReactComponent as Iconlike } from '../../assets/icons/icon_like_detail.svg';
import { ReactComponent as IconCmt } from '../../assets/icons/icon_cmt_detail.svg';

// display: '1', '2'
function PlaygroundDetail({ item, omitHashtags = false, omitTitle = false }) {
  return (
    <div className="item-community">
      <div className="wrap-item-community">
        <div className="community-head">
          {/*<div className="community-user">
            <div className="left-box-info">
              <div className="img-user">
                {item?.user_image_path ? (
                  <img src={item?.user_image_path} alt="" />
                ) : (
                  <img src={AvatarDefault} alt="" />
                )}
              </div>
              <div className="community-user-name">
                {item?.nickname}
              </div>
            </div>
            <div className="right-box-time">
              <div className="community-time">
                {item?.created_at}
              </div>
            </div>
          </div>*/}
          <div className="community-user">
            <UserInfo user={item.user} createdAt={item.created_at} inline />
          </div>
        </div>

        <PostInfo
          data={item}
          omitHashtags
          omitLikeShare
          omitTitle={omitTitle}
        />
        <Image images={item.images} className="w-100 m-0" />
        {!omitHashtags && <Hashtags hashtags={item?.hashtags || []} />}
        <LikeComment className="w-100 position-inherit d-flex justify-content-between align-items-center" />
      </div>
    </div>
  );
}

export default PlaygroundDetail;
