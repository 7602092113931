import React, { Fragment, useEffect } from 'react';
import images from '../../../../themes/images';
import { useListStoreFavoriteParam, useListStoreFavorite } from 'customHooks/store';
import { useLoadingList } from 'customHooks/playground';
import useCountSize from 'customHooks/playground/useCountSize';
import ROUTERS from 'constants/router';
import { useHistory } from 'react-router-dom';
import useChangeUrlParams from 'customHooks/useChangeUrlParams'
import SkeletonLoadingComponent from 'components/SkeletonLoading';

function FilterFavorite({ data, loading, param, setParam, setLoadingByFilter }) {
  const history = useHistory();
  const storeParam = useListStoreFavoriteParam();
  const [stores, loadingStore] = useListStoreFavorite((+!+param.reload) ? storeParam : null);
  const { changeUrlParams } = useChangeUrlParams();
  const setLoadingList = useLoadingList();

  useCountSize(null, loadingStore);

  useEffect(() => {
    setLoadingList()
  }, [])

  useEffect(() => {
    setLoadingByFilter(loadingStore)
  }, [loadingStore])

  useEffect(() => {
    if (!loadingStore) {
     if (!param?.store_id && +!+param.reload) {
      if (stores.length >= 2) {
        changeUrlParams(param, [
          { type: 'filterType', value: 2 },
          // { type: 'page', value: 1 },
          { type: 'store_id', value: 'all' },
          { type: 'hash_tag', value: '' },
          { type: 'keyword', value: '' },
          { type: 'hash_tag_id', value: '' },
          { type: 'has_like_cnt', value: '' },
          { type: 'has_hashtag', value: '' },
        ]);
      } else if (stores.length === 1) {
        changeUrlParams(param, [
          { type: 'filterType', value: 2 },
          // { type: 'page', value: 1 },
          { type: 'store_id', value: stores[0].id },
          { type: 'hash_tag', value: '' },
          { type: 'keyword', value: '' },
          { type: 'hash_tag_id', value: '' },
          { type: 'has_like_cnt', value: '' },
          { type: 'has_hashtag', value: '' },
        ]);
      } else {
        changeUrlParams(param, [
          { type: 'filterType', value: 2 },
          // { type: 'page', value: 1 },
          { type: 'store_id', value: '' },
          { type: 'hash_tag', value: '' },
          { type: 'keyword', value: '' },
          { type: 'hash_tag_id', value: '' },
          { type: 'has_like_cnt', value: '' },
          { type: 'has_hashtag', value: '' },
        ]);
      }
     }
    }
  }, [stores])

  const _onChangeStore = (store_id) => {
    if (+store_id === +param.store_id) {
      return
    }
    window.scrollTo(0, 0)
    changeUrlParams(param, [
      { type: 'store_id', value: store_id },
      { type: 'reload', value: '0' },
    ]);
  };

  return (
    !loadingStore ? (
      <Fragment>
        {stores.length ? (
          <Fragment>
            <div className="main-box-title-filter mt-32px mb-2">
              <img className="" src={images.icon_playground_marker} alt="" />
              <label>우리동네에 올라온 게시글 지금 바로 확인하기!</label>
            </div>
            <div className="main-box-filter mt-1">
              <div className='x-scroll'>
                {stores.length > 1 ? (
                  <button
                    className={`item-filter${
                      param.store_id === 'all' ? ' active' : ''
                    }`}
                    type="button"
                    onClick={() => _onChangeStore('all')}
                  >
                    <label>전체</label>
                  </button>
                ) : null}
                {stores.map((store, key) => (
                  <button
                    key={key}
                    className={`item-filter${
                      +param.store_id === +store.id ? ' active' : ''
                    }`}
                    type="button"
                    onClick={() => _onChangeStore(store.id)}
                  >
                    <label>{store.name}</label>
                  </button>
                ))}
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="main-box-add-store mt-32px">
              <p>아직 우리동네가 없어요!</p>
              <label>
                지금 우리동네를 추가하고<br/>
                더 많은 게시글을 확인해보세요!
              </label>
              <button onClick={() => history.push(`${ROUTERS.FIND_STORE_FAVORITE}?type=webview`)}>
                우리 동네 추가
              </button>
            </div>
          </Fragment>
        )}
      </Fragment>
    ) : (
      <div className="main-box-add-store mt-32px">
        <div className="main-box-title-filter">
          <img className="" src={images.icon_playground_marker} alt="" />
          <label className="m-0 p-0">우리동네에 올라온 게시글 지금 바로 확인하기!</label>
        </div>
        <div className="d-flex mt-2">
          <div className="mr-2">
            <SkeletonLoadingComponent
              height={24}
              width={70}
            />
          </div>
          <div className="mr-2">
            <SkeletonLoadingComponent
              height={24}
              width={70}
            />
          </div>
          <div className="mr-2">
            <SkeletonLoadingComponent
              height={24}
              width={70}
            />
          </div>
          <div className="mr-2">
            <SkeletonLoadingComponent
              height={24}
              width={70}
            />
          </div>
        </div>
      </div>
    )
  );
}

export default FilterFavorite;
