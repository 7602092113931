import React, { Fragment, useEffect, useState } from 'react';
import images from "../../../../themes/images";
import { ReactComponent as IconSearch } from './../../../../assets/icons/icon_search.svg';
import { ReactComponent as IconSearchDisabled } from './../../../../assets/icons/icon_search_disabled.svg';
import useChangeUrlParams from 'customHooks/useChangeUrlParams'
import moment from 'moment'

function SearchInput({ param, setParam, loading, data }) {
  const [inputValue, setInputValue] = useState(param.keyword || '')
  const { changeUrlParams } = useChangeUrlParams();

  useEffect(() => {
    setInputValue(param.keyword || '')
  }, [param.keyword])

  const _handleInputChange = e => {
    let value = e.target.value
    if (value && !value.trim()) {
      return
    }
    if (value.length > 50) {
      return
    }
    setInputValue(e.target.value)
  }

  const search = () => {
    if (inputValue) {
      changeUrlParams(param, [
        { type: 'keyword', value: inputValue },
        // { type: 'reload', value: '' + (+!+param.reload) },
        { type: 'current_time', value: moment().utc().format('YYYY-MM-DD HH:mm:ss') },
      ]);
    }
  }

  const clear = () => {
    setInputValue('')
    // setParam([{ type: 'keyword', value: '' }]);
  }

  return (
    <Fragment>
      <div className="search-store flex-search">
        <input
          className="form-control input-search has-search"
          onChange={_handleInputChange}
          placeholder="검색어를 입력해주세요."
          value={inputValue}
          maxLength="50"
        />
        <img
          className={`item-clear ${inputValue ? ' d-block' : ' d-none'}`}
          src={images.icon_playground_clear}
          alt=''
          onClick={clear}
        />
        <div
          className={`icon-search ${inputValue ? '' : ' disabled'}`}
          aria-hidden="true"
          onClick={search}
        >
          {inputValue ? <IconSearch /> : <IconSearchDisabled />}
        </div>
      </div>
    </Fragment>
  );
}

export default SearchInput;
