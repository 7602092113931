/* eslint-disable camelcase */
// import libs
import { create } from 'apisauce';

const API_URI = process.env.REACT_APP_API_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const ROUTES = {
  API_GET_SMS: `/api/ua/${API_VERSION}/public/account/get-sms-code`,
  API_CHECK_NICK_NAME: `/api/ua/${API_VERSION}/public/account/check-username`,
  API_REGISTER_ACCOUNT: `/api/ua/${API_VERSION}/public/auth/register`,
  API_CHECK_CODE: `/api/ua/${API_VERSION}/public/account/verify-code`,
  API_LOGIN: `/api/app/auth/login`,
  API_FIND_PASSWORD: `/api/ua/${API_VERSION}/public/auth/change-password`,
  API_FIND_CODE: `/api/ua/${API_VERSION}/public/account/get-findpw-code`,
  API_LOGOUT: `/api/ua/${API_VERSION}/private/auth/logout`,
  API_MAIN: `/api/ua/${API_VERSION}/private/common/main-data`,
  API_GET_POINT_USER: `/api/app/account-point`,
  API_MAINTENANCE: `/api/ua/${API_VERSION}/public/maintenance`,
  API_PUSH_HISTORY: `/api/ua/${API_VERSION}/private/common/push-history`,
  API_INQUIRY: `/api/ua/${API_VERSION}/private/inquiry/create`,
  UPDATE_USER_INFO: `/api/ua/${API_VERSION}/private/account/update-profile`,
  API_GET_LIST_STORE: `/api/ua/${API_VERSION}/private/hp/get-store-list`,
  API_REGISTER_PRODUCT: `/api/ua/${API_VERSION}/private/product/recent/create`,
  API_GET_DATA_PUSH: `/api/ua/${API_VERSION}/private/common/push-history`,
  API_GET_LIST_PAYMENT_HISTORY: `/api/ua/${API_VERSION}/private/account/payment-history`,
  API_LEVEL: `/api/ua/${API_VERSION}/private/account/level`,
  API_GET_LIST_PRODUCT_ENQUIRY: `/api/ua/${API_VERSION}/private/inquiry/list`,
  API_LIKE: `/api/ua/${API_VERSION}/private/inquiry/like`,
  API_DELETE_PRODUCT_ENQUIRY: `/api/ua/${API_VERSION}/private/inquiry/delete`,
  API_DETAIL_ENQUIRY: `/api/ua/${API_VERSION}/private/inquiry/detail`,
  API_UPDATE_ENQUIRY: `/api/ua/${API_VERSION}/private/inquiry/update`,
  API_MY_INFO: `/api/ua/${API_VERSION}/private/account/me`,
  API_CREATE_COMMENT: `/api/ua/${API_VERSION}/private/inquiry/comment/create`,
  API_UPDATE_COMMENT: `/api/ua/${API_VERSION}/private/inquiry/comment/update`,
  API_DELETE_COMMENT: `/api/ua/${API_VERSION}/private/inquiry/comment/delete`,
  API_PUSH: `/api/ua/${API_VERSION}/private/account/set-push-mode`,
  API_DELETE_PRODUCT: `/api/ua/${API_VERSION}/private/product/recent/delete`,
  API_FIND_STORE: `/api/ua/${API_VERSION}/private/store/search`,
  API_GET_USER_BARCODE: `/api/ua/${API_VERSION}/private/account/get-barcode`,
  API_GROUP_BUYING_HISTORY: `/api/ua/${API_VERSION}/private/get-group-buying-histories`,
  API_GROUP_BUYING: `/api/ua/${API_VERSION}/private/get-event-info`,
  API_ORDER_GROUP_BUYING: `/api/ua/${API_VERSION}/private/order-group-buying`,
  API_GET_EVENT_STORE: `/api/ua/${API_VERSION}/private/get-event-stores`,
  API_GROUP_BUYING_HISTORY_DETAIL: `/api/ua/${API_VERSION}/private/get-order-event-detail/:id`,

  API_CREATE_QA: `/api/ua/${API_VERSION}/private/event-product-inquiry/create`,
  API_EDIT_QA: `/api/ua/${API_VERSION}/private/event-product-inquiry/update`,
  API_DETAIL_QA: `/api/ua/${API_VERSION}/private/event-product-inquiry/detail/:id`,
  API_DELETE_QA: `/api/ua/${API_VERSION}/private/event-product-inquiry/delete`,
  API_GET_LIST_QA: `/api/ua/${API_VERSION}/private/event-product-inquiry/list`,
  API_CREATE_QA_ADMIN: `/api/ua/${API_VERSION}/private/event-product-inquiry/comment/create`,
  API_DELETE_QA_ADMIN: `/api/ua/${API_VERSION}/private/event-product-inquiry/comment/delete`,
  API_UPDATE_QA_ADMIN: `/api/ua/${API_VERSION}/private/event-product-inquiry/comment/update`,

  API_PRODUCT_DETAIL: `/api/ua/${API_VERSION}/private/product/recent/:id`,
  API_EVENT_DETAIL: `/api/ua/${API_VERSION}/private/get-event-detail/:id`,
  API_REMOVE_STORE_LIKE: `/api/ua/${API_VERSION}/private/favorite-store`,
  API_DETAIL_FAQ: `/api/app/faqs`,
  API_DETAIL_NOTIFICATION: `/api/app/notifications`,
  API_DETAIL_TERMS: `/api/app/terms`,
  API_EVENT: `/api/app/events`,
  API_GET_LIST_CARD: `/api/app/card`,
  API_GET_EVENT_STATUS: `/api/app/v1.0/status-events`,
  API_TOTAL_REMAIN_LOTTERY: `/api/app/v1.0/lottery/total-remain`,
  API_STARTCH_LOTTERY: `/api/app/v1.0/lottery/scratch`,

  API_GET_CONSUTANT: `/api/app/v1.0/consults`,

  API_GET_LIST_PLAYGROUND: `/api/app/v1.1/community/inquiries`,
  API_GET_DETAIL_PLAYGROUND: `/api/app/v1.1/community/inquiries/:id`,
  API_CREATE_PLAYGROUND: `/api/app/v1.1/community/inquiries`,
  API_UPDATE_PLAYGROUND: `/api/app/v1.1/community/inquiries/:id`,
  API_GET_LIST_PLAYGROUND_COMMENT: `/api/app/v1.1/community/inquiries/:inquiry/comments`,
  API_CREATE_COMMENT_PLAYGROUND: `/api/app/v1.1/community/inquiries`,
  API_CREATE_QNA: `/api/app/v1.1/community/inquiries`,
  API_UPDATE_QNA: `/api/app/v1.1/community/inquiries`,
  API_CREATE_PLAYGROUND_COMMENT: `/api/app/v1.1/community/inquiries/:inquiry/comments`,
  API_UPDATE_PLAYGROUND_COMMENT: `/api/app/v1.1/community/inquiries/:inquiry/comments`,
  API_DELETE_PLAYGROUND_COMMENT: `/api/app/v1.1/community/inquiries/:inquiry/comments`,
  API_DELETE_PLAYGROUND: `/api/app/v1.1/community/inquiries/:inquiry`,
  API_GET_LIST_HASHTAG: `/api/app/v1.1/community/hashtags`,
  API_GET_LIST_STORES: `/api/app/v1.1/stores`,
  API_GET_LIST_SEARCH_HISTORY: `/api/app/v1.1/community/search-histories`,
  API_DELETE_SEARCH_HISTORY: `/api/app/v1.1/community/search-histories`,
  API_PLAYGROUND_REACTION: `/api/app/v1.1/community/inquiries/:inquiry/reaction`,
};

console.log(65, window.tokenApp);

// document.getElementById('token-new').innerHTML = window.tokenApp
// document.getElementById('firebaseToken').innerHTML = window.tokenApp + `\n--API--\n`+document.getElementById('firebaseToken').innerHTML
// document.getElementById('firebaseToken').innerHTML = document.getElementById('firebaseToken').innerHTML + `\n---API----\n` + window.tokenApp
export const API = create({
  baseURL: API_URI,
  headers: {
    Authorization: `Bearer ${window.tokenApp}`,
  },
});

// export const API = {
//   get: (api, data) => {
//     console.log(76, window.tokenApp)
//     _API.setHeader('Authorization', `Bearer ${window.tokenApp}`)
//     return _API.get(api, data)
//   }
// }
