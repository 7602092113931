import React, { Fragment, useEffect } from 'react';
import images from '../../../../themes/images';
import useChangeUrlParams from 'customHooks/useChangeUrlParams'
import moment from 'moment'

function FilterDefault({ param, setParam, loading, data }) {
  const { changeUrlParams } = useChangeUrlParams();
  
  useEffect(() => {
    if(+!+param.reload) {
      changeUrlParams(param, [
        { type: 'filterType', value: 1 },
        // { type: 'page', value: 1 },
        { type: 'store_id', value: '' },
        { type: 'hash_tag', value: '' },
        { type: 'keyword', value: '' },
        { type: 'hash_tag_id', value: '' },
        { type: 'has_like_cnt', value: '' },
        { type: 'has_hashtag', value: '' },
        // { type: 'reload', value: '' + (+!+param.reload) },
        // { type: 'current_time', value: moment().utc().format('YYYY-MM-DD HH:mm:ss') },
      ]);
    }
  }, [])
  return null;
  // return <div className="main-box-communicate mt-32px mb-2">
  //   <p>
  //     홍길동님!
  //     <img className="" src={images.icon_playground_smile} alt="" />
  //   </p>
  //   <label>친구들과 함께 소통해 보세요.</label>
  // </div>
}

export default FilterDefault;
