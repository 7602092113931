import React, { Fragment } from 'react';

function Hashtags({ hashtags, highlightHashtagID, highlight }) {
  const hightLightitem = (value, text) => {
    if (!value) {
      return text;
    }
    const regex = new RegExp(`(${value})`, "gi");
    const nameHightlight = text.replace(regex, "<mask class='highlight'>$1</mask>")

    return nameHightlight
  }

  return (
    <div className="box-hasttag">
      {highlightHashtagID ? (
        <Fragment>
          {hashtags?.filter(hashtag => +hashtag.id === +highlightHashtagID).map((hashtag, key) => (
            <div
              className={` item-hashtag d-inline ${hashtag.id == highlightHashtagID ? ' active' : ''}`}
              key={key}
              dangerouslySetInnerHTML={{ __html: hightLightitem(highlight, `#${hashtag?.hashtag}`) }}
            />
          ))}
          {hashtags?.filter(hashtag => +hashtag.id !== +highlightHashtagID).map((hashtag, key) => (
            <div
              className={` item-hashtag d-inline ${hashtag.id == highlightHashtagID ? ' active' : ''}`}
              key={key}
              dangerouslySetInnerHTML={{ __html: hightLightitem(highlight, `#${hashtag?.hashtag}`) }}
            />
          ))}
        </Fragment>
      ) : (
        hashtags?.map((hashtag, key) => (
          <div
            className={` item-hashtag d-inline ${hashtag.id == highlightHashtagID ? ' active' : ''}`}
            key={key}
            dangerouslySetInnerHTML={{ __html: hightLightitem(highlight, `#${hashtag?.hashtag}`) }}
          />
        ))
      )}
      
    </div>
  );
}

export default Hashtags;
