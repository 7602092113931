import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as CreatorSearch } from 'screens/findStore/redux';
import CreateCommunityQAComponent from '../components/createCommunityQA';
import { Creators } from '../redux';

const mapStateToProps = (state) => {
  return {
    storeListLikeOfUser: state.searchReducer.listStoreLikeOfUser,
    listAllStore: state.searchReducer.listAllStore,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...Creators,
      getListAllStore: CreatorSearch.getListAllStore,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateCommunityQAComponent);
