import React, { memo, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import QueryString from 'qs';

import { useDetail } from 'customHooks/playground/index';
import ROUTERS from 'constants/router';

import MainLayout from 'layout/MainLayout';
import ModalPlayground from 'components/Playground/ModalPlayground';
import PlaygroundForm from './../components/PlaygroundForm/index';

const PlaygroundEditContainer = () => {
  const params = useParams();
  const history = useHistory();
  const [itemDetail, loading, dataErrors] = useDetail(params, 'PLAYGROUND');

  const [dataModal, setDataModal] = useState({
    isShow: false,
    type: '',
    textBtnLeft: '취소',
    textBtnRight: '삭제',
    title: '글을 삭제 하시겠습니까?',
  })

  useEffect(() => {
    // nếu check trắng page thì nói bên api trả code về => 404 nghe fen
    if (dataErrors && dataErrors.code === 404) {
      setDataModal({
        isShow: true,
        type: '404',
        textBtnLeft: '',
        textBtnRight: '확인',
        title: '존재하지 않는 게시물입니다.',
      })
    }
  }, [dataErrors])

  useEffect(() => {
    window.showLoadingNative(loading);
  }, [loading]);

  const handleConfirm = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
    history.push(ROUTERS.PLAYGROUND)
  }

  const handleCloseModal = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  return (
    <MainLayout isLoading={false} isShowHeader={false} customClass='main-playground-form'>
      <PlaygroundForm data={itemDetail} loading={loading} />

      <ModalPlayground
        isShow={dataModal.isShow}
        handleShowModal={handleCloseModal}
        textBtnLeft={dataModal.textBtnLeft}
        textBtnRight={dataModal.textBtnRight}
        title={dataModal.title}
        handleBtnRight={handleConfirm}
        type={''}
      />
    </MainLayout>
  );
};

export default memo(PlaygroundEditContainer);
