// import libs
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// Define action creators
export const { Types, Creators } = createActions({
  resetState: null,
  signIn: ['loginInfo'],
  signInSuccess: null,
  signInFailed: null,

  getSMSCode: ['phone'],
  getSMSCodeSuccess: null,
  getSMSCodeFailed: null,

  verifySMSCode: ['data'],
  verifySMSCodeSuccess: null,
  verifySMSCodeFailed: null,

  verifyNickNameUser: ['nickName'],
  verifyNickNameUserSuccess: null,
  verifyNickNameUserFailed: null,

  signUpAccount: ['userInfo'],
  signUpAccountSuccess: null,
  signUpAccountFailed: null,

  logOut: null,
  logOutSuccess: null,
  logOutFailed: null,

  findPassword: ['info'],
  findPasswordSuccess: null,
  findPasswordFailed: null,

  findCodePhone: ['phone'],
  findCodePhoneSuccess: null,
  findCodePhoneFailed: null,

  updateProfileUserInfo: ['data'],

  setInfoSignUpForm: ['obj'],
  setInfoFromApp: null
});

const dataUser = JSON.parse(localStorage.getItem("DATA_INFO"));
console.log('=====> dataUser reducer', dataUser);
// const dataUser = JSON.parse(localStorage.getItem("TOKEN"));
// document.getElementById('token-new').innerHTML = dataUser.token
// Initial state
export const INITIAL_STATE = Immutable({
  userInfo: dataUser?.profile || {},
  isProcessing: false,
  token: dataUser?.token || '',
  isGetSmsCode: false,
  dataSMSCode: {},
  statusCode: null,
  isCheckCode: null,
  formSignUp: {},
});

const resetState = (state) => {
  return state.merge({
    // ...state,
    isProcessing: false,
    type: '',
    dataSMSCode: {},
    statusCode: null,
    isCheckCode: null,
    isGetSmsCode: false,
  });
};

const signIn = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
    errors: '',
    messages: '',
    errorServer: {},
  });
};

const signInSuccess = (state, action) => {
  const { data } = action;
  // localStorage.setItem('_APP_TOKEN', data?.token || '');
  return state.merge({
    isProcessing: false,
    type: action.type,
    statusCode: data && data?.token ? 200 : 400,
    userInfo: data?.user,
    token: data?.token || '',
  });
  
};

const signInFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
    statusCode: 400,
    userInfo: {},
  });
};

const getSMSCode = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const getSMSCodeSuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    type: action.type,
    dataSMSCode: data,
  });
};

const getSMSCodeFailed = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const verifySMSCode = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const verifySMSCodeSuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    type: action.type,
    isCheckCode: data?.status,
  });
};

const verifySMSCodeFailed = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const findCodePhone = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const findCodePhoneSuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    type: action.type,
    dataSMSCode: data,
  });
};

const findCodePhoneFailed = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

// Action check nick name of user

const verifyNickNameUser = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const verifyNickNameUserSuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    type: action.type,
    statusCode: data?.code,
  });
};

const verifyNickNameUserFailed = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const signUpAccount = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
  });
};

const signUpAccountSuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    isProcessing: false,
    type: action.type,
    statusCode: data.code,
  });
};

const signUpAccountFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const logOut = (state, action) => {
  return state.merge({
    type: action.type,
    isLogout: true,
  });
};

const logOutSuccess = (state) => {
  return state.merge({
    ...INITIAL_STATE,
    isLogout: false,
  });
};

const logOutFailed = (state) => {
  return state.merge({
    ...INITIAL_STATE,
    type: 'LOG_OUT_FAILED',
    isLogout: false,
  });
};

const findPassword = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
  });
};
const findPasswordSuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    isProcessing: true,
    type: action.type,
    statusCode: data?.code,
  });
};

const findPasswordFailed = (state, action) => {
  return state.merge({
    isGetSmsCode: false,
    type: action.type,
  });
};

const updateProfileUserInfo = (state, action) => {
  const { data } = action;
  const userInfo = state?.userInfo;
  return state.merge({
    userInfo: {
      ...userInfo,
      name: data?.name || userInfo?.name,
      phone_number: data?.phone_number || userInfo?.phone_number,
      profile_photo_url: data?.profile_photo_url
        ? data?.profile_photo_url
        : userInfo.profile_photo_url,
    },
  });
};

const setInfoSignUpForm = (state, action) => {
  return state.merge({
    type: action.type,
    formSignUp: action.obj,
  });
};

const setInfoFromApp = (state, action) => {
  const {data} = action;
  // console.log(data, 'datadatadatadatadatadata');

  // document.getElementById('token').innerHTML = JSON.stringify(data)

  return state.merge({
    ...state,
    isProcessing: false,
    userInfo: data?.profile || {},
    statusCode: 200,
    token: data?.token,
  });
}

// Assign handler to types.
const HANDLERS = {
  [Types.RESET_STATE]: resetState,
  [Types.UPDATE_PROFILE_USER_INFO]: updateProfileUserInfo,

  [Types.SIGN_IN]: signIn,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILED]: signInFailed,

  [Types.GET_SMS_CODE]: getSMSCode,
  [Types.GET_SMS_CODE_SUCCESS]: getSMSCodeSuccess,
  [Types.GET_SMS_CODE_FAILED]: getSMSCodeFailed,

  [Types.VERIFY_SMS_CODE]: verifySMSCode,
  [Types.VERIFY_SMS_CODE_SUCCESS]: verifySMSCodeSuccess,
  [Types.VERIFY_SMS_CODE_FAILED]: verifySMSCodeFailed,

  [Types.VERIFY_NICK_NAME_USER]: verifyNickNameUser,
  [Types.VERIFY_NICK_NAME_USER_SUCCESS]: verifyNickNameUserSuccess,
  [Types.VERIFY_NICK_NAME_USER_FAILED]: verifyNickNameUserFailed,

  [Types.SIGN_UP_ACCOUNT]: signUpAccount,
  [Types.SIGN_UP_ACCOUNT_SUCCESS]: signUpAccountSuccess,
  [Types.SIGN_UP_ACCOUNT_FAILED]: signUpAccountFailed,

  [Types.LOG_OUT]: logOut,
  [Types.LOG_OUT_SUCCESS]: logOutSuccess,
  [Types.LOG_OUT_FAILED]: logOutFailed,

  [Types.FIND_PASSWORD]: findPassword,
  [Types.FIND_PASSWORD_SUCCESS]: findPasswordSuccess,
  [Types.FIND_PASSWORD_FAILED]: findPasswordFailed,

  [Types.FIND_CODE_PHONE]: findCodePhone,
  [Types.FIND_CODE_PHONE_SUCCESS]: findCodePhoneSuccess,
  [Types.FIND_CODE_PHONE_FAILED]: findCodePhoneFailed,

  [Types.SET_INFO_SIGN_UP_FORM]: setInfoSignUpForm,
  [Types.SET_INFO_FROM_APP]: setInfoFromApp,
};

// Create reducers by pass state and handlers
export const accountReducer = createReducer(INITIAL_STATE, HANDLERS);
