// @flow

import React, { memo, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';
import ModalPopup from 'components/Modal';
import images from 'themes/images';
import Button from 'components/Button';
import InputAutoCompleteSearch from 'components/AutoComplete';
import { useUpdate, useDetail } from 'customHooks/playground';
import { useListStore, useListStoreFavoriteParam, useListStoreFavorite } from 'customHooks/store';
import { checkPermissionCamera } from 'utils/Helpers';
import ModalPlayground from 'components/Playground/ModalPlayground';
import ROUTERS from 'constants/router';

type Props = {
  history: {
    push: Function,
    go: Function,
  },
  // storeListLikeOfUser: Array,
  // listAllStore: Array,
  // getListAllStore: Function
};
const FILE_TYPES =
  'image/png,image/jpeg,image/jpg,image/gif';

const EditCommunityQAComponent = ({
  // storeListLikeOfUser,
  // listAllStore,
  // getListAllStore,
}: Props) => {
  const history = useHistory();
  const params = useParams();
  const [dataEnquiryDetail, loadingDetail, dataErrors] = useDetail(params, 'QNA');
  const param = useListStoreFavoriteParam();
  const [storeListLikeOfUser, ] = useListStoreFavorite(param);
  const [showModal, setShowModal] = useState({
    isShow: false,
    content: '',
  });
  const [isShowData, setIsShowData] = useState(true);
  const [dataInquiry, setDataInquiry] = useState({
    id: 0,
    content: '',
    productImg: '',
    imageView: '',
    store_id: null,
    image_remove: null,
    data_store: {}
  });
  const [dataModal, setDataModal] = useState({
    isShow: false,
    type: '',
    textBtnLeft: '취소',
    textBtnRight: '삭제',
    title: '글을 삭제 하시겠습니까?',
  })

  const [listAllStore, loadingStore] = useListStore();

  useEffect(() => {
    setIsShowData(true)
    setDataInquiry({
      id: 0,
      content: '',
      productImg: '',
      imageView: '',
      store_id: null,
      image_remove: null,
      data_store: {}
    })
  }, [])

  useEffect(() => {
    // nếu check trắng page thì nói bên api trả code về => 404 nghe fen
    if (dataErrors && dataErrors.code === 404) {
      setDataModal({
        isShow: true,
        type: '404',
        textBtnLeft: '',
        textBtnRight: '확인',
        title: '존재하지 않는 게시물입니다.',
      })
    }
  }, [dataErrors])

  useEffect(() => {
    if (+dataEnquiryDetail?.id === +params.id) {
      setDataInquiry({
        ...dataEnquiryDetail,
        data_store: dataEnquiryDetail?.stores && dataEnquiryDetail?.stores.length > 0 ? dataEnquiryDetail.stores[0] : {},
        imageView: dataEnquiryDetail?.attachments && dataEnquiryDetail?.attachments.length > 0 ?  dataEnquiryDetail?.attachments[0].path_small : null,
      });
    }
  }, [dataEnquiryDetail]);

  const checkAcceptFile = (file, accept) => {
    let acceptArr = (accept || '').split(',');
    if (accept && !acceptArr.includes(file.type)) {
      return false;
    }
  
    return true;
  };
  
  const handleChangeFile = (e) => {
    checkPermissionCamera(window.permissionCamera)
    if (!window?.permissionCamera) {
      return false;
    }
    const { files } = e.target;
    if (files[0]) {
      if (!checkAcceptFile(files[0], FILE_TYPES)) {
        setShowModal({
          ...showModal,
          isShow: true,
          content:
            '이미지 형식의 파일만 업로드 가능합니다.',
        });
        return;
      }

      if (files[0]?.size > 20971520) {
        setShowModal({
          ...showModal,
          isShow: true,
          content:
            '파일 크기가 너무 큽니다. \n 20MB 미만의 크기를 선택하십시오',
        });
      } else {
        setDataInquiry({
          ...dataInquiry,
          productImg: files[0] || '',
          imageView: (window.URL || window.webkitURL).createObjectURL(
            e.target.files[0]
          ),
        });
      }
    }
  };

  const handlePermission = () => {
    if (!window?.permissionCamera || window?.permissionCamera === 'false') {
      // alert('callRequestPermissionNative');
      window.callRequestPermissionNative();
    }else{
      document.getElementById('btnUploadFile').classList.remove("d-none")
    }

  }

  const handleChange = (value) => {
    if (value === ' ') return;
    setDataInquiry({
      ...dataInquiry,
      content: value,
    });
  };

  const handleDelete = () => {
    setDataInquiry({
      ...dataInquiry,
      imageView: '',
      productImg: '',
      image_remove: dataInquiry.attachments[0]?.path || ''
    });
  };

  const { content, imageView, productImg, id } = dataInquiry;
  const [loadingUpdate, onUpdate] = useUpdate({ onSuccess: () => {
    setDataInquiry({
      content: '',
      productImg: '',
      imageView: '',
      image_remove: ''
    });
    history.go(-1);
  }, onFailed: () => {
    console.log('onFailed');
    setShowModal({
      ...showModal,
      isShow: true,
      content: '제품 수정에 실패했습니다.',
    });
  } });

  const handleUpdateQA = () => {
    const values = {
      type: 'QNA',
      id: params?.id || id,
      title: content,
      content,
      image: [productImg || ''],
      image_remove: [dataInquiry?.image_remove || null],
      store_id: [dataInquiry?.data_store?.id],
    }
    onUpdate(values)
  };

  const handleSearch = (value) => {
    setDataInquiry({
      ...dataInquiry,
      data_store: value,
    });
    setIsShowData(true)
  }

  const handleSearchText = (item) => {
    setDataInquiry({
      ...dataInquiry,
      data_store: item,
    });
    setIsShowData(true)
  }

  const onChangeInputSearch = () => {
    setIsShowData(false)
  }

  const handleClickSelectDropdown = () => {
    
  }

  const handleForcusIpt = (isShow) => {
    setIsShowData(false)
  }

  const showDataProduct = () => {
    
  }
  
  const handleSelectStore = (item) => {
    setDataInquiry({
      ...dataInquiry,
      store_id: item.id,
      data_store: {
        ...item,
        id: item.id
      }
    });
  }

  const handleConfirm = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
    history.push(ROUTERS.INQUIRY_PRODUCT)
  }

  const renderListStore = lists => {
    if (lists && lists.length > 0) {
      return lists.map((item) => (
        <li
          key={item.store_id}
          className={`item ${dataInquiry?.data_store?.id && dataInquiry?.data_store?.id === item.id ? 'selected' : ''}`}
          aria-hidden
          onClick={() => handleSelectStore(item)}
        >
          {item.name}
        </li>
      ));
    }
  };

  return (
    <MainLayout
      isMarginBottom
      isShowHeader
      titleHeader="상품 문의 수정"
      isCenter
      isShowIcon
      isLink
      isLoading={loadingUpdate || loadingDetail || loadingStore}
      customClass="mb-0 min-height"
    >
      <div className="inquiry-product-page mh-90vh mb-60">
        <div className="form-inquiry">
          <div className="form-group">
            <div className='list-stores-like'>
              <h2>즐겨 찾는 매장</h2>
              <ul className='lists'>
                {renderListStore(storeListLikeOfUser || [])}
              </ul>
            </div>

            <InputAutoCompleteSearch
              data={listAllStore || []}
              handleSearch={handleSearch}
              handleSearchText={handleSearchText}
              onChangeInputSearch={onChangeInputSearch}
              showDataProduct={showDataProduct}
              placeholder="가맹점명을 입력해 주세요"
              // hasSelect
              showBoxResult
              handleClickSelectDropdown={handleClickSelectDropdown}
              dataSelected={dataInquiry.data_store}
              classDiv="flex-search"
              classResult="find-store-like-search"
              // showIconSearch
              showIconPositionSearch
              // showDataFocus
              handleForcusIpt={handleForcusIpt}
              
            />

          </div>
          {
            isShowData && <>
              <textarea
              placeholder="문의 하실 내용을 입력해주세요"
              name="contents"
              rows="3"
              maxLength="2000"
              className="form-control content-textarea"
              value={content}
              onChange={(e) => handleChange(e.target.value)}
            />
            <div className="group-btn-file">
              {imageView ? (
                <div className="img-view">
                  <div
                    className="icon-delete"
                    onClick={() => handleDelete()}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    <img src={images.iconClose} alt="" />
                  </div>
                  <img src={imageView || ''} alt="" className="h-100" />
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    className="custom-file-input d-none" 
                    id='btnUploadFile'
                    value=""
                    onChange={(e) => handleChangeFile(e)}
                    accept="image/jpg, image/jpeg, image/png, capture=camera"
                  />
                  <div className="upload-img" onClick={() => handlePermission()}>
                    <div className="text-upload">
                      이미지 <br />
                      추가
                    </div>
                  </div>
                </>
              )}
            </div>
            </>
          }
          
        </div>
        {
          isShowData && <Button
            customClass="absolute mt-3 btn-register-inquiry"
            isDisabled={!dataInquiry?.content || !dataInquiry?.data_store?.id}
            onClick={() => handleUpdateQA()}
          >
            수정 완료
          </Button>
        }
      </div>

      {/* <ModalPopup
        isOpen={showModal?.isShow}
        isShowFooter
        isShowHeader
        handleClose={() =>
          setShowModal({
            ...showModal,
            isShow: false,
            content: '',
          })
        }
        textBtnRight="확인"
      >
        <div className="content">{showModal?.content}</div>
      </ModalPopup> */}
      
      <ModalPlayground
        isShow={showModal?.isShow}
        // handleShowModal={handleCloseModal}
        // textBtnLeft={'확인'}
        textBtnRight={'확인'}
        title={showModal?.content}
        handleBtnLeft={() => {
          setShowModal({
            ...showModal,
            isShow: false,
            content: '',
          })
        }}
        type={''}
      />

      <ModalPlayground
        isShow={dataModal.isShow}
        textBtnLeft={dataModal.textBtnLeft}
        textBtnRight={dataModal.textBtnRight}
        title={dataModal.title}
        handleBtnRight={handleConfirm}
        type={''}
      />
    </MainLayout>
  );
};

export default memo<Props>(EditCommunityQAComponent);
