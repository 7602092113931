// @flow

import React, { useState, useEffect } from 'react';
import MainLayout from 'layout/MainLayout';
import images from 'themes/images';
import InputAutoCompleteSearch from 'components/AutoComplete';
import { ModalPopup } from 'components/Modal';
import Button from 'components/Button';
import NoData from 'components/NoData';
import { useLocation } from 'react-router-dom';

type Props = {
  listStoreLike: any,
  isProcessing: boolean,
  getListFindStore: Function,
  listLocationStore: any,
  getListAllStore: Function,
  listAllStore: Array,
  removeStoreLikeById: Function,
  isProcessingRemove: Boolean,
};

const FindStoreLikeComponent = (props: Props) => {
  const {
    listStoreLike,
    isProcessing,
    getListFindStore,
    getListAllStore,
    listLocationStore,
    listAllStore,
    removeStoreLikeById,
    isProcessingRemove
  } = props;
  const [keySearch, setKeySearch] = useState('');
  const [storeSelected, setStoreSelected] = useState(null);
  const [resetSearch, setResetSearch] = useState(false);
  const [isShowData, setIsShowData] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [dataStore, setDataStore] = useState({
    storeName: '',
    address: '',
  });
  const [isLoadingNative, setIsLoadingNative] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  console.log(searchParams, 'searchParams');
  useEffect(() => {
    getListAllStore();
    setIsDisable(true)
    // getListFindStore('');
    // go to store when click page
    // setKeySearch('인천용현SK스카이뷰점');
  }, []);

  useEffect(() => {
    if (isProcessing || isProcessingRemove) {
      setIsLoadingNative(true);
    }else if(!isProcessing && !isProcessingRemove){
      setIsLoadingNative(false);
    }else{
      setIsLoadingNative(false);
    }
  }, [isProcessing, isProcessingRemove])


  const handleSearch = (data) => {
    setResetSearch(false);
    setStoreSelected(null);
    getListFindStore({
      searchText: data?.name || keySearch,
    });
    setDataStore({
      storeName: data?.name || '',
      address: data?.address || '',
    });
    setKeySearch(data?.name || '');
  };

  const handleSearchText = (text) => {
    setResetSearch(false);
    setStoreSelected(null);
    setKeySearch(text);
    getListFindStore({
      searchText: text,
    });
  };

  const onChangeInputSearch = (text) => {
    setIsDisable(false);
  }

  const handleSelectStore = (value) => {
    setStoreSelected(value);
    setResetSearch(true);
    getListFindStore({
      searchText: value?.name,
    });
    setKeySearch(value?.name || '');
  };

  const checkStoreIsStoreLike = (id) => {
    const idx = listStoreLike.filter(item => item.store_id === id)
    if (idx && idx.length > 0) {
      return 'disabled'
    }
  }
  
  const changeStatusStoreLike = (id, type) => {
    if (checkStoreIsStoreLike(id) && type === 1) return;
    if (type === 1 && listStoreLike.length === 3) {
      setIsShowModal(true);
    } else {
      // add or remove store favorite
      removeStoreLikeById({store_id: id, favorite: type});
      setTimeout(() => {
        getListFindStore({
          searchText: keySearch,
        });
      }, 1000);
      // getListFindStore({
      //   searchText: keySearch,
      // });
    }
  }

  // useEffect(() => {

  // }, [])



  const renderListStoreLike = (data) => {
    if (data && data.length > 0) {
      return data.map((item) => (
        <li
          key={item.id}
          className={`item ${
            storeSelected && storeSelected?.id === item.id ? 'selected' : ''
          }`}
          aria-hidden
        >
          <span className='store-name'>
            {item.name} 
          </span>
          <img className='img-close' 
            aria-hidden 
            onClick={() => changeStatusStoreLike(item.store_id, 0)} 
            src={images.icon_close_store} 
            alt="" 
          />
        </li>
      ));
    }
  };

  const showDataProduct = (isShow) => {
    setIsShowData(!isShow)
  }

  const renderAllStore = data => {
    if (data && data.length > 0) {
      return data.map((item) => (
        <li
          key={item.id}
          className='item'
        >
          <div className='content' aria-hidden onClick={() => handleSelectStore(item)}>
            <p className="store-name" >
            {item.name}
            </p>
            <p className='position'>
              <img src={images.position_store} className="icon-position" alt="" />
              <span className='address'>
                {item.address}
              </span>
            </p>
          </div>
          <div className="button-add">
            <button className={`btn btn-add ${checkStoreIsStoreLike(item.id)}`} onClick={() => changeStatusStoreLike(item.id, 1)}>
              <span>매장 추가</span>
              <img className='img-plus' src={checkStoreIsStoreLike(item.id) === 'disabled' ? images.icon_plus_store_disabled : images.plus_store} alt="" /> 
              {/* icon_plus_store_disabled */}
            </button>
          </div>
        </li>
      ));
    }
    return <NoData />
    
  }

  return (
    <MainLayout
      customClass="MainPage"
      activePage={3}
      // isShowFooter
      isShowHeader
      titleHeader="즐겨찾는 매장"
      // icoBackWhite
      // isProcessingRemove
      isLoading={isLoadingNative}
      isShowIcon
      isLink
      isBackNative={searchParams.get('type') !== 'webview'}
    >
      <div className="setting-page page-store-like ">
        <div className="page-store-content">
          {
            listStoreLike.length > 0 && 
              <div className="list-store-select">
                <ul className="list">
                  {renderListStoreLike(listStoreLike)}
                </ul>
              </div>
          }
          <InputAutoCompleteSearch
            data={listAllStore || []} 
            handleSearch={handleSearch}
            handleSearchText={handleSearchText}
            resetSearch={resetSearch}
            placeholder="가맹점명을 입력해 주세요"
            showIconSearch
            classDiv="flex-search find-store-like-search"
            showDataProduct={showDataProduct}
            disabledSearch={isDisable}
            onChangeInputSearch={onChangeInputSearch}
          />
          
        </div>
        {
          isShowData && 
            <div className="wrapper-list-content">
              <ul className='list-all-store'>
                { (!isProcessing) && renderAllStore(listLocationStore)}
              </ul>
            </div>
        }
        
        {
          // (isProcessing || isProcessingRemove) && <Loading />
        }
      </div>
      <ModalPopup
        isOpen={isShowModal}
        // handleClose={() =>
        //   setIsShowModal(false)
        // }
        textBtnRight="확인"
        customClass="store-popup"
      >
        <div className="content">
          <div className="title-popup">
            즐겨찾는 매장은 최대 3개까지 <br />
            등록 가능합니다.
          </div>
          <Button
            type="button"
            customClass="btn btn-close"
            onClick={() => setIsShowModal(false)}
          >
            확인
          </Button>
        </div>
      </ModalPopup>

    </MainLayout>
  );
};

export default FindStoreLikeComponent;
