/* eslint-disable no-nested-ternary */
// import libs
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// Define action creators
export const { Types, Creators } = createActions({
  setLoadingList: ['data'],
  getListPlayground: ['data'],
  getListPlaygroundSuccess: null,
  getListPlaygroundFailed: null,

  getDetailPlayground: ['data'],
  getDetailPlaygroundSuccess: null,
  getDetailPlaygroundFailed: null,

  createPlayground: ['data'],
  createPlaygroundSuccess: null,
  createPlaygroundFailed: null,

  updatePlayground: ['data'],
  updatePlaygroundSuccess: null,
  updatePlaygroundFailed: null,

  // comment
  getListComment: ['data'],
  getListCommentWithoutLoading: ['data'],
  getListCommentSuccess: null,
  getListCommentFailed: null,

  createPlaygroundComment: ['data'],
  createPlaygroundCommentSuccess: null,
  createPlaygroundCommentFailed: null,

  updatePlaygroundComment: ['data'],
  updatePlaygroundCommentSuccess: null,
  updatePlaygroundCommentFailed: null,

  deletePlaygroundComment: ['data'],
  deletePlaygroundCommentSuccess: null,
  deletePlaygroundCommentFailed: null,

  deletePlayground: ['data'],
  deletePlaygroundSuccess: null,
  deletePlaygroundFailed: null,

  // reaction
  createPlaygroundReaction: ['data'],
  createPlaygroundReactionSuccess: null,
  createPlaygroundReactionFailed: null,

  // store
  getListStorePlayground: ['data'],
  getListStorePlaygroundSuccess: null,
  getListStorePlaygroundFailed: null,

  // hashtag
  getListHashtagPlayground: ['data'],
  getListHashtagPlaygroundSuccess: null,
  getListHashtagPlaygroundFailed: null,
});

// Initial state
export const INITIAL_STATE = Immutable({
  isLoading: false,
  getListFailed: null,
  list: [],
  listParams: {},

  item: {},
  isLoadingDetail: false,
  getItemFailed: null,

  isLoadingCreatePlayground: false,
  createPlaygroundSuccess: null,
  createPlaygroundFailed: null,

  isLoadingUpdatePlayground: false,
  updatePlaygroundSuccess: null,
  updatePlaygroundFailed: null,

  // comment
  isLoadingListComment: false,
  getListCommentFailed: null,

  isLoadingCreatePlaygroundComment: false,
  createPlaygroundCommentSuccess: null,
  createPlaygroundCommentFailed: null,

  isLoadingUpdatePlaygroundComment: false,
  updatePlaygroundCommentSuccess: null,
  updatePlaygroundCommentFailed: null,

  isLoadingDeletePlaygroundComment: false,
  deletePlaygroundCommentSuccess: null,
  deletePlaygroundCommentFailed: null,

  isLoadingDeletePlayground: false,
  deletePlaygroundSuccess: null,
  deletePlaygroundFailed: null,

  // reaction
  isLoadingCreatePlaygroundReaction: false,
  createPlaygroundReactionSuccess: null,
  createPlaygroundReactionFailed: null,

  // store
  isLoadingStore: false,
  getListStoreFailed: null,
  stores: [],

  // hashtag
  isLoadingHashtag: false,
  getListHashtagFailed: null,
  hashtags: [],
});

const setLoadingList = (state, action) => {
  console.log(setLoadingList);
  return state.merge({
    isLoading: true,
  });
};

const getListPlayground = (state, action) => {
  const { data } = action;
  let listData = [];
  if (data?.page > 1) {
    listData = state?.list;
  }
  return state.merge({
    isLoading: true,
    type: action.type,
    list: listData,
    
    getItemFailed: null,

    isLoadingDeletePlayground: false,
    deletePlaygroundSuccess: null,
    deletePlaygroundFailed: null,
    
    isLoadingCreatePlaygroundReaction: false,
    createPlaygroundReactionSuccess: null,
    createPlaygroundReactionFailed: null,

    isLoadingUpdatePlayground: false,
    updatePlaygroundSuccess: null,
    updatePlaygroundFailed: null,

    isLoadingCreatePlayground: false,
    createPlaygroundSuccess: null,
    createPlaygroundFailed: null,
  });
};

const getListPlaygroundSuccess = (state, action) => {
  const { data } = action;
  let listData = [];
  if (data?.current_page === 1) {
    listData = [];
  } else {
    listData = state?.list;
  }

  return state.merge({
    isLoading: false,
    type: action.type,
    listParams: data,
    list: data && [...listData, ...data.data],
  });
};

const getListPlaygroundFailed = (state, action) => {
  return state.merge({
    isLoading: false,
    type: action.type,
    getListFailed: action.data,
  });
};

const getDetailPlayground = (state, action) => {
  return state.merge({
    isLoadingDetail: true,
    type: action.type,
    item: {},
    getItemFailed: null,
    isLoadingUpdatePlayground: false,
    updatePlaygroundSuccess: null,
    updatePlaygroundFailed: null,
  });
};

const getDetailPlaygroundSuccess = (state, action) => {
  return state.merge({
    isLoadingDetail: false,
    type: action.type,
    item: action.data,
  });
};

const getDetailPlaygroundFailed = (state, action) => {
  return state.merge({
    isLoadingDetail: false,
    type: action.type,
    getItemFailed: action.data,
  });
};

const createPlayground = (state, action) => {
  return state.merge({
    isLoadingCreatePlayground: true,
    type: action.type,
  });
};

const createPlaygroundSuccess = (state, action) => {
  return state.merge({
    isLoadingCreatePlayground: false,
    type: action.type,
    createPlaygroundSuccess: action.data,
  });
};

const createPlaygroundFailed = (state, action) => {
  return state.merge({
    isLoadingCreatePlayground: false,
    type: action.type,
    createPlaygroundFailed: action.data,
  });
};

const updatePlayground = (state, action) => {
  return state.merge({
    isLoadingUpdatePlayground: true,
    type: action.type,
  });
};

const updatePlaygroundSuccess = (state, action) => {
  return state.merge({
    isLoadingUpdatePlayground: false,
    type: action.type,
    updatePlaygroundSuccess: action.data,
  });
};

const updatePlaygroundFailed = (state, action) => {
  console.log(action, 'updatePlaygroundFailed');
  return state.merge({
    isLoadingUpdatePlayground: false,
    type: action.type,
    updatePlaygroundFailed: action.data,
  });
};

// comment
const getListComment = (state, action) => {
  return state.merge({
    isLoadingListComment: true,
    type: action.type,
  });
};

const getListCommentWithoutLoading = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const getListCommentSuccess = (state, action) => {
  let comment = action.data;
  let item = {
    ...state.item,
    comment,
    comment_cnt: comment.length
  };
  let list = state.list;
  if (comment.length) {
    list = list.map((e) =>
      +e.id === +comment[0].inquiry_id
        ? {
            ...e,
            comment,
            comment_cnt: comment.length
          }
        : e
    );
  }

  return state.merge({
    isLoadingListComment: false,
    type: action.type,
    item,
    list,
    isLoadingCreatePlaygroundComment: false,
    createPlaygroundCommentSuccess: null,
    createPlaygroundCommentFailed: null,

    isLoadingUpdatePlaygroundComment: false,
    updatePlaygroundCommentSuccess: null,
    updatePlaygroundCommentFailed: null,

    isLoadingDeletePlaygroundComment: false,
    deletePlaygroundCommentSuccess: null,
    deletePlaygroundCommentFailed: null,
  
    isLoadingDeletePlayground: false,
    deletePlaygroundSuccess: null,
    deletePlaygroundFailed: null,
  });
};

const getListCommentFailed = (state, action) => {
  return state.merge({
    isLoadingListComment: false,
    type: action.type,
    getListCommentFailed: action.data,
  });
};

const createPlaygroundComment = (state, action) => {
  return state.merge({
    isLoadingCreatePlaygroundComment: true,
    type: action.type,
  });
};

const createPlaygroundCommentSuccess = (state, action) => {
  let comment = action.data;
  let item = {};
  if (state.item?.id) {
    item = {
      ...state.item,
      comment: state.item?.comment ? [comment, ...state.item?.comment] : [comment],
      comment_cnt: (state.item.comment_cnt || 0) + 1
    }
  }
  let list = state.list;
  list = list.map((e) =>
    +e.id === +comment.inquiry_id
      ? {
          ...e,
          comment: e.comment ? [comment, ...e.comment] : [comment],
          comment_cnt: (e.comment_cnt || 0) + 1
        }
      : e
  );

  return state.merge({
    isLoadingCreatePlaygroundComment: false,
    type: action.type,
    createPlaygroundCommentSuccess: action.data,
    item,
    list,
  });
};

const createPlaygroundCommentFailed = (state, action) => {
  return state.merge({
    isLoadingCreatePlaygroundComment: false,
    type: action.type,
    createPlaygroundCommentFailed: action.data,
  });
};

const updatePlaygroundComment = (state, action) => {
  return state.merge({
    isLoadingUpdatePlaygroundComment: true,
    type: action.type,
  });
};

const updatePlaygroundCommentSuccess = (state, action) => {
  let comment = action.data;
  let item = {};
  if (state.item?.id) {
    let commentList = state.item.comment.map(e => e.id === comment.id ? {...e, ...comment} : e)

    item = {
      ...state.item,
      comment: commentList,
    }
  }
  let list = state.list;
  list = list.map((inquiry) => {
    if (+inquiry.id === +comment.inquiry_id) {
      let commentList = inquiry.comment.map(e => e.id === comment.id ? {...e, ...comment} : e);
    
      return {
        ...inquiry,
        comment: commentList
      }
    }

    return inquiry
  });

  return state.merge({
    isLoadingUpdatePlaygroundComment: false,
    type: action.type,
    updatePlaygroundCommentSuccess: action.data,
    item,
    list,
  });
};

const updatePlaygroundCommentFailed = (state, action) => {
  return state.merge({
    isLoadingUpdatePlaygroundComment: false,
    type: action.type,
    updatePlaygroundCommentFailed: action.data,
  });
};

const deletePlaygroundComment = (state, action) => {
  return state.merge({
    isLoadingDeletePlaygroundComment: true,
    type: action.type,
  });
};

const deletePlaygroundCommentSuccess = (state, action) => {
  let comment = action.data;
  let item = {};
  if (state.item?.id) {
    let commentList = state.item.comment.filter(e => +e.id !== +comment.comment_id)

    item = {
      ...state.item,
      comment: commentList,
      comment_cnt: commentList.length || 0
    }
  }
  let list = state.list;
  list = list.map((inquiry) => {
    if (+inquiry.id === +comment.inquiry) {
      let commentList = inquiry.comment.filter(e => +e.id !== +comment.comment_id)
    
      return {
        ...inquiry,
        comment: commentList,
        comment_cnt: commentList.length || 0,
      }
    }

    return inquiry
  });

  return state.merge({
    isLoadingDeletePlaygroundComment: false,
    type: action.type,
    deletePlaygroundCommentSuccess: action.data,
    item,
    list,
  });
};

const deletePlaygroundCommentFailed = (state, action) => {
  return state.merge({
    isLoadingDeletePlaygroundComment: false,
    type: action.type,
    deletePlaygroundCommentFailed: action.data,
  });
};

const deletePlayground = (state, action) => {
  return state.merge({
    isLoadingDeletePlayground: true,
    type: action.type,
  });
};

const deletePlaygroundSuccess = (state, action) => {
  console.log(action, 'deletePlaygroundSuccess');
  return state.merge({
    isLoadingDeletePlayground: false,
    type: action.type,
    deletePlaygroundSuccess: action.data,
  });
};

const deletePlaygroundFailed = (state, action) => {
  return state.merge({
    isLoadingDeletePlayground: false,
    type: action.type,
    deletePlaygroundFailed: action.data,
  });
};

// reaction
const createPlaygroundReaction = (state, action) => {
  return state.merge({
    isLoadingCreatePlaygroundReaction: true,
    type: action.type,
  });
};

const createPlaygroundReactionSuccess = (state, action) => {
  let item = {
    ...state.item,
    like_cnt: action.data.like_cnt,
    is_liked: action.data.is_liked,
  };
  let list = state.list;
  list = list.map((e) =>
    +e.id === +action.data.id
      ? {
          ...e,
          like_cnt: action.data.like_cnt,
          is_liked: action.data.is_liked,
        }
      : e
  );
  return state.merge({
    isLoadingcreatePlaygroundReaction: false,
    type: action.type,
    item,
    list,
    createPlaygroundReactionSuccess: action.data,
  });
};

const createPlaygroundReactionFailed = (state, action) => {
  return state.merge({
    isLoadingcreatePlaygroundReaction: false,
    type: action.type,
    createPlaygroundReactionFailed: action.data,
  });
};

// stores
const getListStorePlayground = (state, action) => {
  return state.merge({
    isLoadingStore: true,
    type: action.type,
    getListStoreFailed: null,
  });
};

const getListStorePlaygroundSuccess = (state, action) => {
  return state.merge({
    isLoadingStore: false,
    type: action.type,
    stores: action.data,
  });
};

const getListStorePlaygroundFailed = (state, action) => {
  return state.merge({
    isLoadingStore: false,
    type: action.type,
    getListStoreFailed: action.data,
  });
};

// hashtag
const getListHashtagPlayground = (state, action) => {
  return state.merge({
    isLoadingHashtag: true,
    type: action.type,
    getListHashtagFailed: null,
  });
};

const getListHashtagPlaygroundSuccess = (state, action) => {
  return state.merge({
    isLoadingHashtag: false,
    type: action.type,
    hashtags: action.data,
  });
};

const getListHashtagPlaygroundFailed = (state, action) => {
  return state.merge({
    isLoadingHashtag: false,
    type: action.type,
    getListHashtagFailed: action.data,
  });
};

// Assign handler to types.
const HANDLERS = {
  [Types.GET_LIST_PLAYGROUND]: getListPlayground,
  [Types.GET_LIST_PLAYGROUND_SUCCESS]: getListPlaygroundSuccess,
  [Types.GET_LIST_PLAYGROUND_FAILED]: getListPlaygroundFailed,

  [Types.GET_DETAIL_PLAYGROUND]: getDetailPlayground,
  [Types.GET_DETAIL_PLAYGROUND_SUCCESS]: getDetailPlaygroundSuccess,
  [Types.GET_DETAIL_PLAYGROUND_FAILED]: getDetailPlaygroundFailed,

  [Types.CREATE_PLAYGROUND]: createPlayground,
  [Types.CREATE_PLAYGROUND_SUCCESS]: createPlaygroundSuccess,
  [Types.CREATE_PLAYGROUND_FAILED]: createPlaygroundFailed,

  [Types.UPDATE_PLAYGROUND]: updatePlayground,
  [Types.UPDATE_PLAYGROUND_SUCCESS]: updatePlaygroundSuccess,
  [Types.UPDATE_PLAYGROUND_FAILED]: updatePlaygroundFailed,

  [Types.DELETE_PLAYGROUND]: deletePlayground,
  [Types.DELETE_PLAYGROUND_SUCCESS]: deletePlaygroundSuccess,
  [Types.DELETE_PLAYGROUND_FAILED]: deletePlaygroundFailed,

  // comment
  [Types.GET_LIST_COMMENT]: getListComment,
  [Types.GET_LIST_COMMENT_WITHOUT_LOADING]: getListCommentWithoutLoading,
  [Types.GET_LIST_COMMENT_SUCCESS]: getListCommentSuccess,
  [Types.GET_LIST_COMMENT_FAILED]: getListCommentFailed,

  [Types.CREATE_PLAYGROUND_COMMENT]: createPlaygroundComment,
  [Types.CREATE_PLAYGROUND_COMMENT_SUCCESS]: createPlaygroundCommentSuccess,
  [Types.CREATE_PLAYGROUND_COMMENT_FAILED]: createPlaygroundCommentFailed,

  [Types.UPDATE_PLAYGROUND_COMMENT]: updatePlaygroundComment,
  [Types.UPDATE_PLAYGROUND_COMMENT_SUCCESS]: updatePlaygroundCommentSuccess,
  [Types.UPDATE_PLAYGROUND_COMMENT_FAILED]: updatePlaygroundCommentFailed,

  [Types.DELETE_PLAYGROUND_COMMENT]: deletePlaygroundComment,
  [Types.DELETE_PLAYGROUND_COMMENT_SUCCESS]: deletePlaygroundCommentSuccess,
  [Types.DELETE_PLAYGROUND_COMMENT_FAILED]: deletePlaygroundCommentFailed,

  // reaction
  [Types.CREATE_PLAYGROUND_REACTION]: createPlaygroundReaction,
  [Types.CREATE_PLAYGROUND_REACTION_SUCCESS]: createPlaygroundReactionSuccess,
  [Types.CREATE_PLAYGROUND_REACTION_FAILED]: createPlaygroundReactionFailed,

  // stores
  [Types.GET_LIST_STORE_PLAYGROUND]: getListStorePlayground,
  [Types.GET_LIST_STORE_PLAYGROUND_SUCCESS]: getListStorePlaygroundSuccess,
  [Types.GET_LIST_STORE_PLAYGROUND_FAILED]: getListStorePlaygroundFailed,

  // hashtag
  [Types.GET_LIST_HASHTAG_PLAYGROUND]: getListHashtagPlayground,
  [Types.GET_LIST_HASHTAG_PLAYGROUND_SUCCESS]: getListHashtagPlaygroundSuccess,
  [Types.GET_LIST_HASHTAG_PLAYGROUND_FAILED]: getListHashtagPlaygroundFailed,
};

console.log(Types, 'Types');

// Create reducers by pass state and handlers
export const playgroundReducer = createReducer(INITIAL_STATE, HANDLERS);
