import { useEffect, useMemo } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useCheckUpdate,
  useFilterParam,
  // import FormDate from 'components/Form/Date';
} from 'customHooks/utils';
import { useSelector } from 'react-redux';
import { Creators } from 'store/playground/index';

const getSelector = (key) => (state) => state['playgroundReducer'][key];

const loadingCreateSelector = getSelector('isLoadingCreatePlaygroundReaction');
const createSuccessSelector = getSelector('createPlaygroundReactionSuccess');
const createFailedSelector = getSelector('createPlaygroundReactionFailed');

export const useReaction = (form) => {
  const [isLoading, handleSubmit] = useSubmit({
    loadingSelector: loadingCreateSelector,
    action: Creators.createPlaygroundReaction,
  });

  useSuccess(createSuccessSelector, 'Create success');
  useFailed(createFailedSelector, 'Create Failed');

  const _handleSubmit = (values) => {
    console.log(values);

    return handleSubmit(values);
  };

  return [isLoading, _handleSubmit];
};
