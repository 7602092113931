import React, { Fragment } from 'react';
import FilterPopular from './../Filter/Popular';
import RecentSearch from './RecentSearch';
import SearchInput from './SearchInput';

function SearchFilter({ param, setParam, loading, data }) {

  return (
    <Fragment>
      <SearchInput
        param={param}
        setParam={setParam}
        loading={loading}
        data={data}
      />
      <div className={`box-data-default ${param.keyword ? 'd-none' : ''}`}>
        <div className="box-data-default__hashtag">
          <FilterPopular
            param={param}
            setParam={setParam}
            loading={loading}
            data={data}
            title="인기 해시태그"
            paramKey="keyword"
            hastagKey="hashtag"
            noData={
              <div className='box-data-empty text-center w-100'>
                <span>인기있는 해시태그가 없습니다.</span>
              </div>
            }
            omitScroll
            omitSetParam
          />
        </div>
        <RecentSearch
          param={param}
          setParam={setParam}
          loading={loading}
          data={data}
        />
      </div>
    </Fragment>
  );
}

export default SearchFilter;
