import React from 'react';
import themeImages from '../../themes/images';

function Image({ images, className, detailLink, itemDisplayFormat }) {
  return (
    <div className={`item-content-post__image`} onClick={detailLink}>
      <img
        className={`img-post${className ? ' ' + className : ''}`}
        src={images[0] ? (itemDisplayFormat !== '1' ? images[0].path_medium : images[0].path_thumbnail) : themeImages.img_playground_post_default}
        alt=""
      />
      {/*{images?.length > 1 ? (
        <div className="count-number">
          <label>{images?.length - 1}</label>
        </div>
      ) : null}*/}
    </div>
  )
}

export default Image;
