import SkeletonLoadingComponent from 'components/SkeletonLoading';
import React from 'react';
import LikeComment from './LikeComment';

type props = {
  type: String,
};

function LoadingCommunity({ type }) {
  return (
      <>
      {type === 'detail' && <div className="loading-community">
          <div className="info">
            <SkeletonLoadingComponent
              cssClass="avatar"
              circle
              width={30}
              height={30}
            />
            <SkeletonLoadingComponent cssClass="name" height={16} width={120} />
          </div>
          <div className="content">
            <SkeletonLoadingComponent
              cssClass={'text'}
              height={18}
              width={'80%'}
            />
            <br />
            <SkeletonLoadingComponent
              cssClass={'text'}
              height={16}
              width={'100%'}
            />
            <br />
            <SkeletonLoadingComponent
              cssClass={'text'}
              height={16}
              width={'100%'}
            />
            <br />
            <SkeletonLoadingComponent
              cssClass={'img'}
              height={130}
              width={'100%'}
            />
              
          </div>
          </div>
      }
      {
        type === 'comment' && <div className="loading-comment">
        <div className="info">
          <SkeletonLoadingComponent
            cssClass="avatar"
            circle
            width={30}
            height={30}
          />
          <div className="content">
            <SkeletonLoadingComponent cssClass="name" height={13} width={80} />
            <SkeletonLoadingComponent cssClass="name" height={15} width={200} />
          </div>
        </div>
      </div>
      }
      </>
  );
}

LoadingCommunity.defaultProps = {
  type: 'playground',
};

export default LoadingCommunity;
