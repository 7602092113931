import { useMemo } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useCheckUpdate,
  useFilterParam,
  // import FormDate from 'components/Form/Date';
} from 'customHooks/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/playground/index';
import { useHistory } from 'react-router-dom';
import ROUTERS from 'constants/router';
import heic2any from 'heic2any';

const RELATION = {
  PLAYGROUND: ['stores', 'user', 'hashtags'],
  QNA: ['stores', 'user'],
};

const FilterByType = {
  1: { sort_by: 'created_at' },
  2: { sort_by: 'created_at' },
  3: { sort_by: 'like_cnt', has_hashtag: 1 },
};

const getSelector = (key) => (state) => state['playgroundReducer'][key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const paramsSelector = getSelector('listParams');
const getListFailedSelector = getSelector('getListFailed');

const loadingSelectorDetail = getSelector('isLoadingDetail');
const itemSelector = getSelector('item');
const getItemFailedSelector = getSelector('getItemFailed');

const loadingDeleteSelector = getSelector('isLoadingDeletePlayground');
const deleteSuccessSelector = getSelector('deletePlaygroundSuccess');
const deleteFailedSelector = getSelector('deletePlaygroundFailed');

const loadingCreateSelector = getSelector('isLoadingCreatePlayground');
const createSuccessSelector = getSelector('createPlaygroundSuccess');
const createFailedSelector = getSelector('createPlaygroundFailed');

const loadingUpdateSelector = getSelector('isLoadingUpdatePlayground');
const updateSuccessSelector = getSelector('updatePlaygroundSuccess');
const updateFailedSelector = getSelector('updatePlaygroundFailed');

const defaultFilterType = 1;

export const useListQueryUrlParams = (type, current_time) => {
  const query = useQueryParams();
  const page = query.get('page') || 1;
  const page_size = query.get('page_size') || 10;
  const filterType = query.get('filterType');
  const store_id = query.get('store_id');
  const hash_tag = query.get('hash_tag');
  const keyword = query.get('keyword');
  const hash_tag_id = query.get('hash_tag_id');
  const has_like_cnt = query.get('has_like_cnt');
  const has_hashtag = query.get('has_hashtag');
  const token = query.get('token');
  const reload = query.get('reload');
  const _current_time = query.get('current_time') || current_time;

  return useMemo(() => {
    if (!_current_time) {
      return null
    }
    return {
      page,
      page_size,
      sort_by: 'created_at',
      sort_direction: 'desc',
      type,
      filterType: +(filterType || defaultFilterType),
      relation: RELATION[type],
      store_id,
      hash_tag,
      keyword,
      hash_tag_id,
      has_like_cnt,
      has_hashtag,
      token,
      reload,
      current_time: _current_time,
      ...FilterByType[+(filterType || defaultFilterType)],
    };
    //eslint-disable-next-line
  }, [page, page_size, store_id, hash_tag, keyword, hash_tag_id, has_like_cnt, has_hashtag, _current_time]);
};

export const useListQueryParams = (type) => {
  const params = {
    page: 1,
    page_size: 10,
    sort_by: 'created_at',
    sort_direction: 'desc',
    type,
    filterType: defaultFilterType,
    relation: RELATION[type],
    store_id: '',
    hash_tag: '',
    keyword: '',
    hash_tag_id: '',
    has_like_cnt: '',
    has_hashtag: '',
    ...FilterByType[defaultFilterType],
  };
  const [param, setParam] = useFilterParam(params);

  return [param, setParam];
};

export const useList = (param) => {
  const params = useMemo(
    () => param ? ({
      ...param,
      ...FilterByType[param.filterType],
    }) : null,
    [param]
  );

  return useFetchByParam({
    action: Creators.getListPlayground,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: params,
  });
};

export const useLoadingList = () => {
  const dispatch = useDispatch();
  const setLoading = () => {
    dispatch(Creators.setLoadingList(true));
  }

  return setLoading
};

export const useListResParams = () => {
  return useSelector(paramsSelector);
};

export const useDetail = (params, type) => {
  const param = useMemo(
    () => ({
      id: params.id,
      relation: RELATION[type],
    }),
    [params.id]
  );

  return useFetchByParam({
    action: Creators.getDetailPlayground,
    loadingSelector: loadingSelectorDetail,
    dataSelector: itemSelector,
    failedSelector: getItemFailedSelector,
    param,
  });
};

export const useDelete = (form) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, handleSubmit] = useSubmit({
    loadingSelector: loadingDeleteSelector,
    action: Creators.deletePlayground,
  });

  const onSuccess = () => {
    if (form?.params) {
      dispatch(
        Creators.getListPlayground({
          ...form?.params,
          page: 1,
        })
      );
    } else {
      history.go(-1);
    }
  };

  useSuccess(deleteSuccessSelector, 'Delete success', onSuccess);
  useFailed(deleteFailedSelector, 'Delete Failed');

  // eslint-disable-next-line no-underscore-dangle
  const _handleSubmit = (values) => {
    return handleSubmit({
      inquiry: values?.inquiry || null,
    });
  };

  return [isLoading, _handleSubmit];
};

export const useCreate = (form) => {
  const history = useHistory();
  const [isLoading, handleSubmit] = useSubmit({
    loadingSelector: loadingCreateSelector,
    action: Creators.createPlayground,
  });

  const onSuccess = () => {
    form.onSuccess();
  };

  const onFailed = () => {
    if (form.onFailed) {
      form.onFailed();
    }
  };

  useSuccess(createSuccessSelector, 'Create success', onSuccess);
  useFailed(createFailedSelector, 'Create Failed', onFailed);

  // eslint-disable-next-line no-underscore-dangle
  const _handleSubmit = async (values) => {
    let data = new FormData();
    data.append('type', values.type);
    data.append('title', values.title);
    data.append('content', values.content);
    (values?.hashtag ?? []).map((e) => {
      data.append('hashtag[]', e.replace('#', ''));
    });
    let images = await Promise.all((values?.image ?? []).map(async e => {
      if (['image/heif', 'image/heic'].includes(e.type)) {
        let conversionResult = await heic2any({
          blob: e,
          toType: 'image/jpeg',
        })
        return conversionResult
      } else {
        return e
      }
    }))
    images.map(async (e) => {
      data.append('image[]', e);
    });

    (values?.store_id ?? []).map((e) => {
      data.append('store_id[]', e);
    });
    for (var pair of data.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
    }
    console.log(data, 'ddd');

    return handleSubmit(data);
  };

  return [isLoading, _handleSubmit];
};

export const useUpdate = (form) => {
  const history = useHistory();
  const [isLoading, handleSubmit] = useSubmit({
    loadingSelector: loadingUpdateSelector,
    action: Creators.updatePlayground,
  });

  const onSuccess = () => {
    form.onSuccess();
  };

  const onFailed = () => {
    console.log('onFailed');
    if (form.onFailed) {
      console.log('form.onFailed');
      form.onFailed();
    }
  };

  useSuccess(updateSuccessSelector, 'Update success', onSuccess);
  useFailed(updateFailedSelector, 'Update Failed', onFailed);

  // eslint-disable-next-line no-underscore-dangle
  const _handleSubmit = async (values) => {
    let data = new FormData();
    data.append('_method', 'PUT');
    data.append('id', values.id);
    data.append('type', values.type);
    data.append('title', values.title);
    data.append('content', values.content);
    (values?.hashtag ?? []).map((e) => {
      data.append('hashtag[]', e.replace('#', ''));
    });

    let images = (values?.image ?? [])
      .filter((e) => e instanceof File);
    images = await Promise.all(images.map(async e => {
      if (['image/heif', 'image/heic'].includes(e.type)) {
        let conversionResult = await heic2any({
          blob: e,
          toType: 'image/jpeg',
        })
        return conversionResult
      } else {
        return e
      }
    }))
    images.map((e) => {
      data.append('image[]', e);
    });

    (values?.image_remove ?? []).map((e) => {
      data.append('image_remove[]', e);
    });
    (values?.store_id ?? []).map((e) => {
      data.append('store_id[]', e);
    });

    return handleSubmit({ data, id: values.id });
  };

  return [isLoading, _handleSubmit];
};
