import React, { Fragment } from 'react';
import images from '../../../../themes/images';
import { useListStoreFavoriteParam, useListStoreFavorite } from 'customHooks/store';
import useChangeUrlParams from 'customHooks/useChangeUrlParams'
import useCountSize from 'customHooks/playground/useCountSize';

function FilterFavorite({ data, loading, param, setParam }) {
  const storeParam = useListStoreFavoriteParam();
  const [stores, loadingStore] = useListStoreFavorite(storeParam);
  const { changeUrlParams } = useChangeUrlParams();

  useCountSize('loadingStore', loadingStore);

  const _onChangeStore = (store) => {
    if (+store.id === +param.store_id) {
      return;
    }
    changeUrlParams(param, [
      { type: 'store_id', value: store.id },
      { type: 'keyword', value: store.name },
    ]);
  };

  return (
    !loadingStore && (
      <Fragment>
        {
          stores.length > 0 && 
          <div className="list-store-select">
            <ul className="list">
              {stores.map((item) => (
                <li
                  key={item.id}
                  className={`item${+param.store_id === +item.id ? ' selected' : ''}`}
                  aria-hidden
                  onClick={() => _onChangeStore(item)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        }
      </Fragment>
    )
  );
}

export default FilterFavorite;
