import React, { useState, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useOnClickOutside from 'customHooks/useClickOutSide';
import ROUTERS from 'constants/router';
import HeaderDetailComponent from 'screens/playground/components/HeaderDetail';
import LoadingCommunity from 'components/Playground/Loading';
import PlaygroundCommentList from 'components/Playground/Comment';
import PlaygroundCommentForm from 'components/Playground/Comment/Form';
import { useDetail, useDelete } from 'customHooks/playground';
import { useReaction } from 'customHooks/playground/reaction';
import { useCreate } from 'customHooks/playground/comment';
import ImagePreviewModal from 'components/Playground/ImagesPreView';
import { formatDate } from "utils/Helpers";
import { useSelector } from 'react-redux';
import images from 'themes/images';
import ModalPlayground from 'components/Playground/ModalPlayground';
import TimeAgoComponent from 'components/Playground/TimeAgo';
import MainLayout from '../../../layout/MainLayout';

import AvatarDefault from '../../../assets/icons/avatar_default.svg';
import { ReactComponent as IconDislike } from '../../../assets/icons/icon_dislike_detail.svg';
import { ReactComponent as Iconlike } from '../../../assets/icons/icon_like_detail.svg';
import { ReactComponent as IconCmt } from '../../../assets/icons/icon_cmt_detail.svg';
import { useUser } from 'customHooks/useUser';
import { priceFormat } from 'utils/Helpers'
import { useEffect } from 'react';



type Props = {
  isProcessing: Boolean,
};

const DetailCommunityQA = ({  }: props) => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const [dataDetail, loading, dataErrors] = useDetail(params, searchParams.get('type') || 'PLAYGROUND')
  const [imagesView, setImagesView] = useState('');
  const [isShowModalView, setIsShowModalView] = useState(false);
  const [dataModal, setDataModal] = useState({
    isShow: false,
    type: '',
    textBtnLeft: '취소',
    textBtnRight: '삭제',
    title: '글을 삭제 하시겠습니까?',
  })
  const wrappref = useRef();
  const userInfo = useUser();
  // const getSelector = (key) => (state) => state['playgroundReducer'];

  // const loadingCommentList = getSelector('isLoadingListComment');
  // const loadingDelCmt = useSelector((state) => state.playgroundReducer?.isLoadingDeletePlaygroundComment || false)
  useOnClickOutside(wrappref, () => {});

  useEffect(() => {
    // nếu check trắng page thì nói bên api trả code về => 404 nghe fen
    if (dataErrors && dataErrors.code === 404) {
      setDataModal({
        isShow: true,
        type: '404',
        textBtnLeft: '',
        textBtnRight: '확인',
        title: '존재하지 않는 게시물입니다.',
      })
    }
  }, [dataErrors])


  const handleUpdate = () => {
    let url = ROUTERS.INQUIRY_PRODUCT_DETAIL.replace(':id', dataDetail.id);
    let getType = window.location.search
    if (getType.includes('type=QNA')) {
      url = ROUTERS.INQUIRY_PRODUCT_DETAIL.replace(':id', dataDetail.id);
    }else{
      url = ROUTERS.PLAYGROUND_EDIT.replace(':id', dataDetail.id);
    }
    console.log(url, 'url');
    history.push(url);
  };

  const _onSuccess = () => {
    history.push(ROUTERS.INQUIRY_PRODUCT)
  }

  const [loadingDelete, onDelete] = useDelete({ onSuccess: _onSuccess });

  const handleConfirmDelete = (item) => {
    setDataModal({
      isShow: true,
      type: 'delete',
      textBtnLeft: '취소',
      textBtnRight: '삭제',
      title: '글을 삭제 하시겠습니까?',
      data: {inquiry: dataDetail?.id || null}
    })
    // onDelete({
    //   inquiry: dataDetail?.id || null
    // })
  };

  const handleCloseModal = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const handleCancelDelete = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const handleConfirm = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
    if (dataModal.type === '404') {
      // window.backToScreenNative()
      history.push(searchParams.get('type') === 'PLAYGROUND' ? ROUTERS.PLAYGROUND : ROUTERS.INQUIRY_PRODUCT)
    }else{
      onDelete(dataModal.data);
    }
    
  }

  const handleViewImage = (item) => {
    setImagesView(item);
    setIsShowModalView(true);
  };


  const renderImg = (attachments) => {
    if (attachments.length > 0) {
      return attachments.map((item, idx) => 
        <div
          key={idx}
          className="image"
          role="presentation"
          onClick={() => handleViewImage(item?.path_original || null)}
          onKeyDown={() => {}}
        >
          <img src={item?.path_medium} alt="" />
        </div>
      )
    }
  }

  const hideModalView = () => {
    console.log('hideModalView');
    setIsShowModalView(false);
  }

  const renderHashtag = (data) => {
    return data.map((item, idx) => 
      <li className="item">
        <span>#{item.hashtag}</span>
      </li>
    )
  }

  const renderStores = (data) => {
    return data.map((item, idx) => 
        <div className='item-box-store__store'>
          <img
            className='item-box-store__store__icon'
            src={images.icon_playground_marker_store}
            alt=''
          />
          <label className='item-box-store__store__text'>
            {item.name}
          </label>
        </div>
    )
  }

  const [loadingCreate, ] = useCreate({ onSuccess: () => {} });
  const [loadinReaction, onReaction] = useReaction();
  
  const reactAction = () => {
    onReaction(dataDetail)
  }

  
  return (
    <MainLayout
      isShowHeader={false}
      titleHeader="상품 상세"
      isCenter
      isShowIcon
      isLink
      isLoading={loadingDelete}
      customClass="page-detail-community"
    >
      <HeaderDetailComponent
        isShowAction={(userInfo?.uid === dataDetail?.user_id) && loading === false}
        handleUpdate={handleUpdate}
        handleDelete={handleConfirmDelete}
      />
      <div className="wrapper-page-content">
        {loading ? (
          <LoadingCommunity type="detail" />
        ) : (
          <>
            <div className="item-community">
              <div className={`community-head ${searchParams.get('type') === 'PLAYGROUND' ? 'community-head--playground' : 'community-head--qna'}`}>
                <div className="community-user">
                  <div className="left-box-info">
                    <div className="img-user">
                      {dataDetail?.user?.avatar ? (
                        <img src={dataDetail?.user?.avatar?.thumbnail} alt="" />
                      ) : (
                        <img src={AvatarDefault} alt="" />
                      )}
                    </div>
                    <div className="community-user-name">
                      {dataDetail?.user?.nickname || ''}
                    </div>
                  </div>
                  <div className="right-box-time">
                    <div className="community-time">
                      {/* {
                        searchParams.get('type') === 'PLAYGROUND' ? 
                        <TimeAgoComponent datetime={dataDetail?.created_at} /> : 
                        formatDate(dataDetail?.created_at, '', 'Y.MM.DD H:mm:ss')
                      } */}
                      {formatDate(dataDetail?.created_at, '', 'Y.MM.DD H:mm:ss')}
                    </div>
                  </div>
                </div>
                {
                  searchParams.get('type') === 'PLAYGROUND' && <div className="title-playground">
                  <div className="title">
                    <h2>{dataDetail?.title || ''}</h2>
                  </div>
                </div>
                }
                
              </div>
              
              <div className="wrap-item-community">
                <div className="community-content">
                  <div className="content" 
                  dangerouslySetInnerHTML={{ __html: dataDetail?.content }}
                  />
                  {
                    renderImg(dataDetail?.attachments || [])
                  }

                  {(dataDetail?.stores && searchParams.get('type') === 'PLAYGROUND') && (
                    <div className="community-detail-stores">
                      <div className='item-box-store'>
                        {renderStores(dataDetail?.stores || [])}
                      </div>
                    </div>
                  )}
                  {
                    searchParams.get('type') === 'PLAYGROUND' && <>
                      <div className="content-playground">
                        {dataDetail?.hashtags && (
                          <div className="community-hashtag">
                            <ul>
                              {renderHashtag(dataDetail?.hashtags || [])}
                            </ul>
                          </div>
                        )}
                      </div>
                    </>
                  }
                </div>
                <div className="community-like-cmt">
                  <div className="community-like" onClick={reactAction}>
                    {
                      dataDetail?.is_liked === "true" ? <Iconlike /> : <IconDislike />
                    }
                    <span className="number">{priceFormat(dataDetail?.like_cnt || 0)}</span>
                  </div>
                  <div className="community-cmt">
                    <IconCmt />
                    <span className="number">
                      {priceFormat(dataDetail?.comment_cnt || 0)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="comment">
              <PlaygroundCommentList
                data={dataDetail}
                userInfo={userInfo}
                type={searchParams.get('type')}
                loading={loading}
                infinityScroll
              />
              {/* {
                (loadingCreate) && <LoadingCommunity type={'comment'}/>
              } */}
            </div>
          </>
        )}
      </div>
      <div className="wrapper-page-footer">
        <PlaygroundCommentForm type="createCmt" defaultValues={{
          inquiry_id: dataDetail?.id,
        }}/>
      </div>

      {/* Popup view image */}
      <ImagePreviewModal data={imagesView} isShow={isShowModalView} setIsShowModalView={hideModalView}/>

      <ModalPlayground
        isShow={dataModal.isShow}
        handleShowModal={handleCloseModal}
        textBtnLeft={dataModal.textBtnLeft}
        textBtnRight={dataModal.textBtnRight}
        title={dataModal.title}
        handleBtnLeft={handleCancelDelete}
        handleBtnRight={handleConfirm}
        type={''}
      />
    </MainLayout>
  );
};

export default DetailCommunityQA;
