import { useEffect, useState } from 'react';
import { ROUTES, API } from 'utils/Apis';

export const useListRecentSearch = (keyword, isDelete) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (!keyword || !isDelete) {
      setLoading(true);
      let params = {
        page: 0,
        page_size: 0,
        sort_by: 'created_at',
        sort_direction: 'desc',
      };

      API.get(ROUTES.API_GET_LIST_SEARCH_HISTORY, params)
        .then((res) => {
          setLoading(false);
          setList(res.data.data);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [keyword, isDelete]);

  return [list, loading];
};

export const useDelete = () => {
  const [loading, setLoading] = useState(false);
  
  const handleDelete = (ids) => {
    setLoading(true)
    API.post(ROUTES.API_DELETE_SEARCH_HISTORY, {
      ids,
      _method: 'DELETE'
    })
    .then((res) => {
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  }

  return [loading, handleDelete];
};
