import { useEffect, useMemo, useState } from 'react';
import { ROUTES, API } from 'utils/Apis';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useCheckUpdate,
  useFilterParam,
  // import FormDate from 'components/Form/Date';
} from 'customHooks/utils';
import { useSelector } from 'react-redux';
import { Creators } from 'store/playground/index';

const getSelector = (key) => (state) => state['playgroundReducer'][key];

const loadingSelector = getSelector('isLoadingHashtag');
const listSelector = getSelector('hashtags');
const getListFailedSelector = getSelector('getListHashtagFailed');

export const useListHashtagParam = (reloadPage) => {
  const param = useMemo(() => {
    return {
      sort_by: 'created_at',
      sort_direction: 'desc',
      get_hash_tag_trending: 1,
    };
  }, [reloadPage])
  return param
};

export const useListHashtag = (param) => {
  return useFetchByParam({
    action: Creators.getListHashtagPlayground,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param,
  });
};

// export const useListHashtag = (reloadPage) => {
//   const [loading, setLoading] = useState(true);
//   const [list, setList] = useState([]);

//   useEffect(() => {
//     setLoading(true);
//     let params = {
//       sort_by: 'created_at',
//       sort_direction: 'desc',
//       get_hash_tag_trending: 1,
//     };

//     API.get(ROUTES.API_GET_LIST_HASHTAG, params)
//       .then((res) => {
//         setLoading(false);
//         setList(res.data.data);
//       })
//       .catch(() => {
//         setLoading(false);
//       });
//     // eslint-disable-next-line
//   }, [reloadPage]);

//   return [list, loading];
// };
