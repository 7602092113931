import SkeletonLoadingComponent from 'components/SkeletonLoading';
import React from 'react';

function LoadingCommunity({itemDisplayFormat}) {
  if (itemDisplayFormat === '1') {
    return (
      <div className="bg-white mb-3 p-2 d-flex justify-content-between">
        <div>
          <div className="mb-2">
            <SkeletonLoadingComponent
              height={16}
              width={50}
            />
          </div>
          <div className="mb-2">
            <SkeletonLoadingComponent
              cssClass={'pb-2'}
              height={16}
              width={150}
            />
          </div>
          <div className="mb-2">
            <SkeletonLoadingComponent
              cssClass={'pb-2'}
              height={14}
              width={150}
            />
          </div>
          <div>
            <SkeletonLoadingComponent
              height={12}
              width={100}
            />
          </div>
        </div>
        <div>
          <SkeletonLoadingComponent cssClass="name" height={90} width={70} />
        </div>
      </div>
    );
  }

  return (
    <div className="border-bottom pb-3 pt-2">
      <div className="mb-3 d-flex align-items-center">
        <SkeletonLoadingComponent
          cssClass="avatar"
          circle
          width={44}
          height={44}
        />
        <div className="ml-2">
          <div className="mb-1">
            <SkeletonLoadingComponent cssClass="name" height={18} width={150} />
          </div>
          <div>
            <SkeletonLoadingComponent cssClass="name" height={12} width={150} />
          </div>
        </div>
      </div>
      <div className="mb-2">
        <SkeletonLoadingComponent
          cssClass={'img'}
          height={150}
          width={'100%'}
        />
      </div>
      <div className="mb-1">
        <SkeletonLoadingComponent
          cssClass={'text'}
          height={18}
          width={'50%'}
        />
      </div>
      <div className="mb-1">
        <SkeletonLoadingComponent
          cssClass={'text'}
          height={16}
          width={'75%'}
        />
      </div>
      <div>
        <SkeletonLoadingComponent
          cssClass={'text'}
          height={16}
          width={'75%'}
        />
      </div>
    </div>
  );
}

LoadingCommunity.defaultProps = {
  type: 'playground',
};

export default LoadingCommunity;
