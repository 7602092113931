import React, { Fragment, useState, useRef, useEffect } from 'react';

function FormStoreFavorite({
  stores,
  storeFavorites = [],
  onChange,
  remove,
  setShowStoreFavorite,
}) {
  useEffect(() => {
    console.log(storeFavorites, "storeFavorites");
    if (storeFavorites.length) {
      onChange(storeFavorites.map((e) => e.id));
      setShowStoreFavorite(true);
    }
  }, [storeFavorites]);

  // useEffect(() => {
  //   window.showLoadingNative(loadingStore);
  // }, [loadingStore]);

  const onClickStore = (store) => {
    if (stores.includes(store.id)) {
      if (stores.length === 1) {
        return;
      }
      remove(store.id);
    } else {
      onChange(store.id);
    }
  };

  return (
    <Fragment>
      <div className={`store-list`}>
        <div className="title">우리동네</div>
        <div className="desc">
          문구야놀자 가맹점 기준으로 우리 동네가 설정됩니다.
        </div>
        <div className="d-flex overflow-auto">
          {storeFavorites.map((store, index) => (
            <div
              key={index}
              className={`store-item${
                stores.includes(store.id) ? ' active' : ''
              }`}
              onClick={() => onClickStore(store)}
            >
              {store.name}
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default FormStoreFavorite;
