/* eslint-disable global-require */
const images = {
  logoIntro: require('../assets/images/intro_logo.svg'),
  introCharacter: require('../assets/images/intro_character.svg'),
  settingLogo: require('../assets/images/setting_logo.svg'),
  camera: require('../assets/images/camera.svg'),
  phone: require('../assets/images/phone.svg'),
  download: require('../assets/images/download.svg'),
  iconBack: require('../assets/images/icon_top_arrow.svg'),
  iconRegisterSuccess: require('../assets/images/register_success.svg'),
  mainLogo: require('../assets/images/main_logo.svg'),
  iconBell: require('../assets/images/icon_ball.svg'),
  myCha: require('../assets/images/my_cha.svg'),
  iconPush: require('../assets/images/icon_plus.svg'),

  product1: require('../assets/images/product1.jpg'),
  product2: require('../assets/images/product2.jpg'),
  product3: require('../assets/images/product3.jpg'),
  product4: require('../assets/images/product4.png'),
  product5: require('../assets/images/product5.png'),
  product6: require('../assets/images/imgproduct1.png'),
  product7: require('../assets/images/imgProduct3.png'),
  buttonNotice: require('../assets/images/main_btn.svg'),
  icon_miniarrow: require('../assets/icons/icon_miniarrow.svg'),
  buttonAddProduct: require('../assets/images/main-adbtn.svg'),
  iconNonData: require('../assets/images/icon_listno.svg'),
  radioOff: require('../assets/images/icon_radio_off.svg'),
  radioON: require('../assets/images/icon_radio_on.svg'),

  level1: require('../assets/images/level1.svg'),
  sublevel1: require('../assets/images/level01.png'),
  nameLevel1: require('../assets/images/cha-bbobi-name.svg'),

  level2: require('../assets/images/cha_bboki_B.svg'),
  sublevel2: require('../assets/images/subleve02.png'),
  nameLevel2: require('../assets/images/cha-bboki-name2.svg'),

  level3: require('../assets/images/level03.svg'),
  sublevel3: require('../assets/images/sublevel03.png'),
  nameLevel3: require('../assets/images/cha-u-gae-name.svg'),

  level4: require('../assets/images/level04.svg'),
  sublevel4: require('../assets/images/sublevel04.png'),
  nameLevel4: require('../assets/images/cha-ri-name.svg'),

  level5: require('../assets/images/level05.svg'),
  sublevel5: require('../assets/images/subLevel05.png'),
  nameLevel5: require('../assets/images/cha-guya-name.svg'),

  itemLevel1: require('../assets/images/cha_bbobi_M.svg'),
  itemLevel2Off: require('../assets/images/level2-off.svg'),
  itemLevel3Off: require('../assets/images/level3-off.svg'),
  itemLevel4Off: require('../assets/images/level4-off.svg'),
  itemLevel5Off: require('../assets/images/level5-off.svg'),

  itemLevel2On: require('../assets/images/levelOn02.svg'),
  itemLevel3On: require('../assets/images/levelOn03.svg'),
  itemLevel4On: require('../assets/images/levelOn04.svg'),
  itemLevel5On: require('../assets/images/levelOn05.svg'),

  iconClose: require('../assets/images/pop_XW.svg'),
  imgDefault: require('../assets/images/icon_imageadd.svg'),
  iconRepMy: require('../assets/images/icon_mp_re.svg'),
  iconOffLike: require('../assets/images/icon_mp_like.svg'),
  iconOnLike: require('../assets/images/icon_mp_likeOn.svg'),
  iconPopup: require('../assets/images/popup_character.svg'),
  iconClosePopup: require('../assets/images/popup_closed.svg'),
  iconRepMyOn: require('../assets/images/icon_mp_reOn.svg'),
  pop_X: require('../assets/icons/pop_X.svg'),
  iconPopX: require('../assets/images/pop_X.svg'),
  iconArrowBB: require('../assets/images/icon_arrowBB.svg'),

  box1: require('../assets/images/icon_mg01.svg'),
  box2: require('../assets/images/icon_mg02.svg'),
  box3: require('../assets/images/icon_mg03.svg'),
  box4: require('../assets/images/icon_list.svg'),

  iconLogout: require('../assets/images/icon_logout.svg'),
  icon_arrow_R: require('../assets/icons/icon_arrow_R.svg'),
  icon_listno: require('../assets/icons/icon_listno.svg'),

  iconMyEdit: require('../assets/images/icon_myedite.svg'),

  iconClose2: require('../assets/images/pop_miniX.svg'),
  progressBar: require('../assets/images/progressbar.gif'),
  iconShop: require('../assets/images/icon_shop.svg'),
  iconPosition: require('../assets/icons/position.svg'),
  icon_level1: require('../assets/images/icon_level1.svg'),
  icon_level3: require('../assets/images/icon_level3.svg'),
  icon_level5: require('../assets/images/icon_level5.svg'),
  icon_level2: require('../assets/images/icon_lever2.svg'),
  icon_character_success: require('../assets/images/popup_character_OK.svg'),
  img_GPS: require('../assets/images/icon_gps.svg'),
  icon_point: require('../assets/images/icon_point.svg'),
  img_intro: require('../assets/images/Intro.gif'),
  img_introMap: require('../assets/images/SP_04.svg'),

  icon_tooltip: require('../assets/icons/icon_tooltip.svg'),
  icon_bacode_W: require('../assets/icons/icon_bacode_W.svg'),
  icon_close_B: require('../assets/icons/icon_close_B.svg'),
  img_bacode: require('../assets/images/img_bacode.jpg'),
  icon_Reset_web: require('../assets/icons/icon_Reset_web.svg'),

  icon_balloon: require('../assets/images/icon_balloon.svg'),
  image_notice_home: require('../assets/images/popup.webp'),
  icon_closebox: require('../assets/images/icon_closebox.svg'),
  icon_here: require('../assets/images/icon_here.svg'),
  tap: require('../assets/images/tap.svg'),
  icon_bbobi: require('../assets/images/icon_bbobi.svg'),

  img_card: require('../assets/images/img_card.png'),
  img_card_2x: require('../assets/images/img_card@2x.png'),
  img_card_3x: require('../assets/images/img_card@3x.png'),
  yellowbubble: require('../assets/images/yellowbubble.png'),
  yellowbubble_2x: require('../assets/images/yellowbubble@2x.png'),
  icon_guya: require('../assets/images/icon_guya.png'),
  icon_location_select: require('../assets/images/icon_location_select.svg'),
  icon_arrowBB_select: require('../assets/images/icon_arrowBB_select.svg'),
  icon_bboki: require('../assets/images/icon_bboki.png'),
  icon_top_arrow: require('../assets/images/icon_top_arrow.png'),
  icon_arrow_right: require('../assets/images/arrow_right.png'),

  icon_pencil: require('../assets/images/icon_pencil.svg'),
  img_warning: require('../assets/images/img_warning.svg'),
  icon_icon_close_B3: require('../assets/images/icon_close_B3.png'),
  icon_icon_search3: require('../assets/images/icon_search3.png'),
  icon_clock3x: require('../assets/images/icon_clock3x.png'),
  icon_arrow_R_list: require('../assets/images/icon_arrow_R_list.svg'),
  icon_location_order: require('../assets/images/icon_location_order.svg'),
  arrow_right_order: require('../assets/images/arrow_right_order.svg'),
  img_pop_home: require('../assets/images/img_pop_home.svg'),
  icon_date_history: require('../assets/images/icon_date_history.svg'),
  icon_location_history: require('../assets/images/icon_location_history.svg'),
  popup_home_190722: require('../assets/images/popup_home_190722.svg'),
  close_popup_home: require('../assets/images/close_popup_home.svg'),
  guide_use_220719: require('../assets/images/guide_use_220719.svg'),
  popup_home_280822: require('../assets/images/popup_home_280822.svg'),
  icon_arrow_R_p: require('../assets/images/icon_arrow_R_p.svg'),
  question_guya: require('../assets/images/question_guya.svg'),
  icon_arrow_D: require('../assets/images/icon_arrow_D.svg'),
  icon_miniarrow_red: require('../assets/images/icon_miniarrow_red.svg'),
  icon_edit_action: require('../assets/images/icon_edit_action.svg'),
  icon_lock_private: require('../assets/images/icon_lock_private.svg'),
  icon_lock_big: require('../assets/images/icon_lock_big.svg'),
  icon_arrow_question: require('../assets/images/icon_arrow_question.png'),
  icon_arrow_answer: require('../assets/images/icon_arrow_answer.svg'),
  bg_triangle: require('../assets/images/bg-triangle.svg'),
  icon_miniarrow_up_down: require('../assets/images/icon_miniarrow_up_down.svg'),
  event_popup_default: require('../assets/images/event_popup_default.svg'),
  event_popup01: require('../assets/images/event_popup01.svg'),
  event_popup02: require('../assets/images/event_popup02.webp'),
  icon_arrowR: require('../assets/icons/icon_arrow_right.svg'),
  box5: require('../assets/images/icon_mg05s.svg'),
  box6: require('../assets/images/icon_mg06s.svg'),
  box7: require('../assets/images/icon_mg07s.svg'),
  icon_top_arrow_B: require('../assets/images/icon_top_arrow_B.svg'),
  icon_comment_off: require('../assets/icons/icon_comment_off.svg'),
  icon_love_off: require('../assets/icons/icon_love_off.svg'),
  icon_comment_on: require('../assets/icons/icon_comment_on.svg'),
  icon_love_on: require('../assets/icons/icon_love_on.svg'),
  icon_arrow_bottom: require('../assets/icons/icon_arrow_bottom.svg'),
  icon_arrow_left: require('../assets/icons/icon_arrow_left.svg'),
  icon_percen: require('../assets/icons/icon_percen.svg'),

  icon_qna_create: require('../assets/icons/icon_qna_create.png'),
  icon_lock_qna: require('../assets/icons/icon_lock_qna.svg'),
  icon_location_store: require('../assets/icons/icon_location_store.png'),

  icon_gift: require('../assets/icons/icon_gift.png'),
  icon_empty_data: require('../assets/icons/icon_empty_data.svg'),

  icon_search: require('../assets/icons/icon_search.svg'),

  icon_location_current: require('../assets/icons/icon_location_current.svg'),
  icon_gps: require('../assets/icons/icon_gps.svg'),
  three_dot: require('../assets/icons/three_dot.svg'),

  icon_contact: require('../assets/icons/icon_contact.svg'),
  icon_no_data: require('../assets/icons/icon_nodata.svg'),
  position_store: require('../assets/icons/position_store.svg'),
  plus_store: require('../assets/icons/plus_store.svg'),
  icon_close_store: require('../assets/icons/icon_close_store.svg'),
  icon_close_img_preview: require('../assets/icons/icon_close_img_preview.svg'),
  icon_plus_store_disabled: require('../assets/icons/icon_plus_store_disabled.svg'),

  icon_arrow_down_dropdown: require('../assets/icons/icon_arrow_down_dropdown.svg'),

  icon_search_ipt: require('../assets/icons/icon_search_ipt.svg'),
  icon_playground_nearby: require('../assets/icons/icon_playground_nearby.svg'),
  icon_playground_trending: require('../assets/icons/icon_playground_trending.svg'),
  icon_playground_like: require('../assets/icons/icon_playground_like.svg'),
  icon_playground_marker: require('../assets/icons/icon_playground_marker.svg'),
  img_playground_post: require('../assets/images/img_playground_post.png'),
  icon_playground_heart: require('../assets/icons/icon_playground_heart.svg'),
  icon_playground_heart_active: require('../assets/icons/icon_playground_heart_active.svg'),
  icon_playground_comment: require('../assets/icons/icon_playground_comment.svg'),
  icon_playground_create: require('../assets/icons/icon_playground_create.svg'),
  icon_playground_smile: require('../assets/icons/icon_playground_smile.svg'),
  icon_search_disabled: require('../assets/icons/icon_search_disabled.svg'),
  icon_playground_close: require('../assets/icons/icon_playground_close.svg'),
  img_not_found: require('../assets/images/image-not-found.jpg'),
  img_playground_bg_list: require('../assets/images/img_playground_bg_list.svg'),
  img_playground_post_default: require('../assets/images/img_playground_post_default.svg'),
  icon_playground_marker_store: require('../assets/icons/icon_playground_marker_store.svg'),
  icon_playground_filter_all: require('../assets/icons/icon_playground_filter_all.svg'),
  icon_playground_hometown: require('../assets/icons/icon_playground_hometown.svg'),
  icon_inquiry_comment: require('../assets/icons/icon_inquiry_comment.svg'),
  icon_inquiry_heart: require('../assets/icons/icon_inquiry_heart.svg'),
  icon_inquiry_heart_active: require('../assets/icons/icon_inquiry_heart_like.svg'),
  icon_playground_clear: require('../assets/icons/icon_playground_clear.svg'),
};

export default images;
