import { useState } from 'react';
import { isIOS } from 'react-device-detect';

const useTouchDownRefresh = (isTouchEnd) => {
  const [isRerfesh, setIsRerfesh] = useState(false);
  
  let startY;
  let endY;
  let position;
  window.onscroll = function() {
    const {body} = window.document; //IE 'quirks'
    let document = window.document.documentElement; //IE with doctype
    document = (document.clientHeight) ? document : body;
    position = document.scrollTop;
  };
  


  const handelOnTouchStart = (event) => {
    startY = event?.changedTouches[0]?.clientY;
  };

  const handelOnTouchEnd = (event) => {
    // console.log('handelOnTouchEnd');
    endY = event?.changedTouches[0]?.clientY;
    // console.log(startY - endY, 'startY - endY');
    // console.log(position, 'position');
    if (isIOS) {
      if (startY - endY < -150 && startY !== endY) {
        if (isTouchEnd && (position <= 0 || position === undefined)) {
          console.log('loading touch end');
          setIsRerfesh(true)
          setTimeout(() => {
            setIsRerfesh(false);
          }, 100);
        }
      }
    }else{
      if (startY - endY < -200 && startY !== endY) {
        if (isTouchEnd && (position === 0 || position === undefined)) {
          console.log('loading touch end');
          setIsRerfesh(true)
          setTimeout(() => {
            setIsRerfesh(false);
          }, 100);
        }
      }
    }
  };

  const handelOnTouchMove = () => {
    console.log('loading touch start');
  }

  return [
      isRerfesh,
      // eslint-disable-next-line no-shadow
      (e, data) => {
        switch (data) {
          case 'handelOnTouchStart':
            handelOnTouchStart(e);
            break;
          case 'handelOnTouchEnd':
            handelOnTouchEnd(e);
            break;
          case 'handelOnTouchMove':
            handelOnTouchMove(e);
            break;
          default:
            break;
        }
      },
  ];
};

export default useTouchDownRefresh;
