import React, { Fragment, useState, useRef, useEffect } from 'react';
import { ReactComponent as IconClosePlayground } from '../../../../assets/icons/playground-tag-remove.svg';

function FormHashtags({ hashtags, setHashtag, remove }) {
  const [value, setValue] = useState('');
  const [stateFocusHashtag, setStateFocusHashtag] = useState(false);
  const ref = useRef(null)

  const handleChangeInput = (e) => {
    let inputValue = e.target.value.trim();
    if (inputValue === '' || !inputValue.includes('#')) {
      // return;
    }
    if (inputValue === '# ') {
      return;
    }
    // max 50 kí tự, tính thêm dấu # là 51
    if (inputValue.length > 50) {
      inputValue = inputValue.substring(0, 50);
      // return;
    }

    if (inputValue[0] !== '') {
      // return
    }

    // space ở cuối thif taoj hashtag
    if (e.target.value[e.target.value.length - 1] === ' ') {
      return createHashtag();
    }

    setValue(inputValue);
  };

  const createHashtag = () => {
    if (value.trim() === '') {
      return;
    }
    if (!hashtags.includes(value)) {
      setHashtag(value);
    }
    setValue('');
    if (hashtags.length === 4) {
      setStateFocusHashtag(false)
      // setValue('');
    } else {
      // setValue('#');
    }
  };

  const onKeyDown = (e) => {
    // enter or space
    if ((
      e.keyCode === 13
      /* || e.keyCode === 32*/
    ) && value.trim()) {
      createHashtag();
    }
  };

  const onFocus = () => {
    // if (!value.includes('#')) {
    //   // setValue('#');
    // }
    setStateFocusHashtag(true)
  };

  const onBlur = () => {
    createHashtag();
    setValue('');
    setStateFocusHashtag(false)
  };
  // 봌,  이롷다, 문구먚, 너구옽, 바다몿

  return (
    <Fragment>
      <div className={`col-sm-12 ${(stateFocusHashtag) ? 'group-box-hastag' : ''}`}>
        <input
          value={value}
          name={'subject'}
          onChange={handleChangeInput}
          type="text"
          className="form-control"
          placeholder={
            !stateFocusHashtag && hashtags.length < 5
              ? '게시글을 소개할 해시태그(#)를 등록하세요(최대 5개)'
              : ''
          }
          onKeyDown={onKeyDown}
          disabled={hashtags.length === 5}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      <div className="col-sm-12">
        <div className="tags">
          {hashtags.map((hastag, key) => (
            <div className="tag-item" key={key}>
              <span className="tag-label">#{hastag}</span>
              <span className="tag-remove" onClick={() => remove(key)}>
                <IconClosePlayground />
              </span>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default FormHashtags;
