// @flow
// libs
import React, { memo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import ko from 'timeago.js/lib/lang/ko';
import {formatDate, isTimeOver24HoursAgo} from "utils/Helpers";
timeago.register('ko', ko);

type Props = {
  type?: string,
  datetime?: string
};

const TimeAgoComponent = ({
  type,
  datetime
}: Props) => {

  const [time, setTime] = useState('');

  useEffect(() => {
    setTime(datetime)
  }, [datetime])

  
  const renderTime = () => {

    const dateTimeString = time; // Thời gian cần kiểm tra
    const result = isTimeOver24HoursAgo(dateTimeString);

    if (result) {
      let format = formatDate(time, '', 'YY.MM.DD')
      return <span className="time-ago">{format}</span>
    } else {
      let format = formatDate(time, '', 'HH:mm')
      return <span className="time-ago">{format}</span>
    }

    // let d1 = new Date().getTime();
    // let d2 = new Date(time).getTime();
    //
    // if ((d1 - d2)/ 1000 > 86400) {
    //   let format = formatDate(time, '', 'YY.MM.DD')
    //   return <span className="time-ago">{format}</span>
    // }else{
    //   let format = formatDate(time, '', 'H:mm')
    //   return <span className="time-ago">{format}</span>
    //   // return (
    //   //   <TimeAgo
    //   //     datetime={datetime}
    //   //     locale="ko"
    //   //     className="time-ago"
    //   //     opts={{
    //   //       minInterval: 5,
    //   //     }}
    //   //   />
    //     // )
    // }
  }
  return (
    <>
      {renderTime()}
    </>
  );
};

TimeAgoComponent.defaultProps = {
  type: '',
  datetime: ''
};

export default memo<Props>(TimeAgoComponent);
