import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useListStore } from 'customHooks/store';
import moment from 'moment'

import useChangeUrlParams from 'customHooks/useChangeUrlParams'

import InputAutoCompleteSearch from 'components/AutoComplete';
import FilterFavorite from './StoreFavorite';

const ProductInquiryFilter = ({ param, setParam, loading, data, setIsShowList, isShowList,handleFocusIptSearch }) => {
  const history = useHistory();
  const [disabledSearch, setDisableSearch] = useState(true)
  const [stores, loadingStore] = useListStore();
  const { changeUrlParams } = useChangeUrlParams();

  useEffect(() => {
    if (param.store_id) {
      setIsShowList(true)
    }
  }, [param.store_id])

  const handleSearch = (data) => {
    setIsShowList(true)
    changeUrlParams(param, [
      { type: 'store_id', value: data.id },
    ]);
    setDisableSearch(false)
  };
  const handleSearchText = (data) => {
    if (data || !disabledSearch) {
      setIsShowList(true)
      changeUrlParams(param, [
        { type: 'keyword', value: data },
        { type: 'store_id', value: '' },
        // { type: 'reload', value: '' + (+!+param.reload) },
        { type: 'current_time', value: moment().utc().format('YYYY-MM-DD HH:mm:ss') },
      ]);
      setDisableSearch(false)
    }
  };
  const onChangeInputSearch = (data) => {
    setIsShowList(!data)
  };
  const showDataProduct = (data) => {
    if (data) {
      setIsShowList(false)
    }
  };
  const handleClickSelectDropdown = (data) => {console.log('handleClickSelectDropdown', data);};
  const handleForcusIpt = (data) => {
    handleFocusIptSearch(data)
    // setIsShowList(!data)
  };
  const dataSearch = [];
  let keyword = '';
  if (param.store_id) {
    keyword = (stores || []).find(e => +e.id === +param.store_id)?.name || ''
  }
  if (param.keyword) {
    keyword = param.keyword
  }

  return (
    <div className="main-box-category">
      <div className="product-page">
        <FilterFavorite param={param} setParam={setParam} />

        {((param.store_id || param.keyword) && keyword) || (!param.store_id && !param.keyword) ? <InputAutoCompleteSearch
          data={stores || []}
          handleSearch={handleSearch}
          handleSearchText={handleSearchText}
          onChangeInputSearch={onChangeInputSearch}
          showDataProduct={showDataProduct}
          placeholder="가맹점명을 입력해 주세요"
          // hasSelect
          showBoxResult={!isShowList}
          handleClickSelectDropdown={handleClickSelectDropdown}
          dataSelected={dataSearch}
          classResult="data-store-product"
          showIconSearch
          classDiv="flex-search"
          disabledSearch={disabledSearch}
          handleForcusIpt={handleForcusIpt}
          highlight
          keyword={keyword}
          omitFocusSearchStore
        /> : <div className='auto-complete'>
          <div className={`search-store`}>
            <input
              className={`form-control input-search `}
            />
          </div>
        </div>}
      </div>
    </div>
  );
};

export default memo(ProductInquiryFilter);
