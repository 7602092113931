import { ModalPopup } from 'components/Modal';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import images from '../../themes/images';

function ImagePreviewModal({ data, isShow, setIsShowModalView }) {

  return (
    <ModalPopup
        isOpen={isShow}
        isShowIconClose
        handleClose={() => setIsShowModalView()}
        handleCloseOvl={() => setIsShowModalView()}
        customClass="view-product-popup"
      >
      <div className="review-modal">
        <div className="img-review">
          <img className='img' src={data} alt="" />
        </div>
      </div>
    </ModalPopup>
  );
}

export default ImagePreviewModal;
