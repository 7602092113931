import React, { memo, useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTERS from 'constants/router';

import FormFilePreview from './FormFilePreview';
import FormStoreFavorite from './FormStoreFavorite';
import FormHashtags from './FormHashtags';

import { useCreate, useUpdate } from 'customHooks/playground';

const PlaygroundForm = ({ data, loading, storeFavorites, backUrl }) => {
  const history = useHistory();
  const [values, setValue] = useState({
    type: 'PLAYGROUND',
    id: '',
    title: '',
    content: '',
    image: [],
    image_remove: [],
    store_id: [],
    hashtag: [],
  });
  const [showStoreFavorite, setShowStoreFavorite] = useState(false);
  const [isDisableSubmit, setDisableSubmit] = useState(true);

  useEffect(() => {
    if (data) {
      setValue((p) => ({
        ...p,
        id: data.id,
        title: data.title,
        content: data.content,
        image: data.attachments || [],
        store_id: (data.stores || []).map((e) => e.id),
        hashtag: (data.hashtags || []).map((e) => `${e.hashtag}`),
      }));
    }
  }, [data]);

  useEffect(() => {
    if (values.title && values.content && values.title.trim() && values.content.trim()) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [values]);

  const handleBack = () => {
    history.push(ROUTERS.PLAYGROUND);
  };

  const handleChangeInputTitle = (e) => {
    e.persist();
    let value = e.target.value
    if (e.target.value.length > 20) {
      value = value.substring(0, 20);
    }
    setValue((p) => ({
      ...p,
      [e.target.name]: value,
    }));
  };

  const handleChangeInputContent = (e) => {
    e.persist();
    let value = e.target.value
    if (e.target.value.length > 1000) {
      value = value.substring(0, 1000);
      // return
    }
    setValue((p) => ({
      ...p,
      [e.target.name]: value,
    }));
  };

  const _setImages = (file) => {
    setValue((p) => ({
      ...p,
      image: [...p.image, file],
    }));
  };

  const removeFile = (img, index) => {
    let image = values.image.filter((file, i) => i !== index);
    setValue((p) => ({
      ...p,
      image,
    }));
    if (img.path) {
      setValue((p) => ({
        ...p,
        image_remove: [...p.image_remove, img.path],
      }));
    }
  };

  const _setStore = (store_id) => {
    if (Array.isArray(store_id)) {
      setValue((p) => ({
        ...p,
        store_id,
      }));
    } else {
      setValue((p) => ({
        ...p,
        store_id: [...p.store_id, store_id],
      }));
    }
  };

  const removeStore = (index) => {
    let store_id = values.store_id.filter((e, i) => e !== index);
    setValue((p) => ({
      ...p,
      store_id,
    }));
  };

  const _setHashtags = (hashtag) => {
    setValue((p) => ({
      ...p,
      hashtag: [...p.hashtag, hashtag],
    }));
  };
  const removeHashtag = (index) => {
    let hashtag = values.hashtag.filter((file, i) => i !== index);
    setValue((p) => ({
      ...p,
      hashtag,
    }));
  };

  const onSuccess = () => {
    setValue({
      type: 'PLAYGROUND',
      id: '',
      title: '',
      content: '',
      image: [],
      image_remove: [],
      store_id: [],
      hashtag: [],
    });
    history.push(/*backUrl || */ROUTERS.PLAYGROUND);
  };

  const [loadingCreate, onCreate] = useCreate({ onSuccess });
  const [loadingUpdate, onUpdate] = useUpdate({ onSuccess });

  useEffect(() => {
    window.showLoadingNative(loadingCreate || loadingUpdate);
  }, [loadingCreate, loadingUpdate]);

  const _onSubmit = async (e) => {
    e.preventDefault();
    if (isDisableSubmit) {
      return;
    }
    if (data?.id) {
      await onUpdate(values);
    } else {
      window.showLoadingNative(true);
      await onCreate(values);
    }
  };

  return (
    <Fragment>
      <div className="playground-form">
        <div className="wrapper-header wrapper-header--playground">
          <div className="app-sub-header border-bottom w-100 d-flex align-items-center justify-content-between">
            <button type="button" className="btn p-0" onClick={handleBack}>
              취소
            </button>
            <button
              className={`btn p-0${isDisableSubmit ? ' disabled' : ''}`}
              type="button"
              onClick={_onSubmit}
            >
              {data?.id ? '수정' : '등록'}
            </button>
          </div>
        </div>

        <div className="page-playground">
          <div className="row">
            <label className="col-sm-12 required">제목</label>
            <div className="col-sm-12">
              <input
                value={values.title}
                // maxLength="20"
                name={'title'}
                onChange={handleChangeInputTitle}
                type="text"
                className="form-control"
                placeholder="제목을 작성해 주세요(20자 이내)"
              />
            </div>
          </div>
          <div className="row border-bottom">
            <label className="col-sm-12 required">내용</label>
            <div className="col-sm-12">
              <textarea
                value={values.content}
                // maxLength="1000"
                name={'content'}
                onChange={handleChangeInputContent}
                rows={7}
                className="form-control"
                placeholder="내용을 작성해 주세요(1000자 이내)"
              />
            </div>
          </div>
          <div className="row image-select border-bottom">
            <div className="col-sm-12">
              <FormFilePreview
                maxFile={5}
                images={values.image}
                onChange={_setImages}
                remove={removeFile}
              />
            </div>
          </div>
          <div
            className={`row image-select border-bottom${
              !showStoreFavorite ? ' d-none' : ''
            }`}
          >
            <div className="col-sm-12">
              <FormStoreFavorite
                stores={values.store_id}
                storeFavorites={
                  data?.id ? data?.favorite_stores : storeFavorites
                }
                onChange={_setStore}
                remove={removeStore}
                setShowStoreFavorite={setShowStoreFavorite}
              />
            </div>
          </div>
          <div className="row border-bottom group-item-hashtag">
            <label className="col-sm-12">
              해시태그
              <span className="text-muted">
                해시태그는 게시글 검색 시 활용 됩니다.
              </span>
            </label>
            <FormHashtags
              hashtags={values.hashtag}
              setHashtag={_setHashtags}
              remove={removeHashtag}
            />
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="note">
                <div className="title">※ 게시글 작성 시 유의사항</div>
                <div className="content">
                  깨끗한 커뮤니티 문화를 위해 명예 훼손 / 욕설 / 비방 등<br />
                  내용이 포함된 글 작성 시, 관리자에 의해 삭제 될 수 있음
                  <br />을 안내 드립니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default memo(PlaygroundForm);
