import React, { Fragment } from 'react';
import LikeComment from './LikeComment';
import Hashtags from './Hashtags';
import images from "../../themes/images";
import ROUTERS from 'constants/router';
import { useHistory } from 'react-router-dom';
import UserInfo from './UserInfo';
import {abbreviateNumber, limitCharacterInLine, nl2br} from 'utils/Helpers';

function PostInfo({
  data,
  hashtags,
  omitHashtags = false,
  omitLikeShare = false,
  omitTitle = false,
  omitUser = false,
  onCommentClick,
  showBoxStore = false,
  timeAgo = false,
  highlight = '',
  itemDisplayFormat = '1',
  detailLink,
  highlightHashtagID,
  highlightStoreID,
}) {

  const hightLightitem = (value, text) => {
    if (!value) {
      return text;
    }
    const regex = new RegExp(`(${value})`, "gi");
    const nameHightlight = text.replace(regex, "<mask class='highlight'>$1</mask>")

    return nameHightlight
  }

  const commentIcon =
      itemDisplayFormat == '1'
          ? images.icon_playground_comment
          : images.icon_inquiry_comment;
  const classShowComment = ()=>{
    let className = 'has-store-hashtag'
    if(((showBoxStore && data.stores?.length) || hashtags && hashtags.length > 0)){
      if(hashtags && hashtags.length > 0){
        className = 'has-box-hashtag'
      }
      if(((showBoxStore && data.stores?.length))){
        className = 'has-box-store'
      }
      if(((showBoxStore && data.stores?.length) && hashtags && hashtags.length > 0)){
        className = 'has-store-hashtag'
      }
    }else {
      className = 'non-store-hashtag'
    }
    return className
  }

  let storeName = ''
  if (data.stores.length) {
    if (highlightStoreID && highlightStoreID !== 'all') {
      let store = data.stores.find(e => +e.id === +highlightStoreID)
      if (store) {
        storeName = store.name
      }
    } else {
      storeName = data.stores[0].name
    }
  }

  return (
    <div className="item-content-post__text">

      {!omitTitle && <label className="text-title" onClick={detailLink}>
        <div
          dangerouslySetInnerHTML={{ __html:
                itemDisplayFormat == '1' ? limitCharacterInLine(hightLightitem(highlight, data.title)) :hightLightitem(highlight, data.title)
        }}
        />
      </label>}
      <div
        className="text-description s"
        onClick={detailLink}
        dangerouslySetInnerHTML={{ __html:
              itemDisplayFormat == '1' ?
                  limitCharacterInLine(nl2br(hightLightitem(highlight, data.content) || '')) : nl2br(hightLightitem(highlight, data.content) || '')
      }}
      />
      <div className={`box-store-hashtag mt-2 ${classShowComment()}`}>
        {showBoxStore && data.stores?.length ? (
            <div className='item-box-store' onClick={detailLink}>
              <div className='item-box-store__store'>
                <img
                  className='item-box-store__store__icon'
                  src={images.icon_playground_marker_store}
                  alt=''
                />
                <div
                  className='item-box-store__store__text'
                  dangerouslySetInnerHTML={{ __html: hightLightitem(highlight, storeName) }}
                />
              </div>
              {data.stores?.length > 1 ? (
                  <div className='item-box-store__count'>
                    <label>+{data.stores?.length - 1}</label>
                  </div>
              ) : null}
            </div>
        ) : ''}
        <div className={`box-hashtag-comment`}>
          <Hashtags hashtags={hashtags} highlight={highlight} highlightHashtagID={highlightHashtagID} />
          <div
              className={`item-comment item-box-comment  ${itemDisplayFormat != '1' ? 'd-none' : ''}`}
          >
            <img className="" src={commentIcon} alt="" />
            <label>{abbreviateNumber(data?.comment_cnt || 0)}</label>
          </div>
        </div>
      </div>

      <div className="box-hashtag-like-comment">
        {/* {!omitHashtags ? <Hashtags hashtags={hashtags} /> : null} */}
        {!omitLikeShare ? (
          <LikeComment
            data={data}
            itemDisplayFormat={itemDisplayFormat}
            onCommentClick={onCommentClick}
          />
        ) : null}
      </div>
      {omitUser && <UserInfo
        user={data.user}
        createdAt={data.created_at}
        onClick={detailLink}
        timeAgo={timeAgo}
        highlight={highlight}
      />}
    </div>
  );
}

export default PostInfo;
