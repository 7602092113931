import React, {useRef, useState} from 'react';
import useOnClickOutside from 'customHooks/useClickOutSide';
import { ReactComponent as IconBack } from '../../../assets/icons/icon_back.svg';
import { ReactComponent as IconHome } from '../../../assets/icons/icon_home.svg';
import { ReactComponent as IconThreeDotDetail } from '../../../assets/icons/three_dot_detail.svg';
import { useHistory } from 'react-router-dom';
import ROUTERS from 'constants/router';
import { useEffect } from 'react';
import QueryString from 'qs';

type Props = {
  isProcessing: Boolean,
  handleUpdate: () => void,
  handleDelete: () => void,
  isShowAction: Boolean
};

const HeaderDetailComponent = ({handleUpdate, handleDelete, isShowAction}: props) => {
  const history = useHistory()
  const [showActionUpdate, setShowActionUpdate] = useState(false);
  const wrapprefHeader = useRef();
  const searchParams = new URLSearchParams(window.location.search);
  const [backUrl, setBackurl] = useState('')
  useEffect(() => {
    if (history.location.state) {
      let params = history.location.state
      // params.page = 1
      params.reload = '1' // set = 1 thì ko fetch lại list
      let path = searchParams.get('type') ? (searchParams.get('type') === 'PLAYGROUND' ? ROUTERS.PLAYGROUND : ROUTERS.INQUIRY_PRODUCT) : ''
      setBackurl(path ? (path + `?${QueryString.stringify(params)}`) : '')
    }
  }, [history.location?.state])

  useOnClickOutside(wrapprefHeader, () => {
    setShowActionUpdate(false)
  })

  const handleBack = () => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.go(-1);
    }
  }

  useEffect(() => {
    window.backFromDeviceAndroid = function() {
      handleBack();
    }

    return () => {
      window.backFromDeviceAndroid = undefined
    }

  }, [backUrl])

  const goToMain = () => {
    window.backToScreenByUrlNative('MainPay')
  }

  const updateAction = () => {
    setShowActionUpdate(false);
    handleUpdate()
  }
  const deleteAction = () => {
    setShowActionUpdate(false);
    handleDelete()
  }

  return (
    <div className="wrapper-header wrapper-header--detail">
      <div className="content">
        <div className="app-sub-header">
          <div className="app-sub-header__left">
            <div className="icon-back" role="presentation" onClick={() => handleBack()}>
              <IconBack />
            </div>
            <div className="icon-home" role="presentation" onClick={() => goToMain()}>
              <IconHome />
            </div>
          </div>
          <div className='box-dropdown-action-detail' ref={wrapprefHeader}>
            {
                isShowAction && <div className="app-sub-header__right"   role="presentation" onClick={() => setShowActionUpdate(true)}>
                  <IconThreeDotDetail />
                </div>
            }
            <div className="action-detail">
              {
                  showActionUpdate && <ul>
                    <li role="presentation" onClick={() => updateAction()}><span>수정</span></li>
                    <li role="presentation" onClick={() => deleteAction()}><span>삭제</span></li>
                  </ul>
              }
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default HeaderDetailComponent;

