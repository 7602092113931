import React, { memo, useEffect, Fragment } from 'react';
import MainLayout from 'layout/MainLayout';
import { useHistory, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'react-bootstrap';
import moment from 'moment'

import ListFilter from '../components/ListFilter/index';
import PlaygroundListNoData from '../components/ListNoData';
import images from '../../../themes/images';
import ROUTERS from '../../../constants/router';
import PlaygroundList from 'components/Playground/List';

import {
  useListQueryUrlParams,
  // useListQueryParams,
  useList,
  useListResParams,
  useDelete,
} from 'customHooks/playground/index';
import useChangeUrlParams from 'customHooks/useChangeUrlParams'
import useCountSize from 'customHooks/playground/useCountSize';
import ModalPlayground from 'components/Playground/ModalPlayground';
import { useState } from 'react';
import HeaderPlayground from 'components/Playground/HeaderPlayground';

const PlaygroundContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const { changeUrlParams } = useChangeUrlParams();
  const param = useListQueryUrlParams('PLAYGROUND', moment().utc().format('YYYY-MM-DD HH:mm:ss'));
  // const [param, setParam] = useListQueryParams('PLAYGROUND');
  // console.log(param, 'param');
  const [list, loading] = useList((+!+param.reload) ? param : null);
  const resParams = useListResParams();
  const [dataModal, setDataModal] = useState({
    isShow: false,
    type: '',
    textBtnLeft: '취소',
    textBtnRight: '삭제',
    title: '글을 삭제 하시겠습니까?',
  })
  const [ reloadPage, setReloadPage ] = useState(0)
  const [ loadingByFilter, setLoadingByFilter ] = useState(false)
  useCountSize(null, loading);

  const handleBack = () => {
    window.backToScreenNative();
  };

  // eslint-disable-next-line func-names
  window.backFromDeviceAndroid = function() {
    handleBack();
  }
  
  useEffect(() => {
    window.callGetListPathNative(['/playground?', '/product/inquiry?', '/playground/search?', '/product/inquiry/detail/'])
    // changeUrlParams(param, [{ type: 'current_time', value: param.current_time }])
  }, [])
  
  useEffect(() => {
    if (+!+param.reload) {
      window.scrollTo(0, 0)
      window.localStorage.scrollY = 0
    } else {
      let scrollPos = window.localStorage.scrollY
      setTimeout(() => {
        window.scrollTo(0, scrollPos)
        console.log(document.body.clientHeight, "document.body.clientHeight");
      }, 50)
      console.log(document.body.clientHeight, "document.body.clientHeight");
    }
  }, [])
  
  useEffect(() => {
    function scroll (event) {
      let scroll = this.scrollY;
      window.localStorage.scrollY = scroll
    }
    window.addEventListener("scroll", scroll);

    return () => window.removeEventListener("scroll", scroll);
  }, [])


  const handleSearch = () => {
    history.push({
      pathname: ROUTERS.PLAYGROUND_SEARCH,
      state: param
    });
  };

  const _delete = (item) => {
    console.log('delete', item);
    setDataModal({
      isShow: true,
      type: 'delete',
      textBtnLeft: '취소',
      textBtnRight: '삭제',
      title: '글을 삭제 하시겠습니까?',
      data: {inquiry: item?.id || null}
    })
  };

  const _onSuccess = () => {

  }

  const [loadingDelete, onDelete] = useDelete({ onSuccess: _onSuccess, params: param });

  const handleCloseModal = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const handleCancelDelete = () => {
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const handleDelete = () => {
    onDelete(dataModal.data);
    setDataModal({
      ...dataModal,
      isShow: false,
    })
  }

  const goToEdit = (item) => {
    const url = ROUTERS.PLAYGROUND_EDIT.replace(':id', item.id);
    history.push(url);
  };

  const goToCreate = () => {
    // history.push(ROUTERS.PLAYGROUND_CREATE);
    history.push({
      pathname: ROUTERS.PLAYGROUND_CREATE,
      state: param
    });
  };

  const onTouchrefreshPage = (data) => {
    if (data) {
      changeUrlParams(param, [
        { type: 'page', value: 1 },
        // { type: 'reload', value: '' + (+!+param.reload) },
        { type: 'current_time', value: moment().utc().format('YYYY-MM-DD HH:mm:ss') },

      ])
      setReloadPage(p => ++p)
      // setParam([
      //   { type: 'page', value: 1 },
      // ])
    }
  }

  const handleSelect = (key) => {
    console.log('handleSelect');
    if (key === 'playground') {
      changeUrlParams(param, [
        { type: 'page', value: 1 },
        // { type: 'reload', value: '' + (+!+param.reload) },
        { type: 'current_time', value: moment().utc().format('YYYY-MM-DD HH:mm:ss') },
      ])
      // setParam([
      //   { type: 'page', value: 1 },
      // ])
    }
  }

  return (
    <MainLayout
      isLoading={loadingDelete}
      isShowHeader={false}
      customClass="playground-main"
      isTouchEnd
      refreshPage={onTouchrefreshPage}
    >
      <div className="playground-main__filter" id="box-filter-page">
        <HeaderPlayground
          handleBack={handleBack}
          isShowSearchIcon={true}
          handleSearch={handleSearch}
          handleSelect={handleSelect}
          activeItem={{
            key: 'playground',
            text: '플레이그라운드'
          }}
        />
        <ListFilter
          param={param}
          // setParam={setParam}
          loading={loading}
          data={list}
          reloadPage={reloadPage}
          setLoadingByFilter={setLoadingByFilter}
        />
      </div>
      {!list.length && !loading ?
        <PlaygroundListNoData param={param} />
      : 
        <InfiniteScroll
          dataLength={(list && list.length) || 0}
          next={() => {
            changeUrlParams(param, [{ type: 'page', value: +param.page + 1 }]);
          }}
          hasMore={list && list.length < resParams.total && !loading}
          // scrollThreshold={1}
        >
          <PlaygroundList
            data={!loadingByFilter ? list : []}
            loading={loading || loadingByFilter}
            param={param}
            deleteAction={_delete}
            editAction={goToEdit}
            omitHashtags
            timeAgo
            highlightHashtagID={param?.filterType === 3 ? param?.hash_tag_id : ''}
            highlightStoreID={param?.filterType === 2 ? param?.store_id : ''}
          />
        </InfiniteScroll>
      }

      <div className="btn-to-page-create" onClick={goToCreate}>
        <div className="contact">
          <img
            className="img-contact"
            src={images.icon_playground_create}
            alt=""
          />
        </div>
      </div>

      <ModalPlayground
        isShow={dataModal.isShow}
        handleShowModal={handleCloseModal}
        textBtnLeft={dataModal.textBtnLeft}
        textBtnRight={dataModal.textBtnRight}
        title={dataModal.title}
        handleBtnLeft={handleCancelDelete}
        handleBtnRight={handleDelete}
        type={''}
      />

    </MainLayout>
  );
};

export default memo(PlaygroundContainer);
