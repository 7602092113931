import React, { memo, useEffect, useMemo } from 'react';
import MainLayout from 'layout/MainLayout';
import { useHistory, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'react-bootstrap';

import PlaygroundDetail from 'components/Playground/Detail';
import PlaygroundCommentList from 'components/Playground/Comment/index';
import HeaderDetailComponent from 'screens/playground/components/HeaderDetail';

import { useDetail } from 'customHooks/playground/index';
import useCountSize from 'customHooks/playground/useCountSize';

// const ITEM = {
//   user: { name: 'usernameeee' },
//   title: 'title',
//   content: 'content',
//   nickname: 'sdgsdfgdsfg',
//   created_at: '2023.02.17 19:40:30',
//   hashtags: ['hashtag', 'hashtag', 'hashtag', 'hashtag'],
//   images: ['http://localhost:3000/static/media/image-not-found.d58bbd74.jpg'],
// };

const PlaygroundDetailContainer = () => {
  const params = useParams();
  const [itemDetail, loading] = useDetail(params, 'PLAYGROUND');

  const handleUpdate = () => {
    console.log('handleUpdate');
  };

  const handleDelete = (item) => {
    console.log(item, 'handleDelete');
  };

  return (
    <MainLayout
      isShowHeader={false}
      titleHeader="상품 상세"
      isCenter
      isShowIcon
      isLink
      isLoading={false}
      customClass="page-detail-community"
    >
      <HeaderDetailComponent
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
      />
      <div className="wrapper-page-content">
        <PlaygroundDetail item={itemDetail} omitHashtags omitTitle />
        <div className="comment">
          <PlaygroundCommentList data={itemDetail} />
        </div>
      </div>
    </MainLayout>
  );
};

export default memo(PlaygroundDetailContainer);
