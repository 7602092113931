import { useDispatch, useSelector } from 'react-redux';

const getSelector = (key) => (state) => state['accountReducer'][key];
const userInfoSelector = getSelector('userInfo');

const dataUser = JSON.parse(localStorage.getItem("DATA_INFO"));

// eslint-disable-next-line import/prefer-default-export
export const useUser = () => {
  const userReducer = useSelector(userInfoSelector);
  if (dataUser?.profile?.uid) {
    return dataUser.profile
  }
  return userReducer;
};
