/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';
import { checkStatusCodeApi } from 'utils/Helpers';

import { Types } from './index';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* getListPlayground(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() =>
      API.get(ROUTES.API_GET_LIST_PLAYGROUND, action.data)
    );

    const { data } = response;
    checkStatusCodeApi(data.code);
    // In case: signup request success
    yield put({
      type: Types.GET_LIST_PLAYGROUND_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_LIST_PLAYGROUND_FAILED, error });
  }
}

function* getDetailPlayground(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => {
      let url = ROUTES.API_GET_DETAIL_PLAYGROUND;
      url = url.replace(':id', action.data.id);
      
      return API.get(url, action.data)
    });

    const { data } = response;
    checkStatusCodeApi(data.code);
    // In case: signup request success
    if (data.code === 200) {
      yield put({
        type: Types.GET_DETAIL_PLAYGROUND_SUCCESS,
        data: data.data,
      });
    } else {
      yield put({ type: Types.GET_DETAIL_PLAYGROUND_FAILED, data });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_DETAIL_PLAYGROUND_FAILED, error });
  }
}

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* createPlayground(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => {
      let url = ROUTES.API_CREATE_PLAYGROUND;

      return API.post(url, action.data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    });

    const { data } = response;
    checkStatusCodeApi(data.code);
    // In case: signup request success
    yield put({
      type: Types.CREATE_PLAYGROUND_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    // in case: server error
    yield put({ type: Types.CREATE_PLAYGROUND_FAILED, error });
  }
}

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* updatePlayground(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    console.log(action.data, 'action.data');
    const response = yield call(() => {
      let url = ROUTES.API_UPDATE_PLAYGROUND;
      url = url.replace(':id', action.data.id);

      return API.post(url, action.data.data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    });

    const { data } = response;
    
    console.log(data.code, 'data.codedata.codedata.codedata.code');
    checkStatusCodeApi(data.code);
    // In case: signup request success
    if (data.code === 200) {
      yield put({
        type: Types.UPDATE_PLAYGROUND_SUCCESS,
        data: data.data,
      });
    }else{
      yield put({ type: Types.UPDATE_PLAYGROUND_FAILED, data: data.errors });
    }

  } catch (error) {
    // in case: server error
    yield put({ type: Types.UPDATE_PLAYGROUND_FAILED, error });
  }
}

// comment
// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* getListComment(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => {
      let url = ROUTES.API_GET_LIST_PLAYGROUND_COMMENT;
      url = url.replace(':inquiry', action.data.inquiry);

      return API.get(url, action.data);
    });

    const { data } = response;
    checkStatusCodeApi(data.code);
    // In case: signup request success
    yield put({
      type: Types.GET_LIST_COMMENT_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_LIST_COMMENT_FAILED, error });
  }
}
// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* createComment(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => {
      let url = ROUTES.API_CREATE_PLAYGROUND_COMMENT;
      url = url.replace(':inquiry', action.data.inquiry);

      return API.post(url, action.data);
    });

    const { data } = response;
    checkStatusCodeApi(data.code);
    // In case: signup request success
    yield put({
      type: Types.CREATE_PLAYGROUND_COMMENT_SUCCESS,
      data: data.data,
    });
    yield put({
      type: Types.GET_LIST_COMMENT_WITHOUT_LOADING,
      data: {
        ...action.data,
        page: 0,
        sort_by: 'created_at',
        sort_direction: 'desc',
      },
    });
  } catch (error) {
    // in case: server error
    yield put({ type: Types.CREATE_PLAYGROUND_COMMENT_FAILED, error });
  }
}

function* updateComment(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => {
      let url = ROUTES.API_UPDATE_PLAYGROUND_COMMENT;
      url = url.replace(':inquiry', action.data.inquiry);

      return API.post(url, action.data);
    });

    const { data } = response;
    checkStatusCodeApi(data.code);
    // In case: signup request success
    yield put({
      type: Types.UPDATE_PLAYGROUND_COMMENT_SUCCESS,
      data: data.data,
    });
    yield put({
      type: Types.GET_LIST_COMMENT,
      data: {
        ...action.data,
        page: 0,
        sort_by: 'created_at',
        sort_direction: 'desc',
      },
    });
  } catch (error) {
    // in case: server error
    yield put({ type: Types.UPDATE_PLAYGROUND_COMMENT_FAILED, error });
  }
}

function* deleteComment(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => {
      let url = ROUTES.API_DELETE_PLAYGROUND_COMMENT;
      url = url.replace(':inquiry', action.data.inquiry);
      console.log(action.data, 'action.data');
      return API.delete(url, action.data);
    });

    const { data } = response;
    checkStatusCodeApi(data.code);
    // In case: signup request success
    yield put({
      type: Types.DELETE_PLAYGROUND_COMMENT_SUCCESS,
      data: action.data,
    });
    yield put({
      type: Types.GET_LIST_COMMENT,
      data: {
        ...action.data,
        page: 0,
        sort_by: 'created_at',
        sort_direction: 'desc',
      },
    });
  } catch (error) {
    // in case: server error
    yield put({ type: Types.DELETE_PLAYGROUND_COMMENT_FAILED, error });
  }
}

function* deletePlayground(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => {
      let url = ROUTES.API_DELETE_PLAYGROUND;
      url = url.replace(':inquiry', action.data.inquiry);
      return API.delete(url)
    });
    const { data } = response;
    checkStatusCodeApi(data.code);
    yield put({
      type: Types.DELETE_PLAYGROUND_SUCCESS,
      data,
    });
    
  } catch (error) {
    // in case: server error
    yield put({ type: Types.DELETE_PLAYGROUND_FAILED, error });
  }
}


// worker Saga: will be fired on CREATE_PLAYGROUND_REACTION actions
function* createPlaygroundReaction(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => {
      let url = ROUTES.API_PLAYGROUND_REACTION;
      url = url.replace(':inquiry', action.data.id);

      return API.post(url, action.data);
    });

    const { data } = response;
    checkStatusCodeApi(data.code);
    // In case: signup request success
    yield put({
      type: Types.CREATE_PLAYGROUND_REACTION_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    // in case: server error
    yield put({ type: Types.CREATE_PLAYGROUND_REACTION_FAILED, error });
  }
}

// store
// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* getListStore(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => {
      return API.get(ROUTES.API_GET_LIST_STORES, action.data);
    });

    const { data } = response;
    checkStatusCodeApi(data.code);
    // In case: signup request success
    yield put({
      type: Types.GET_LIST_STORE_PLAYGROUND_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_LIST_STORE_PLAYGROUND_FAILED, error });
  }
}

// hashtag
// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* getListHashtag(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => {
      return API.get(ROUTES.API_GET_LIST_HASHTAG, action.data);
    });

    const { data } = response;
    checkStatusCodeApi(data.code);
    // In case: signup request success
    yield put({
      type: Types.GET_LIST_HASHTAG_PLAYGROUND_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_LIST_HASHTAG_PLAYGROUND_FAILED, error });
  }
}

/*
  Starts getListPlayground on each dispatched `GET_LIST_PLAYGROUND` action.
*/
function* playgroundSaga() {
  yield takeLatest(Types.GET_LIST_PLAYGROUND, getListPlayground);
  yield takeLatest(Types.GET_DETAIL_PLAYGROUND, getDetailPlayground);
  yield takeLatest(Types.CREATE_PLAYGROUND, createPlayground);
  yield takeLatest(Types.UPDATE_PLAYGROUND, updatePlayground);
  yield takeLatest(Types.DELETE_PLAYGROUND, deletePlayground);

  // comment
  yield takeLatest(Types.GET_LIST_COMMENT, getListComment);
  yield takeLatest(Types.GET_LIST_COMMENT_WITHOUT_LOADING, getListComment);
  yield takeLatest(Types.CREATE_PLAYGROUND_COMMENT, createComment);
  yield takeLatest(Types.UPDATE_PLAYGROUND_COMMENT, updateComment);
  yield takeLatest(Types.DELETE_PLAYGROUND_COMMENT, deleteComment);

  // reaction
  yield takeLatest(Types.CREATE_PLAYGROUND_REACTION, createPlaygroundReaction);
  
  // stores
  yield takeLatest(Types.GET_LIST_STORE_PLAYGROUND, getListStore);
  
  // hashtag
  yield takeLatest(Types.GET_LIST_HASHTAG_PLAYGROUND, getListHashtag);
}

export default playgroundSaga;
