import { useEffect, useMemo, useState } from 'react';
import { ROUTES, API } from 'utils/Apis';
import { useUser } from 'customHooks/useUser';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useCheckUpdate,
  useFilterParam,
  // import FormDate from 'components/Form/Date';
} from 'customHooks/utils';
import { useSelector } from 'react-redux';
import { Creators } from 'store/playground/index';

const getSelector = (key) => (state) => state['playgroundReducer'][key];

const loadingSelector = getSelector('isLoadingStore');
const listSelector = getSelector('stores');
const getListFailedSelector = getSelector('getListStoreFailed');

export const useListStoreFavoriteParam = () => {
  const user = useUser();
  const param = useMemo(() => {
    return {
      page: 1,
      page_size: 0,
      sort_by: 'id',
      order_by: 'desc',
      user_id: user?.uid,
      store_favorite: 1,
    };
  }, [])
  return param
};

export const useListStoreFavorite = (param) => {
  return useFetchByParam({
    action: Creators.getListStorePlayground,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param,
  });
};

// export const useListStoreFavorite = () => {
//   const [loading, setLoading] = useState(true);
//   const [list, setList] = useState([]);
//   const user = useUser();

//   useEffect(() => {
//     setLoading(true);
//     let params = {
//       page: 1,
//       page_size: 0,
//       sort_by: 'id',
//       order_by: 'desc',
//       user_id: user?.uid,
//       store_favorite: 1,
//     };

//     API.get(ROUTES.API_GET_LIST_STORES, params)
//       .then((res) => {
//         setLoading(false);
//         setList(res.data.data);
//       })
//       .catch(() => {
//         setLoading(false);
//       });
//     // eslint-disable-next-line
//   }, []);

//   return [list, loading];
// };

export const useListStore = () => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    setLoading(true);
    let params = {
      page: 1,
      page_size: 0,
      sort_by: 'id',
      order_by: 'desc',
    };

    API.get(ROUTES.API_GET_LIST_STORES, params)
      .then((res) => {
        setLoading(false);
        setList(res.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return [list, loading];
};
