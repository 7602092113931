import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string'
import MainLayout from 'layout/MainLayout';

import PlaygroundForm from './../components/PlaygroundForm/index';
import { useListStoreFavoriteParam, useListStoreFavorite } from 'customHooks/store';
import ROUTERS from '../../../constants/router';

const PlaygroundCreateContainer = () => {
  const history = useHistory();
  const param = useListStoreFavoriteParam();
  const [storeFavorites, loadingStore] = useListStoreFavorite(param);
  const [backUrl, setBackurl] = useState(ROUTERS.PLAYGROUND)

  useEffect(() => {
    window.showLoadingNative(loadingStore);
  }, [loadingStore]);

  useEffect(() => {
    if (history.location.state) {
      let paramList = history.location.state
      paramList.current_time = ''
      setBackurl(ROUTERS.PLAYGROUND + `?${queryString.stringify(paramList)}`)
    }
  }, [history.location?.state])

  return (
    <MainLayout isLoading={false} isShowHeader={false} customClass='main-playground-form'>
      <PlaygroundForm storeFavorites={storeFavorites} backUrl={backUrl} />
    </MainLayout>
  );
};

export default memo(PlaygroundCreateContainer);
