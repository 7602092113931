import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import heic2any from 'heic2any';
import { ReactComponent as IconPlaygroundRemoveImage } from '../../../../assets/icons/playground-remove-img.svg';
var Tiff = require('tiff.js');

const DEFAULT_IMAGE = '';

const ImagePreview = ({ file, remove, height, width, className }) => {
  const [imgSrc, setImgSrc] = useState(DEFAULT_IMAGE);
  const output = useRef();
  useEffect(() => {
    if (file instanceof File) {
      if (['image/heif', 'image/heic'].includes(file.type)) {
        // fetching the heic image
        heic2any({
          blob: file,
          toType: 'image/jpeg',
        }).then((conversionResult) => {
          // console.log(conversionResult, 'conversionResult');
          // convert to blob url
          var url = URL.createObjectURL(conversionResult);
          setImgSrc(url);
          // conversionResult is a BLOB
          // of the PNG formatted image
        });
      } else if (['image/tiff'].includes(file.type)) {
        var reader = new FileReader();
        reader.onload = (function (theFile) {
          return function (e) {
            let buffer = e.target.result;
            let tiff = new Tiff({ buffer: buffer });
            let base64 = tiff.toDataURL();
            setImgSrc(base64);
          };
        })(file);
        reader.readAsArrayBuffer(file);
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          setImgSrc(reader.result);
        };
      }
    } else if (file) {
      setImgSrc(file.path_thumbnail);
    } else {
      setImgSrc(DEFAULT_IMAGE);
    }
  }, [file]);

  return (
    <div
      className="position-relative d-flex justify-content-center align-items-center"
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      {file && remove ? (
        <span onClick={remove} className="remove-image">
          <IconPlaygroundRemoveImage />
        </span>
      ) : null}
        <img
          src={imgSrc || ''}
          className={`img-fluid rounded border ${className}`}
          height={height}
          width={width}
          style={{
            width: `${width}px`,
            height: `${height}px`,
            objectFit: 'cover',
          }}
        />
    </div>
  );
};

ImagePreview.defaultProps = {};

ImagePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  remove: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

export default ImagePreview;
