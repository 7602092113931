import React, { Fragment, useEffect } from 'react';
import images from '../../../../themes/images';
import { useListHashtagParam, useListHashtag } from './../../hooks/hashtag';
import useCountSize from 'customHooks/playground/useCountSize';
import useChangeUrlParams from 'customHooks/useChangeUrlParams'

function FilterPopular({
  data,
  loading,
  param,
  setParam,
  title = '',
  noData = null,
  paramKey = 'hash_tag_id',
  hastagKey = 'id',
  omitScroll = false,
  omitSetParam = false,
  reloadPage = 0,
  setLoadingByFilter = () => {},
}) {
  const hashtagParam = useListHashtagParam(reloadPage);
  const [hastags, loadingHashtag] = useListHashtag((+!+param.reload) ? hashtagParam : null);
  const { changeUrlParams } = useChangeUrlParams();

  useCountSize(null, loadingHashtag);

  useEffect(() => {
    setLoadingByFilter(loadingHashtag)
  }, [loadingHashtag])

  useEffect(() => {
    if (!omitSetParam && !loadingHashtag && !param?.hash_tag_id && +!+param.reload) {
      let id = '';
      if (hastags.length) {
        id = hastags[0].id
      }
      changeUrlParams(param, [
        { type: 'filterType', value: 3 },
        { type: 'page', value: 1 },
        { type: 'store_id', value: '' },
        { type: 'hash_tag', value: '' },
        { type: 'keyword', value: '' },
        { type: 'hash_tag_id', value: id },
        { type: 'has_like_cnt', value: id ? 0 : 1 },
        { type: 'has_hashtag', value: 1 },
      ]);
    }
  }, [hastags])

  const _onChange = (hash_tag) => {
    if (param[paramKey] === (hastagKey === 'id' ? hash_tag : `#${hash_tag}`)) {
      return;
    }
    changeUrlParams(param, [{
      type: paramKey,
      value: hastagKey === 'id' ? hash_tag : `#${hash_tag}`
    },
    { type: 'reload', value: '0' }
  ]);
  };

  const renderHashtag = <Fragment>
    <div className="main-box-title-filter mt-32px mb-2">
      {title}
    </div>
    <div className="main-box-filter">
      <div className={!omitScroll ? "x-scroll" : ''}>
        {hastags?.map((hastag) => (
          <button
            key={hastag.id}
            className={`item-filter${
              param[paramKey] == (hastagKey === 'id' ? hastag[hastagKey] : `#${hastag[hastagKey]}`) ? ' active' : ''
            }`}
            type="button"
            onClick={() => _onChange(hastag[hastagKey])}
          >
            <label>#{hastag.hashtag}</label>
          </button>
        ))}
        {!hastags?.length && noData}
      </div>
    </div>
  </Fragment>

  return (
    !loadingHashtag ? (
      noData ? (
        renderHashtag
      ) : (
        hastags?.length ? (
          renderHashtag
        ) : null
      )
    ) : null
  );
}

export default FilterPopular;
