import React, { Fragment, useEffect } from 'react';
import PlaygroundListItemLoading from './Item'

function PlaygroundListLoading({itemDisplayFormat, loading = false, param = {}}) {

  useEffect(() => {
    // if (loading && +param?.page === 1 && +!+param.reload) {
    //   setTimeout(() => {
    //     window.scrollTo(0, 0)
    //   }, 100)
    // }
  }, [loading, param?.page])

  return (
    loading ? (
      +param.page == 1 ? (
        <Fragment>
          <PlaygroundListItemLoading itemDisplayFormat={itemDisplayFormat} />
          <PlaygroundListItemLoading itemDisplayFormat={itemDisplayFormat} />
          <PlaygroundListItemLoading itemDisplayFormat={itemDisplayFormat} />
          <PlaygroundListItemLoading itemDisplayFormat={itemDisplayFormat} />
          <PlaygroundListItemLoading itemDisplayFormat={itemDisplayFormat} />
        </Fragment>
      ) : (
        <PlaygroundListItemLoading itemDisplayFormat={itemDisplayFormat} />
      )
    ) : null
  );
}

PlaygroundListLoading.defaultProps = {
  type: 'playground',
};

export default PlaygroundListLoading;
